/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./zoom-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./zoom-button-component";
import * as i3 from "@angular/platform-browser";
var styles_ZoomButtonComponent = [i0.styles];
var RenderType_ZoomButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZoomButtonComponent, data: {} });
export { RenderType_ZoomButtonComponent as RenderType_ZoomButtonComponent };
export function View_ZoomButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn badge-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomDevice.setZoomIn(0.9) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-search-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ZOOM IN "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn badge-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomDevice.setZoomOut(0.5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-search-minus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ZOOM OUT"]))], null, null); }
export function View_ZoomButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-zoom-component", [], null, null, null, View_ZoomButtonComponent_0, RenderType_ZoomButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.ZoomButtonComponent, [i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ZoomButtonComponentNgFactory = i1.ɵccf("app-zoom-component", i2.ZoomButtonComponent, View_ZoomButtonComponent_Host_0, { zoom: "zoom" }, { zoomChange: "zoomChange" }, []);
export { ZoomButtonComponentNgFactory as ZoomButtonComponentNgFactory };
