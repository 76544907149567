/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./remote-control.component";
import * as i3 from "./remote-control.service";
import * as i4 from "angular2-logger/core";
import * as i5 from "./remote-control.component.scss.shim.ngstyle";
import * as i6 from "./remote-control-device-list.component.ngfactory";
import * as i7 from "./remote-control-device-list.component";
import * as i8 from "./remote-control-device.component.ngfactory";
import * as i9 from "./remote-control-device.component";
import * as i10 from "angular2-toaster/src/toaster.service";
import * as i11 from "../core/basic-info.service";
import * as i12 from "../core/util.service";
import * as i13 from "../photo/photo.service";
import * as i14 from "@ng-bootstrap/ng-bootstrap";
import * as i15 from "../core/camera-preset.service";
import * as i16 from "../shared/resized-image.pipe";
import * as i17 from "../core/item-type.service";
import * as i18 from "@angular/platform-browser";
import * as i19 from "@angular/common";
var styles_RemoteControlMainComponent = [];
var RenderType_RemoteControlMainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RemoteControlMainComponent, data: {} });
export { RenderType_RemoteControlMainComponent as RenderType_RemoteControlMainComponent };
export function View_RemoteControlMainComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RemoteControlMainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-remote-control-main", [], null, null, null, View_RemoteControlMainComponent_0, RenderType_RemoteControlMainComponent)), i0.ɵdid(1, 245760, null, 0, i2.RemoteControlMainComponent, [i3.RemoteControlService, i4.Logger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemoteControlMainComponentNgFactory = i0.ɵccf("app-remote-control-main", i2.RemoteControlMainComponent, View_RemoteControlMainComponent_Host_0, {}, {}, []);
export { RemoteControlMainComponentNgFactory as RemoteControlMainComponentNgFactory };
var styles_RemoteControlComponent = [i5.styles];
var RenderType_RemoteControlComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RemoteControlComponent, data: {} });
export { RenderType_RemoteControlComponent as RenderType_RemoteControlComponent };
function View_RemoteControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "alert-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Remote control"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Can't establish socket connection. "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-refresh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "mobile-hide"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Retry"]))], null, null); }
function View_RemoteControlComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-remote-control-device-list", [], null, null, null, i6.View_RemoteControlDeviceListComponent_0, i6.RenderType_RemoteControlDeviceListComponent)), i0.ɵdid(1, 49152, null, 0, i7.RemoteControlDeviceListComponent, [i1.Router, i3.RemoteControlService], { devices: [0, "devices"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.devices; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RemoteControlComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-remote-control-device", [], [[8, "className", 0]], [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).keyPressed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_RemoteControlDeviceComponent_0, i8.RenderType_RemoteControlDeviceComponent)), i0.ɵdid(1, 245760, null, 0, i9.RemoteControlDeviceComponent, [i4.Logger, i10.ToasterService, i1.Router, i11.BasicInfoService, i12.UtilService, i0.ChangeDetectorRef, i13.PhotoService, i14.NgbModal, i15.CameraPresetService, i1.ActivatedRoute, i16.ResizedImagePipe, i17.ItemTypeService, i18.DomSanitizer], { device: [0, "device"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.device; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).hostClass; _ck(_v, 0, 0, currVal_0); }); }
function View_RemoteControlComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemoteControlComponent_3)), i0.ɵdid(1, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemoteControlComponent_4)), i0.ɵdid(3, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.device; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.device; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RemoteControlComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemoteControlComponent_1)), i0.ɵdid(1, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["connectedTemplate", 2]], null, 0, null, View_RemoteControlComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isError; var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RemoteControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-remote-control", [], null, null, null, View_RemoteControlComponent_0, RenderType_RemoteControlComponent)), i0.ɵdid(1, 114688, null, 0, i2.RemoteControlComponent, [i3.RemoteControlService, i1.ActivatedRoute, i1.Router, i10.ToasterService, i4.Logger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemoteControlComponentNgFactory = i0.ɵccf("app-remote-control", i2.RemoteControlComponent, View_RemoteControlComponent_Host_0, {}, {}, []);
export { RemoteControlComponentNgFactory as RemoteControlComponentNgFactory };
