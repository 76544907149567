import { Component, OnInit, Input, HostListener, OnChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { CookieService } from 'ngx-cookie';
import { finalize } from 'rxjs/operators';

import { CollectionService } from './collection.service';

@Component({
  selector: 'app-collection-editor',
  templateUrl: './collection-editor.component.html',
  // styleUrls: ['./collection-editor.component.scss'],
})
export class CollectionEditorComponent implements OnInit, OnChanges {
  @Input() collection: any;
  @Input() collections: any;
  @Input() basicInfo: any;

  isSaving = false;
  isClosing = false;

  editor;
  actions = [{ type: 'detach', icon: 'fa fa-minus-circle', title: 'Remove from collection' }];
  productColumns = [
    { name: 'id', label: 'ID', type: 'text' },
    { name: 'sku', label: 'SKU', type: 'text' },
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'files', label: 'Image', type: 'first-image' },
  ];

  photoColumns = [
    { name: 'id', label: 'ID', type: 'text' },
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'url', label: 'Image', type: 'image' },
  ];

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private toasterService: ToasterService,
    private collectionService: CollectionService,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    this.changeCollection();

    // smart collection items are not detachable (for now)
    if (this.isSmartCollection()) {
      this.actions = [];
    }
  }

  get items() {
    if (this.collection.items) {
      return this.collection.items.data;
    }
    if (this.isPhotoCollection()) {
      return this.editor.collection.files;
    }
    if (this.isProductCollection()) {
      return this.editor.collection.products;
    }

    return [];
  }

  get columns() {
    if (this.isPhotoCollection()) {
      return this.photoColumns;
    }
    if (this.isProductCollection()) {
      return this.productColumns;
    }

    return null;
  }

  isPhotoCollection() {
    return this.editor.collection.collection_type === CollectionService.TYPE_PHOTO;
  }

  isProductCollection() {
    return this.editor.collection.collection_type === CollectionService.TYPE_PRODUCT;
  }

  isSmartCollection() {
    return this.editor.collection.settings && this.editor.collection.settings.filters;
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  save(close) {
    this.isClosing = close;
    this.isSaving = true;

    // save changes to the DB
    this.collectionService.update(this.editor.collection)
      .pipe(finalize(() => { this.isSaving = false; }))
      .subscribe((response: any) => {
        const collection = response.body;
        this.toasterService.pop('success', `Collection updated!`, `The collection ${collection.name} has been successfully updated.`);

        // make changes visible on the front end listing
        this.editor.collection = collection;
        Object.assign(this.collection, collection);

        if (close) {
          this.ngbActiveModal.close();
        }
      });
  }

  actionHandler($event) {
    if (this.isPhotoCollection()) {
      return this.photoActionHandler($event);
    }

    if (this.isProductCollection()) {
      return this.productActionHandler($event);
    }

    return null;
  }

  productActionHandler($event) {
    if ($event.action.type === 'detach') {
      // remove product from list
      this.editor.collection.products = this.editor.collection.products.filter(p => p.id !== $event.item.id);
    }
  }

  photoActionHandler($event) {
    if ($event.action.type === 'detach') {
      // remove photo from list
      this.editor.collection.files = this.editor.collection.files.filter(p => p.id !== $event.item.id);
    }
  }

  ngOnChanges(changes) {
    if (changes.collection) {
      this.changeCollection();
    }
  }

  changeCollection() {
    this.editor = {
      collection: cloneDeep(this.collection),
    };
  }

  getPublicLink(collection) {
    return `${this.basicInfo.apiBaseUrl.replace('/api', '')}/view-collection/${collection.key}`;
  }

  copiedToClipboard() {
    this.toasterService.pop('success', `Link copied!`, `The link was copied to your clipboard.`);
  }

  export($event) {
    $event.stopPropagation();
    // use cookies as a temporary auth method for download link
    // the server will respond with a header telling the browser to remove the cookie
    this.cookieService.put('Authorization', localStorage.getItem('accessToken'));
  }

  getFilterLink(collection) {
    const filters = JSON.stringify(collection.settings.filters);
    return [`/${collection.collection_type}s/`, filters];
  }

  @HostListener('window:keyup', ['$event'])
  keyPressed($event) {
    // do nothing if an input is focused
    if ($event.path && $event.path.length && ['INPUT', 'TEXTAREA'].includes($event.path[0].tagName)) {
      return;
    }

    if (['ArrowRight', 'ArrowDown'].indexOf($event.key) !== -1 && this.collection.next) {
      this.collection = this.collection.next;
      this.changeCollection();
    }
    if (['ArrowLeft', 'ArrowUp'].indexOf($event.key) !== -1 && this.collection.prev) {
      this.collection = this.collection.prev;
      this.changeCollection();
    }
  }
}
