<div class="modal-header">
   <h4 class="modal-title">Share product {{product.name || product.sku}}</h4>
</div>

<div class="modal-body">
  <form #shareForm="ngForm" [hidden]="showPreview">
    <div class="form-group" *ngIf="templates.length">
      <label>Template</label>
      <select class="form-control"
        name="templateId"
        [(ngModel)]="message.templateId"
        (ngModelChange)="onTemplateChange($event)">
        <option [ngValue]="null">Select template</option>
        <option *ngFor="let template of templates" [ngValue]="template.id">{{template.name}}</option>
      </select>
    </div>

    <div class="row">
      <div class="form-group col col-md-6">
        <label>From name</label>
        <input class="form-control"
          [(ngModel)]="message.from_name"
          name="from_name"
          type="text"
          placeholder="">
      </div>

      <div class="form-group col col-md-6">
        <label>From email</label>
        <input class="form-control"
          [(ngModel)]="message.from_email"
          name="from_email"
          type="email"
          email
          required
          placeholder="">
      </div>
    </div>

    <div class="row">
      <div class="form-group col col-md-6">
        <label>To name</label>
        <input class="form-control"
          [(ngModel)]="message.to_name"
          name="to_name"
          type="text"
          placeholder="">
      </div>

      <div class="form-group col col-md-6">
        <label>To email</label>
        <input class="form-control"
          [(ngModel)]="message.to_email"
          name="to_email"
          type="email"
          required
          email
          placeholder="">
      </div>
    </div>

    <div class="form-group">
      <label>Subject</label>
      <input class="form-control"
        [(ngModel)]="message.subject"
        name="subject"
        type="text"
        required
        placeholder="">
    </div>

    <div class="form-group">
      <label>Message</label>
      <textarea #contentTextArea name="content" height="300" class="form-control" required [(ngModel)]="message.content"></textarea>
    </div>
  </form>
  <ng-container *ngIf="showPreview">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-2">From:</div>
          <div class="col-10">{{message.from_name || message.from_email}} &lt;{{message.from_email}}&gt;</div>
        </div>
        <div class="row">
          <div class="col-2">To:</div>
          <div class="col-10">{{message.to_name || message.to_email}} &lt;{{message.to_email}}&gt;</div>
        </div>
        <div class="row">
          <div class="col-2">Subject:</div>
          <div class="col-10 subject-preview">{{message.subject}}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="alert alert-success" *ngIf="success">
          <i class="fa fa-check"></i> The message has been sent successfully
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col message-preview">{{message.content}}</div>
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <div class="col">
    <div class="form-group pull-right">
      <button type="submit" class="btn btn-sm btn-secondary" (click)="cancel()" [disabled]="isSending"><i class="fa fa-close"></i> {{success ? 'Close' : 'Cancel'}}</button>
      <button *ngIf="!showPreview" type="submit" class="btn btn-sm btn-primary" (click)="send()" [disabled]="!shareForm.valid">
        <i class="fa fa-eye"></i> Preview
      </button>
      <button *ngIf="showPreview && !success" type="submit" class="btn btn-sm btn-success" (click)="send()" [disabled]="isSending">
        <i class="fa" [ngClass]="{ 'fa-share-square': !isSending, 'fa-spinner fa-spin': isSending }"></i> Send
      </button>
    </div>
  </div>
</div>
