import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  HostBinding,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Logger } from 'angular2-logger/core';
import { ToasterService, Toast } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, timer, interval } from 'rxjs';
import { get, isEqual, find, each, findIndex } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
  DEFAULT_ASPECT_RATIO,
  getTransformedCameraSettings,
} from './device';
import { Device } from './remote-control.service';
import { PhotoEditorComponent } from '@app/photo/photo-editor.component';
import { BasicInfoService } from '@app/core/basic-info.service';
import { UtilService } from '@app/core/util.service';
import { PhotoService } from '@app/photo/photo.service';
import { PhotoCropperComponent } from '@app/shared/photo-cropper/photo-cropper.component';
import { RemoteVideoComponent } from './remote-video.component';
import { CameraPresetManagerComponent } from '@app/shared/camera-preset-manager/camera-preset-manager.component';
import { CameraPresetService } from '@app/core/camera-preset.service';
import { ItemTypeService } from '@app/core/item-type.service';
import { Slider } from '@app/shared/slider/slider';
import { ResizedImagePipe } from '@app/shared/resized-image.pipe';

const defaultShortcuts = {
  proceed: 'Alt',
  back: 'Escape',
  toggleOverlay: null,
  zoomLevel: null,
  exposure: null,
  colorTemperature: null,
  tint: null,
};

@Component({
  selector: 'app-remote-control-device',
  templateUrl: './remote-control-device.component.html',
  styleUrls: ['./remote-control-device.component.scss'],
})

export class RemoteControlDeviceComponent implements OnInit, OnDestroy {
  @ViewChild(RemoteVideoComponent) remoteVideo: RemoteVideoComponent;
  @ViewChild(PhotoCropperComponent) cropper: PhotoCropperComponent;

  isPhotoStripOpen = true;
  attributeValues = {};
  sliders = [];
  rotation = 0;
  itemTypes;
  skipPreview = false;
  isCapturing = false;
  isReady = false;
  isUploading = false;
  uploadProgress: number = null;
  saveToCameraRoll = false;
  showPhoto = false;
  showAdvancedExposure = false;
  sku = '';
  isOverlayVisible = false;
  overlayImage = null;
  @ViewChild('skuElem') skuField: ElementRef;
  @HostBinding('class') public hostClass = 'remote-control';

  private _aspectRatio = DEFAULT_ASPECT_RATIO;
  private uploadedPhotos = [];
  private currentDevice: Device;
  private presets;
  private currentPresetId: number = null;
  private photoGroups = [];

  // this can be refactored after this is published
  // https://github.com/ng-bootstrap/ng-bootstrap/issues/1600
  private isModalOpen = false;

  constructor(
    private logger: Logger,
    private toasterService: ToasterService,
    private router: Router,
    private basicInfoService: BasicInfoService,
    private util: UtilService,
    private chRef: ChangeDetectorRef,
    private photoService: PhotoService,
    private ngbModal: NgbModal,
    private cameraPresetService: CameraPresetService,
    private route: ActivatedRoute,
    private resizedImage: ResizedImagePipe,
    private itemTypeService: ItemTypeService,
    private domSanitizer: DomSanitizer,
  ) { }

  @Input()
  set device(device: Device) {
    if (this.currentDevice) {
      this.ngOnDestroy();
    }

    this.currentDevice = device;
    if (this.currentDevice) {
      this.initializeDevice();
    }
  }

  get device(): Device {
    return this.currentDevice;
  }

  get uploadedPhotosForStrip() {
    return this.uploadedPhotos ? this.uploadedPhotos.slice(0, 6) : [];
  }

  get photoStripGroups() {
    const newGroups = [];
    let previousPhoto;

    // group consecutive SKUs into groups
    this.uploadedPhotosForStrip.forEach(photo => {
      if (previousPhoto && get(previousPhoto, 'product.sku') === get(photo, 'product.sku')) {
        // add to existing group
        newGroups[newGroups.length - 1].photos.push(photo);
      } else {
        // create a new group
        newGroups.push({ sku: get(photo, 'product.sku'), photos: [photo] });
      }
      previousPhoto = photo;
    });

    // change the reference only if something has changed
    if (!isEqual(this.photoGroups, newGroups)) {
      this.photoGroups = newGroups;
    }
    return this.photoGroups;
  }

  get orientation() {
    return this.device.settings.orientation || ORIENTATION_PORTRAIT;
  }

  set orientation(orientation) {
    if (this.device) {
      this.device.setOrientation(orientation);
    }
  }

  get aspectRatio() {
    return this._aspectRatio;
  }

  set aspectRatio(aspect) {
    this._aspectRatio = aspect;
    if (!this.showPhoto && this.device) {
      this.device.setAspectRatio(aspect);
    }
  }

  ngOnInit() {
    // The changing of the value during OnInit is intentional. The timer is a hack to prevent ExpressionChangedAfterItHasBeenCheckedError
    timer().subscribe(() => {
      this.basicInfoService.setForceMobileMenu(true);
      this.basicInfoService.setIsMenuOpen(false);
      this.basicInfoService.setToasterConfig({ positionClass: 'toast-top-left-sidebar' });
    });

    interval(500).subscribe(this.focusSku);

    this.itemTypeService.loadAndFilter(null, ({ show_in_remote_controller }) => show_in_remote_controller)
      .subscribe((itemTypes) => {
        this.itemTypes = itemTypes;
      });

    this.cameraPresetService.getAll()
      .subscribe((presets: any) => {
        this.presets = presets.body;
        this.setDefaultPresetIfApplicable();
      });
  }

  ngOnDestroy() {
    timer().subscribe(() => {
      this.basicInfoService.setForceMobileMenu(false);
      this.basicInfoService.setIsMenuOpen(false);
    });
    if (this.device) {
      this.device.setDisconnectionListener();
      this.device.disconnect();
    }

    this.basicInfoService.setToasterConfig({ positionClass: 'toast-top-right' });
  }

  getAttributeFields() {
    return this.itemTypes;
  }

  focusSku = () => {
    const activeElement = <any>document.activeElement;
    const allowedTypes = ['number', 'text'];
    const shouldFocus = !activeElement || !activeElement.type || allowedTypes.indexOf(activeElement.type) === -1;
    const isAlreadyFocused = this.skuField === activeElement;

    if (!this.isModalOpen && shouldFocus && this.skuField && !isAlreadyFocused) {
      // select all content in SKU field and focus
      this.util.selectAllContent(this.skuField);
    }
  }

  disconnect() {
    this.router.navigate(['remote-control']);
  }

  isAdvancedExposureEnabled(): boolean {
    if (this.showAdvancedExposure) {
      return true;
    }

    if (!this.device || !this.device.settings) {
      return false;
    }

    const { exposureDuration, exposureISO } = this.device.settings;

    return exposureISO !== null || exposureDuration !== null;
  }

  async initializeDevice() {
    this.logger.debug('RemoteControlDeviceComponent initializeDevice');

    this.isReady = false;
    try {
      await this.device.connect();
    } catch (error) {
      this.logger.error('Failed to connect', error);
    }

    this.device.setDisconnectionListener(() => {
      this.toasterService.pop('error', 'Device disconnected');
      timer().subscribe(() => this.router.navigate(['remote-control']));
      this.device.setDisconnectionListener();
    });

    this.logger.debug('isReady = true');
    this.isReady = true;

    this.setDefaultPresetIfApplicable();

    if (!this.device.isConnectable) {
      return;
    }

    this.sliders = [
      new Slider('zoomLevel', this.device, {
        name: 'Zoom',
        icon: 'fa fa-search',
        unit: 'x',
        fixed: 1,
      }),
      new Slider('exposure', this.device, {
        name: 'Exposure',
        icon: 'exposure',
        useMaterialIcon: true,
        unit: 'EV',
        fixed: 2,
        onShowAdvanced: () => {
          this.showAdvancedExposure = true;
        },
        isHidden: () => {
          return this.isAdvancedExposureEnabled();
        },
      }),
      new Slider('exposureISO', this.device, {
        name: 'Sensitivity (ISO)',
        icon: 'exposure',
        useMaterialIcon: true,
        unit: '',
        fixed: 0,
        onHideAdvanced: () => {
          this.showAdvancedExposure = false;
          this.device.setExposureISO(null);
          this.device.setExposureDuration(null);
        },
        isHidden: () => {
          return !this.isAdvancedExposureEnabled();
        },
      }),
      new Slider('exposureDuration', this.device, {
        name: 'Exposure duration',
        icon: 'timer',
        useMaterialIcon: true,
        unit: 'ms',
        fixed: 3,
        formatNumber(val) {
          const newVal = Number.parseFloat((val * 1000) + '').toPrecision(3);
          return `${newVal}`;
        },
        unFormatNumber(val) {
          return parseFloat(val) / 1000;
        },
        isHidden: () => {
          return !this.isAdvancedExposureEnabled();
        },
      }),
      new Slider('colorTemperature', this.device, {
        name: 'Color temp.',
        icon: 'color_lens',
        useMaterialIcon: true,
        unit: 'K',
        fixed: 0,
      }),
      new Slider('tint', this.device, {
        name: 'Tint',
        icon: 'fa fa-tint',
        isDisabled: () => this.device.settings.colorTemperature === null,
        fixed: 0,
      }),
    ];

    // prevent loader from getting stuck
    timer(100).subscribe(() => this.chRef.detectChanges());
  }

  get basicInfo() {
    return get(this.route, 'parent.parent.snapshot.data.basicInfo', {});
  }

  get latestPhoto() {
    return this.device.latestPhoto;
  }

  get isCropperEnabled(): boolean {
    return this.showPhoto && this.cropper && this.cropper.isEnabled;
  }

  getUploadedPhotoThumb(photo) {
    return this.resizedImage.transform(photo.url, '100x100', 'crop');
  }

  setDefaultPresetIfApplicable() {
    const defaultPresetId = get(this.basicInfo, 'settings.defaultPreset');
    if (this.presets && this.isReady && !this.device.settings.preset && defaultPresetId ) {
      this.onSetPreset(this.presets.find(preset => preset.id === defaultPresetId));
    } else {
      this.orientation = get(this.device, 'settings.orientation', ORIENTATION_LANDSCAPE);
      this.aspectRatio = get(this.device, 'settings.aspectRatio', DEFAULT_ASPECT_RATIO);
    }

    // sometimes the orientation is wrong after connecting. This is a temporary workaround.
    if (this.presets && this.isReady) {
      setTimeout(() => {
        if (this.hasSettingsChanged()) {
          this.onResetSettings();
        }
      }, 2000);
    }
  }

  getDefaultPreset() {
    return {
      id: null,
      name: 'Default preset',
      settings: this.device.getDefaultSettings(),
      overlay: null,
    };
  }

  getCurrentPreset() {
    const currentPresetId = this.device.settings.preset;
    let currentPreset = null;
    if (currentPresetId) {
      currentPreset = find(this.presets, <any>{ id: currentPresetId });
    }

    const preset = currentPreset || this.getDefaultPreset();

    if (preset.id !== this.currentPresetId) {
      this.currentPresetId = preset.id;
      this.updateOverlayByPreset(preset);
    }

    return preset;
  }

  upload = async () => {
    if (!this.showPhoto || this.isUploading) {
      return;
    }

    this.showPhoto = false;
    const uploadingToast = this.toasterService.pop(<Toast>{
      type: 'success',
      title: 'Uploading...',
      showCloseButton: false,
      tapToDismiss: false,
      timeout: 0,
    });

    this.util.selectAllContent(this.skuField);

    try {
      this.isUploading = true;
      const data = {
        sku: this.sku,
        product_attribute_values: this.attributeValues,
        attribute_values: { postProduction: { crop: this.cropper.getData(true) } },
      };
      const uploadData = await this.device.upload(data, ({ progress }) => {
        this.uploadProgress = progress * 100;
        this.logger.debug('upload progress', this.uploadProgress);
        const percent = Math.round(this.uploadProgress);
        uploadingToast.body = `${percent}%`;
      });
      this.toasterService.clear(uploadingToast.toastId);
      this.toasterService.pop('success', 'Photo uploaded successfully');

      this.logger.debug('Upload done', uploadData);

      this.uploadedPhotos.unshift(uploadData);
      this.uploadedPhotos = this.util.linkPrevNext(this.uploadedPhotos);
    } catch (error) {
      this.logger.error('Failed to upload a photo', error);
      this.toasterService.clear(uploadingToast.toastId);
      this.toasterService.pop('error', error === 'timeout' ? 'Timeout occurred while uploading a photo' : 'Failed to upload a photo');
      this.showPhoto = true;
      this.chRef.detectChanges();
    }

    this.isUploading = false;
    this.uploadProgress = null;
  }

  capture = async () => {
    if (this.isCapturing || this.showPhoto || this.isUploading) {
      return;
    }

    try {
      this.isCapturing = true;

      const preview = this.remoteVideo.size;
      const options = {
        sku: this.sku,
        saveToCameraRoll: this.saveToCameraRoll,
        preview,
      };

      await this.device.capture(options);
      this.showPhoto = true;
      this.chRef.detectChanges();

      if (this.aspectRatio === 'FULL') {
        this.aspectRatio = 'FREE';
      }

      if (this.skipPreview) {
        this.upload();
      }
    } catch (error) {
      const msg = 'Failed to take a photo';
      this.logger.error(msg, error);
      this.toasterService.pop('error', msg);
    }

    this.isCapturing = false;
  }

  dismissPhoto = () => {
    if (this.aspectRatio === 'FREE') {
      this.aspectRatio = 'FULL';
    }
    this.showPhoto = false;
  }

  hasSettingsChanged() {
    const { settings: defaultSettings, overlay } = this.getCurrentPreset();
    const settings = this.device.getTransformedSettings();
    const overlayId = this.overlayImage ? this.overlayImage.id : null;
    const isOverlayChanged = overlayId !== overlay;

    return !isEqual(getTransformedCameraSettings(defaultSettings), settings) || isOverlayChanged;
  }

  openPhoto(photo) {
    const modalRef = this.openModal(PhotoEditorComponent, { size: 'lg' });
    modalRef.componentInstance.reversedShortcutArrows = true;
    modalRef.componentInstance.photo = photo;
    modalRef.componentInstance.basicInfo = this.basicInfo;
    modalRef.componentInstance.onDeleteSuccess = (photoId: number) => {
      this.uploadedPhotos = this.uploadedPhotos.filter(({ id }) => id !== photoId);
      this.uploadedPhotos = this.util.linkPrevNext(this.uploadedPhotos);
    };
  }

  onSetPreset(preset) {
    this.currentPresetId = preset.id;
    this.showAdvancedExposure = false;
    this.device.setPreset(preset.id, preset.settings);
    this.orientation = preset.settings.orientation || this.orientation;
    this.aspectRatio = preset.settings.aspectRatio || this.aspectRatio;
    this.updateOverlayByPreset(preset);
  }

  updateOverlayByPreset(preset) {
    if (!preset.overlay) {
      this.overlayImage = null;
      this.isOverlayVisible = false;
      return;
    }
    this.photoService.getById(preset.overlay).subscribe((response: any) => {
      const photo = response.body;
      this.overlayImage = photo;
      this.isOverlayVisible = true;
    });
  }

  onResetSettings() {
    this.showAdvancedExposure = false;
    const currentPreset = this.getCurrentPreset();
    if (currentPreset.id) {
      this.onSetPreset(currentPreset);
      return;
    }

    this.resetSettings();
  }

  resetSettings() {
    this.device.resetSettings();
    this.overlayImage = null;
    this.isOverlayVisible = false;
  }

  onSkuFieldKeyPress(event) {
    // enter and tabulator are used by bar code scanners as end of line
    if (event.keyCode === 13 || event.keyCode === 9) {
      event.preventDefault();
      this.util.selectAllContent(event);
    }
  }

  openPresetManager(mode) {
    const modalRef = this.openModal(CameraPresetManagerComponent, { size: 'lg' });
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.overlay = this.overlayImage ? this.overlayImage.id : null;
    modalRef.componentInstance.settings = this.device.settings;

    modalRef.componentInstance.onSavePreset = (preset) => {
      this.logger.debug('New preset saved', preset);
      const updatedIndex = findIndex(this.presets, ({ id }) => id === preset.id);
      if (updatedIndex > -1) {
        this.presets[updatedIndex] = { ...preset };
      } else {
        this.presets.push(preset);
      }
    };
    modalRef.componentInstance.onDeletePreset = (presets) => {
      this.presets = presets;
    };
    modalRef.componentInstance.onSelectPreset = this.onSelectPreset;
    modalRef.componentInstance.setPresets(this.presets.map(preset => ({
      ...preset,
      isSelected: preset.id === this.device.settings.preset,
    })).concat([{
      ...this.getDefaultPreset(),
      isSelected: !this.device.settings.preset,
    }]));
  }

  onSelectPreset = (preset) => {
    this.logger.debug('Preset selected', preset);

    if (preset.id === this.device.settings.preset) {
      this.resetSettings();
    } else {
      this.onSetPreset(preset);
    }
  }

  toggleOverlay = () => {
    if (this.overlayImage) {
      this.isOverlayVisible = !this.isOverlayVisible;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyPressed($event) {
    if (this.isModalOpen) {
      return;
    }

    const organizationShortcuts = get(this.basicInfo, 'settings.shortcuts', {});

    const shortcuts = {
      ...defaultShortcuts,
      ...organizationShortcuts,
    };

    const actions = <any>{
      proceed: null,
      back: null,
      toggleOverlay: this.toggleOverlay,
    };

    if (this.showPhoto) {
      actions.proceed = this.upload;
      actions.back = this.dismissPhoto;
    } else {
      actions.proceed = this.capture;
      this.sliders.forEach(({ key, focus }) => {
        actions[key] = focus;
      });
    }

    const isKeyPressed = (actionKey) => {
      const { code, key, keyCode} = $event;
      return actionKey === code || actionKey === key || actionKey === keyCode;
    };

    this.logger.debug('keyPressed', $event.code, $event.key, $event.keyCode);
    each(shortcuts, (key, action) => {
      if (actions[action] && isKeyPressed(key)) {
        this.logger.debug('keyPressed with action', action, key, $event.key, $event.keyCode);
        $event.preventDefault();
        actions[action]();
      }
    });
  }

  setFocusPoint = (x, y) => {
    if (!this.isAdvancedExposureEnabled()) {
      return this.device.setFocusPoint(x, y);
    }
  }

  private openModal(component, options = {}) {
    this.isModalOpen = true;
    const modalRef = this.ngbModal.open(component, options);
    modalRef.result.then(() => {
      this.isModalOpen = false;
    }).catch((error) => {
      this.isModalOpen = false;
      this.logger.error('Modal closed with error');
    });

    return modalRef;
  }
}
