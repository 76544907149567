import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/core/auth.service';
import { ToasterService } from 'angular2-toaster';
import { BasicInfoService } from '@app/core/basic-info.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl = '/';
  basicInfo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToasterService,
    public basicInfoService: BasicInfoService,
  ) { }

  ngOnInit() {
    this.basicInfo = this.route.snapshot.data['basicInfo'];

    // reset login status
    this.authService.logout();
    this.returnUrl = '/';
  }

  login() {
    this.loading = true;
    this.authService.login(this.model.username, this.model.password)
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.toasterService.pop('error', 'Login failed!', 'Please try again.');
          this.loading = false;
        });
  }
}
