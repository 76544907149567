import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from 'angular2-logger/core';
import { Observable, interval } from 'rxjs';
import { first, get } from 'lodash';

import { AuthService } from '@app/core/auth.service';
import { CollectionService } from '@app/collection/collection.service';

const Pusher = require('pusher-js');

@Component({
  selector: 'app-shop-demo',
  templateUrl: './shop-demo.component.html',
  styleUrls: ['./shop-demo.component.scss'],
})
export class ShopDemoComponent implements OnInit {
  items;
  watches;
  wallets;
  watchLiftup;
  walletLiftup;
  selectedProduct;
  basicInfo;
  private socket;
  private channel;

  constructor(
    private authService: AuthService,
    private collectionService: CollectionService,
    private ngbModal: NgbModal,
    private logger: Logger,
  ) { }

  async ngOnInit() {
    this.basicInfo = await this.authService.me();

    const channelName = `presence-${get(this.basicInfo, 'user.organization_id')}`;
    const { cluster, appKey } = get(this.basicInfo, 'pusher', {});

    if (appKey && cluster) {
      this.socket = new Pusher(appKey, {
        cluster,
        encrypted: true,
        authEndpoint: '/api/pusher/auth',
        auth: {
          params: {
            uniqueId: 'foobar',
          },
          headers: { Authorization: this.authService.createAuthHeader().get('Authorization') },
        },
      });

      this.logger.log('subscribe socket with channel', channelName);
      this.channel = this.socket.subscribe(channelName);
      
      this.channel.bind('pusher:subscription_succeeded', ({ members }) => {
        this.logger.log('subscribed', members);
      });
      this.channel.bind('pusher:subscription_error', (status) => {
        this.logger.log('subscription_error', status);
      });
      this.channel.bind('resized', this.loadCollection.bind(this));
    }

    if (!localStorage.getItem('shop-demo-key')) {
      const key = window.prompt('Please enter a collection key');
      if (key) {
        localStorage.setItem('shop-demo-key', key);
      }
    }

    this.loadCollection();
  }

  openItem(product, modalTemplate) {
    this.selectedProduct = product;
    this.ngbModal.open(modalTemplate, { size: 'lg' });
  }

  getSelectedImageUrl() {
    if (this.selectedProduct && this.selectedProduct.selectedImage && this.selectedProduct.selectedImage.url) {
      return this.selectedProduct.selectedImage.url;
    }
    if (
      this.selectedProduct &&
      this.selectedProduct.files &&
      this.selectedProduct.files.length &&
      this.selectedProduct.files[0] &&
      this.selectedProduct.files[0].url
    ) {
      return this.selectedProduct.files[0].url;
    }
  }

  selectImage(image) {
    this.selectedProduct.selectedImage = image;
  }

  loadCollection() {
    this.logger.log('loading collection...', status);
    this.collectionService.getByKey(localStorage.getItem('shop-demo-key')).subscribe((data: any) => {
      if (get(data, 'body.items.data')) {
        this.items = data.body.items.data;

        // get 6 first watches
        this.watches = this.items
          .filter(p => get(p, 'attribute_values.category') === 'Watches')
          .slice(0, 6);
        // get 6 first wallets
        this.wallets = this.items
          .filter(p => get(p, 'attribute_values.category') === 'Wallets')
          .slice(0, 6);

        this.logger.log('collection loaded');

        this.watchLiftup = this.getLiftup('Watches');
        this.walletLiftup = this.getLiftup('Wallets');
      }
    });
  }

  startCarousel(item) {
    if (!item.files || item.files.length < 1) {
      return;
    }

    item.thumbFile = 1;

    item.timer = interval(2500).subscribe(() => {
      item.thumbFile++;
      if (item.thumbFile >= item.files.length) {
        item.thumbFile = 0;
      }
    });
  }

  stopCarousel(item) {
    item.thumbFile = undefined;
    item.timer.unsubscribe();
  }

  getLiftup(category) {
    return first(this.items.filter(item => get(item, 'attribute_values.is-liftup') && get(item, 'attribute_values.category') === category));
  }
}
