import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth.service';
import { UtilService } from '@app/core/util.service';
import { BaseApiService } from '@app/core/base-api.service';

@Injectable()
export class CameraPresetService extends BaseApiService {
  constructor(
    public authService: AuthService,
    public util: UtilService,
  ) {
    super(authService, util);
    this.modelEndpoint = 'api/camerapreset';
  }

  getAll() {
    return this.authService.get(`${this.modelEndpoint}/all`);
  }
}
