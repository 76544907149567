import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from 'angular2-logger/core';
import { get } from 'lodash';
import { ToasterService } from 'angular2-toaster';

import { AuthService } from '@app/core/auth.service';
import { RemoteControlService, Devices } from '@app/remote-control/remote-control.service';
import { BasicInfoService } from '@app/core/basic-info.service';

const DEMO_SHOP_DOMAIN = 'shop.kingdam.io';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  basicInfo;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: Logger,
    private remoteControlService: RemoteControlService,
    private authService: AuthService,
    private toasterService: ToasterService,
    public basicInfoService: BasicInfoService,
  ) { }

  ngOnInit() {
    this.basicInfo = this.route.snapshot.data['basicInfo'];

    this.logger.info('Dashboard init');
    if (window.location.hostname === DEMO_SHOP_DOMAIN) {
      this.router.navigateByUrl('/shop', { skipLocationChange: true });
    } else if (this.router.url === '/') {
      this.router.navigate(['products']);
    }
  }

  get devices(): Devices {
    return this.remoteControlService.devices;
  }

  toggleMenu() {
    this.basicInfoService.toggleMenu();
  }

  getBasicInfo(path) {
    return get(this.basicInfo, path);
  }
}
