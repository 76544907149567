/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/pagination-list-view/pagination-list-view.component.ngfactory";
import * as i3 from "../shared/pagination-list-view/pagination-list-view.component";
import * as i4 from "../shared/item-list/item-list.component.ngfactory";
import * as i5 from "@angular/common";
import * as i6 from "../shared/item-list/item-list.component";
import * as i7 from "angular2-toaster/src/toaster.service";
import * as i8 from "@angular/router";
import * as i9 from "../core/util.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./collection.component";
import * as i12 from "./collection.service";
import * as i13 from "ngx-cookie/src/cookie.service";
import * as i14 from "@ng-bootstrap/ng-bootstrap";
var styles_CollectionComponent = [i0.styles];
var RenderType_CollectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollectionComponent, data: {} });
export { RenderType_CollectionComponent as RenderType_CollectionComponent };
function View_CollectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn-fade btn btn-sm btn-secondary pull-right"], ["download", ""]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.export($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-download"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (("/api/collection/" + _v.context.$implicit.id) + "/export/"); _ck(_v, 0, 0, currVal_0); }); }
export function View_CollectionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { downloadTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-pagination-list-view", [["title", "Collections"]], null, [[null, "itemsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemsChange" === en)) {
        var pd_0 = ((_co.collections = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PaginationListViewComponent_0, i2.RenderType_PaginationListViewComponent)), i1.ɵdid(2, 114688, null, 0, i3.PaginationListViewComponent, [], { title: [0, "title"], service: [1, "service"], items: [2, "items"] }, { itemsChange: "itemsChange" }), (_l()(), i1.ɵeld(3, 0, null, 1, 2, "app-item-list", [["view-content", ""]], null, [[null, "onAction"], [null, "onSelectItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAction" === en)) {
        var pd_0 = (_co.actionHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelectItem" === en)) {
        var pd_1 = (_co.openCollection($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ItemListComponent_0, i4.RenderType_ItemListComponent)), i1.ɵprd(512, null, i5.DatePipe, i5.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(5, 114688, null, 0, i6.ItemListComponent, [i7.ToasterService, i8.Router, i9.UtilService, i10.DomSanitizer, i5.DatePipe], { columns: [0, "columns"], items: [1, "items"], isSelectable: [2, "isSelectable"], actions: [3, "actions"] }, { onSelectItem: "onSelectItem", onAction: "onAction" }), (_l()(), i1.ɵand(0, [[1, 2], ["downloadTemplate", 2]], null, 0, null, View_CollectionComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Collections"; var currVal_1 = _co.collectionService; var currVal_2 = _co.collections; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.columns; var currVal_4 = _co.getCollections(); var currVal_5 = false; var currVal_6 = _co.actions; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_CollectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-collection", [], null, null, null, View_CollectionComponent_0, RenderType_CollectionComponent)), i1.ɵdid(1, 4308992, null, 0, i11.CollectionComponent, [i12.CollectionService, i13.CookieService, i7.ToasterService, i14.NgbModal, i8.ActivatedRoute, i9.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollectionComponentNgFactory = i1.ɵccf("app-collection", i11.CollectionComponent, View_CollectionComponent_Host_0, {}, {}, []);
export { CollectionComponentNgFactory as CollectionComponentNgFactory };
