import { Device } from '@app/remote-control/remote-control.service';

export interface SliderInterface {
  name: string;
  icon: string;
  useMaterialIcon?: boolean;
  isDisabled?: Function;
  fixed: number;
  unit?: string;
  format?: Function;
  formatNumber?: Function;
  unFormatNumber?: Function;
  isHidden?: Function;
  onShowAdvanced?: Function;
  onHideAdvanced?: Function;
}

export class Slider implements SliderInterface {
  device: Device;
  key: string;

  name: string;
  icon: string;
  useMaterialIcon = false;
  fixed: number;
  unit: string;

  focus: Function;

  onShowAdvanced: Function;
  onHideAdvanced: Function;

  constructor(key: string, device, values: SliderInterface) {
    this.key = key;
    this.device = device;

    Object.keys(values).forEach((k) => {
      this[k] = values[k];
    });
  }

  isHidden() {
    return false;
  }

  isDisabled() {
    return false;
  }

  format = (val) => {
    return val === null ? 'Auto' : this.formatNumber(val);
  }

  formatNumber = (val) => {
    return Number(val).toFixed(this.fixed);
  }

  unFormatNumber = (val) => {
    return Number(val).toFixed(this.fixed);
  }

  get defaults() {
    return this.device.settings.defaultSettings[this.key];
  }

  get min(): number {
    return this.defaults.minimumValue;
  }

  get max(): number {
    return this.defaults.maximumValue;
  }

  get step(): number {
    return this.defaults.step;
  }

  get value(): number {
    return this.isDisabled() ? null : this.device.settings[this.key];
  }

  get defaultValue(): number {
    return this.resetValue;
  }

  get resetValue(): number {
    return this.defaults.resetValue;
  }

  set value(value: number) {
    const newValue = this.getMinMaxValue(value);

    const ucfirstKey = this.key.charAt(0).toUpperCase() + this.key.slice(1);
    const setFn = `set${ucfirstKey}`;

    this.device.settings[this.key] = newValue;
    if (this.device[setFn]) {
      this.device[setFn](newValue);
    }
  }

  getMinMaxValue(value: number): number {
    if (value === this.resetValue) {
      return value;
    }

    let newValue = value;
    newValue = Math.min(newValue, this.max);
    newValue = Math.max(newValue, this.min);
    return newValue;
  }
}
