import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Logger } from 'angular2-logger/core';
import { Observable, timer } from 'rxjs';
import { RemoteControlService, Devices, Device } from './remote-control.service';

@Component({
  selector: 'app-remote-control-main',
  template: '<router-outlet></router-outlet>',
})
export class RemoteControlMainComponent implements OnInit, OnDestroy {
  constructor(
    private remoteControlService: RemoteControlService,
    private logger: Logger,
  ) { }

  ngOnInit() {
    this.logger.debug('RemoteControlMainComponent ngOnInit');
  }

  ngOnDestroy() {
    this.logger.debug('RemoteControlMainComponent ngOnDestroy');
    this.remoteControlService.disconnect();
  }
}

@Component({
  selector: 'app-remote-control',
  templateUrl: './remote-control.component.html',
  styleUrls: ['./remote-control.component.scss'],
})
export class RemoteControlComponent implements OnInit {
  isError = false;
  private deviceId: string;

  constructor(
    private remoteControlService: RemoteControlService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private logger: Logger,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(({ deviceId }) => {
      this.deviceId = deviceId;

      this.logger.debug('route deviceId', deviceId);
      if (!this.remoteControlService.selectDevice(deviceId) && deviceId) {
        this.toasterService.pop('error', 'Device is not online');
        timer().subscribe(() => this.router.navigate(['remote-control']));
      } else if (deviceId) {
        this.retry();
      }
    });

    this.retry();
  }

  async retry() {
    this.isError = false;
    try {
      this.logger.debug('Connect on RemoteControlComponent');
      await this.remoteControlService.connect();
    } catch (error) {
      this.isError = true;
    }
  }

  get device(): Device {
    const device = this.remoteControlService.selectedDevice;
    let newRoute = null;

    if (device && this.deviceId !== device.id) {
      newRoute = ['remote-control', device.id];
    } else if (this.deviceId && !device) {
      newRoute = ['remote-control'];
    }

    if (newRoute) {
      timer().subscribe(() => this.router.navigate(newRoute));
    }

    return this.remoteControlService.selectedDevice;
  }

  get devices(): Devices {
    return this.remoteControlService.devices;
  }
}
