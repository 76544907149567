import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { CookieService } from 'ngx-cookie';
import { get } from 'lodash';

import { UtilService } from '@app/core/util.service';
import { CollectionService } from './collection.service';
import { CollectionEditorComponent } from '@app/collection/collection-editor.component';
import { ColumnsInterface } from '@app/shared/item-list/item-list.component';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit, AfterViewInit {
  @ViewChild('downloadTemplate') downloadTemplate: TemplateRef<any>;
  collections = [];
  modalRef;
  basicInfo;

  actions = [

  ];
  columns: Array<ColumnsInterface> = [
    { name: 'id', label: 'ID', type: 'text' },
    { name: 'name', label: 'Name', type: 'string' },
    { name: 'created_at', label: 'Created', type: 'date' },
    { name: 'updated_at', label: 'Modified', type: 'date' },
    { name: 'is_public', label: 'Visibility', type: 'visibility' },
    {
      name: 'type', label: 'Type', type: 'badge',
      settings: {
        getOption: row => {
          const option: any = [{
            id: 'Products',
            badge: 'info',
            icon: 'fa fa-barcode',
          }, {
            id: 'Photos',
            badge: 'default',
            icon: 'fa fa-photo',
          }].find(opt => opt.id === row.type);
          // add smart to column badge if collection has filters
          if (get(row, 'settings.filters')) {
            option.name = `Smart ${option.id}`;
            option.badge = 'smart';
            option.icon = 'fa fa-lightbulb-o';
          }
          return option;
        },
      },
    },
  ];

  constructor(
    public collectionService: CollectionService,
    private cookieService: CookieService,
    private toasterService: ToasterService,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private util: UtilService,
  ) { }

  ngOnInit() {
    this.basicInfo = this.route.parent.snapshot.data['basicInfo'];
  }

  ngAfterViewInit() {
    this.actions.push({ type: 'delete', icon: 'fa fa-trash', title: 'Delete' });
    this.actions.push({ tpl: this.downloadTemplate });
  }

  getCollections() {
    this.collections.forEach(collection => {
      collection.type = collection.collection_type === CollectionService.TYPE_PRODUCT ? 'Products' : 'Photos';
    });
    return this.collections;
  }

  actionHandler($event) {
    if ($event.action.type === 'delete') {
      this.delete($event.event, $event.item);
      return;
    }
  }

  delete(event, collection) {
    event.stopPropagation();

    // save changes to the DB
    if (window.confirm('Are you sure you want to delete this collection?')) {
      this.collectionService.delete(collection.id)
        .subscribe(() => {
          this.toasterService.pop('success', 'Collection deleted!', 'The collection has been deleted.');
        });
    }
  }

  openCollection(collection) {
    this.modalRef = this.ngbModal.open(CollectionEditorComponent, { size: 'lg' });
    // populate modal with information at hand
    this.modalRef.componentInstance.collection = collection;
    this.modalRef.componentInstance.basicInfo = this.basicInfo;
    // fetch all the data (including related items for smart collections)
    this.collectionService.getById(collection.id).subscribe((response: any) => {
      Object.assign(this.modalRef.componentInstance.collection, response.body);
    });
  }

  export($event) {
    $event.stopPropagation();
    // use cookies as a temporary auth method for download link
    // the server will respond with a header telling the browser to remove the cookie
    this.cookieService.put('Authorization', localStorage.getItem('accessToken'));
  }

}
