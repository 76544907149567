import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Logger } from 'angular2-logger/core';
import { timer } from 'rxjs';
import { RemoteControlService } from './remote-control.service';
export class RemoteControlMainComponent {
    constructor(remoteControlService, logger) {
        this.remoteControlService = remoteControlService;
        this.logger = logger;
    }
    ngOnInit() {
        this.logger.debug('RemoteControlMainComponent ngOnInit');
    }
    ngOnDestroy() {
        this.logger.debug('RemoteControlMainComponent ngOnDestroy');
        this.remoteControlService.disconnect();
    }
}
export class RemoteControlComponent {
    constructor(remoteControlService, route, router, toasterService, logger) {
        this.remoteControlService = remoteControlService;
        this.route = route;
        this.router = router;
        this.toasterService = toasterService;
        this.logger = logger;
        this.isError = false;
    }
    ngOnInit() {
        this.route.params.subscribe(({ deviceId }) => {
            this.deviceId = deviceId;
            this.logger.debug('route deviceId', deviceId);
            if (!this.remoteControlService.selectDevice(deviceId) && deviceId) {
                this.toasterService.pop('error', 'Device is not online');
                timer().subscribe(() => this.router.navigate(['remote-control']));
            }
            else if (deviceId) {
                this.retry();
            }
        });
        this.retry();
    }
    retry() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isError = false;
            try {
                this.logger.debug('Connect on RemoteControlComponent');
                yield this.remoteControlService.connect();
            }
            catch (error) {
                this.isError = true;
            }
        });
    }
    get device() {
        const device = this.remoteControlService.selectedDevice;
        let newRoute = null;
        if (device && this.deviceId !== device.id) {
            newRoute = ['remote-control', device.id];
        }
        else if (this.deviceId && !device) {
            newRoute = ['remote-control'];
        }
        if (newRoute) {
            timer().subscribe(() => this.router.navigate(newRoute));
        }
        return this.remoteControlService.selectedDevice;
    }
    get devices() {
        return this.remoteControlService.devices;
    }
}
