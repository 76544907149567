import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';

@Component({
  selector: 'app-remote-control-helper',
  templateUrl: './remote-control-helper.component.html',
  styleUrls: ['./remote-control-helper.component.scss'],
})
export class RemoteControlHelperComponent implements OnInit {
  @Input() title: string;
  basicInfo;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.basicInfo = this.route.parent.parent.snapshot.data['basicInfo'];
  }

  isForceRemoteControllerEnabled() {
    return !!get(this.basicInfo, 'settings.forceRemoteController');
  }

}
