/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./remote-control-device-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./remote-control-helper.component.ngfactory";
import * as i4 from "./remote-control-helper.component";
import * as i5 from "@angular/router";
import * as i6 from "./remote-control-device-list.component";
import * as i7 from "./remote-control.service";
var styles_RemoteControlDeviceListComponent = [i0.styles];
var RenderType_RemoteControlDeviceListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemoteControlDeviceListComponent, data: {} });
export { RenderType_RemoteControlDeviceListComponent as RenderType_RemoteControlDeviceListComponent };
function View_RemoteControlDeviceListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "connecting"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-spinner fa-pulse fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Connecting... "]))], null, null); }
function View_RemoteControlDeviceListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-screen-smartphone"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "fa-times": 0, "fa-check": 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-success"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDevice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-plug"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Connect "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["title", "Refresh device status"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshDevice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-refresh"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "fa"; var currVal_2 = _ck(_v, 7, 0, !_v.context.$implicit.isConnectable, _v.context.$implicit.isConnectable); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); var currVal_3 = !_v.context.$implicit.isConnectable; _ck(_v, 9, 0, currVal_3); }); }
function View_RemoteControlDeviceListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Device name"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reachable?"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RemoteControlDeviceListComponent_4)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "tfoot", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "td", [["colspan", "3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-spinner fa-pulse fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Searching... "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.devices; _ck(_v, 10, 0, currVal_0); }, null); }
function View_RemoteControlDeviceListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RemoteControlDeviceListComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-remote-control-helper", [["title", "Can't see your device here or it is not reachable?"]], null, null, null, i3.View_RemoteControlHelperComponent_0, i3.RenderType_RemoteControlHelperComponent)), i1.ɵdid(5, 114688, null, 0, i4.RemoteControlHelperComponent, [i5.ActivatedRoute], { title: [0, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.devices; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Can't see your device here or it is not reachable?"; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RemoteControlDeviceListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "pull-left title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Remote control "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RemoteControlDeviceListComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RemoteControlDeviceListComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isReady; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.isReady; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_RemoteControlDeviceListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-remote-control-device-list", [], null, null, null, View_RemoteControlDeviceListComponent_0, RenderType_RemoteControlDeviceListComponent)), i1.ɵdid(1, 49152, null, 0, i6.RemoteControlDeviceListComponent, [i5.Router, i7.RemoteControlService], null, null)], null, null); }
var RemoteControlDeviceListComponentNgFactory = i1.ɵccf("app-remote-control-device-list", i6.RemoteControlDeviceListComponent, View_RemoteControlDeviceListComponent_Host_0, { devices: "devices" }, {}, []);
export { RemoteControlDeviceListComponentNgFactory as RemoteControlDeviceListComponentNgFactory };
