import * as tslib_1 from "tslib";
import { OnInit, OnChanges } from '@angular/core';
import { cloneDeep, flatten, get, set, uniqBy, defaults } from 'lodash';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, merge, catchError, finalize } from 'rxjs/operators';
import { ProductService } from '@app/product/product.service';
import { PhotoService } from './photo.service';
import { ItemTypeService } from '@app/core/item-type.service';
import { UtilService } from '@app/core/util.service';
import { PhotoPostProductionComponent } from '@app/shared/photo-post-production/photo-post-production.component';
export class PhotoEditorComponent {
    constructor(productService, photoService, ngbActiveModal, toasterService, itemTypeService, ngbModal, util) {
        this.productService = productService;
        this.photoService = photoService;
        this.ngbActiveModal = ngbActiveModal;
        this.toasterService = toasterService;
        this.itemTypeService = itemTypeService;
        this.ngbModal = ngbModal;
        this.util = util;
        this.photo = {};
        this.reversedShortcutArrows = false;
        this.isSaving = false;
        this.isClosing = false;
        this.searching = false;
        this.searchFailed = false;
        this.hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
        this.search = (text$) => text$.pipe(debounceTime(300), distinctUntilChanged(), tap(() => this.searching = true), switchMap((term) => {
            this.searchTerm = term;
            if (term.length === 0) {
                this.searchResult = null;
                return of([]);
            }
            return this.productService.search(term)
                .pipe(tap((result) => {
                this.searchResult = result;
                this.searchFailed = false;
            }), catchError(() => {
                this.searchFailed = true;
                return of([]);
            }));
        }), tap(() => this.searching = false), merge(this.hideSearchingWhenUnsubscribed));
        this.productFormatter = (prod) => {
            return !prod.id ? null : `${this.productName(prod) || ''} (${prod.sku ? 'SKU' : 'ID'}: ${prod.sku || prod.id})`;
        };
        this.photoTypeFilter = ({ id }) => this.editor.photo.item_type_id === id;
    }
    ngOnInit() {
        if (!this.isReadOnly) {
            this.itemTypeService.load().subscribe(itemTypes => this.photoTypes = itemTypes);
        }
        this.changePhoto();
    }
    cancel() {
        this.ngbActiveModal.close();
    }
    save(close = false) {
        this.isClosing = close;
        this.isSaving = true;
        // save changes to the DB
        this.photoService.update(this.editor.photo)
            .pipe(finalize(() => { this.isSaving = false; }))
            .subscribe((response) => {
            const photo = response.body;
            this.toasterService.pop('success', `Photo updated!`, `The photo has been successfully updated.`);
            // make changes visible on the front end listing
            Object.assign(this.photo, Object.assign({}, photo, { product: photo.product || null }));
            if (close) {
                this.ngbActiveModal.close();
            }
        });
    }
    canCreateNewProduct() {
        if (this.searchResult && this.searchResult.find(({ id, name, sku }) => [id, name, sku].indexOf(this.searchTerm) > -1)) {
            return false;
        }
        return !!this.searchTerm;
    }
    createNewProduct() {
        if (!this.canCreateNewProduct()) {
            return;
        }
        this.editor.photo.product = { sku: this.searchTerm };
    }
    productName(product) {
        return get(product, 'attribute_values.name');
    }
    unlinkProduct() {
        this.editor.photo.product = null;
        this.editor.photo.product_id = null;
    }
    ngOnChanges(changes) {
        if (changes.photo) {
            this.changePhoto();
        }
    }
    changePhoto() {
        const photo = defaults(cloneDeep(this.photo), { item_type_id: null });
        this.editor = { photo };
        if (!this.editor.photo.attribute_values) {
            this.editor.photo.attribute_values = {};
        }
    }
    getPhotoTypeField() {
        return {
            type: 'string',
            input_element: 'select',
            title: 'Photo type',
            settings: {
                options: this.photoTypes,
            },
        };
    }
    getAttributeFields() {
        const photoTypes = this.photoTypes || [];
        const photoType = flatten(photoTypes
            .filter(this.photoTypeFilter)
            .map(photo => photo.custom_attributes));
        const attributeFields = uniqBy(photoType, 'slug');
        return attributeFields;
    }
    deletePhoto(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.photoService.confirmAndDelete(this.editor.photo.id);
                this.ngbActiveModal.close();
                if (this.onDeleteSuccess) {
                    this.onDeleteSuccess(this.editor.photo.id);
                }
            }
            catch (error) {
            }
        });
    }
    getDownloadUrl() {
        return this.util.getDownloadUrl(this.photo, this.basicInfo);
    }
    openCropper() {
        const modalRef = this.ngbModal.open(PhotoPostProductionComponent, {
            windowClass: 'modal-xl modal-dark',
            backdrop: 'static',
        });
        modalRef.componentInstance.setInitialValues(this.photo, get(this.basicInfo, 'settings.cropper'));
        modalRef.componentInstance.onCancel.subscribe(() => modalRef.close());
        modalRef.componentInstance.onSave.subscribe((response) => {
            if (response.body && response.body.url) {
                // update url, postProduction and crop (depracated) values of photo and editor.photo
                [this.photo, this.editor.photo].forEach(photo => {
                    photo.url = response.body.url;
                    set(photo, 'attribute_values.postProduction', get(response.body, 'attribute_values.postProduction'));
                    set(photo, 'attribute_values.crop', get(response.body, 'attribute_values.crop'));
                });
            }
            modalRef.close();
        });
        return modalRef;
    }
    keyPressed($event) {
        // do nothing if an input is focused
        if ($event.path && $event.path.length && ['INPUT', 'TEXTAREA'].includes($event.path[0].tagName)) {
            return;
        }
        const next = this.reversedShortcutArrows ? this.photo.prev : this.photo.next;
        const prev = this.reversedShortcutArrows ? this.photo.next : this.photo.prev;
        if (['ArrowRight', 'ArrowDown'].indexOf($event.key) !== -1 && next) {
            this.photo = next;
            this.changePhoto();
        }
        if (['ArrowLeft', 'ArrowUp'].indexOf($event.key) !== -1 && prev) {
            this.photo = prev;
            this.changePhoto();
        }
    }
}
