import { Injectable } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { DomSanitizer } from '@angular/platform-browser';
import { debounce, each, omit, isUndefined, isObject } from 'lodash';

export const ORIENTATION_LANDSCAPE = 'LANDSCAPE';
export const ORIENTATION_LANDSCAPE_LEFT = 'LANDSCAPE-LEFT';
export const ORIENTATION_LANDSCAPE_RIGHT = 'LANDSCAPE-RIGHT';
export const ORIENTATION_PORTRAIT = 'PORTRAIT';
export const ORIENTATION_PORTRAITUPSIDEDOWN = 'PORTRAITUPSIDEDOWN';
export const ORIENTATION_UNKNOWN = 'UNKNOWN';
export const DEFAULT_ASPECT_RATIO = '4:3';

interface DeviceInfoResponse {
  results: string[];
}

export interface CameraSettings {
  preset?: number;
  aspectRatio?: string;
  zoomLevel?: number;
  exposure?: number;
  exposureISO?: number;
  exposureDuration?: number;
  colorTemperature?: number;
  tint?: number;
  defaultSettings?: any;
  orientation?: string;
  focusPoint?: Array<2>;
  settingsVersion?: number;
}

export function getTransformedCameraSettings(settings: CameraSettings): CameraSettings {
    const transformedSettings = omit(settings, ['defaultSettings', 'preset', 'settingsVersion', 'isReady']);
    if (!settings || settings.colorTemperature === null) {
      transformedSettings.tint = 0;
    }

    return transformedSettings;
}

@Injectable()
export default class Device {
  id: string;
  name: string;
  infoUrl: string;
  info: object;
  videoSrc: any;
  settings: CameraSettings = {};

  private isLocalServerAvailable = false;
  private isConnecting = false;
  private isConnected = false;
  private photos = [];
  private pc: RTCPeerConnection;
  private onCaptureSuccess: Function;
  private onCaptureFailure: Function;
  private onUploadSuccess: Function;
  private onUploadFailure: Function;
  private updateSettingsDebouncer: Function;
  private settingsResolver = [];
  private disconnectionListener: Function;
  private onUploadProgress: Function;
  private uploadTimeout: number;
  private emitter: Function;

  constructor(
    private logger: Logger,
    private sanitizer: DomSanitizer,
  ) {
    this.updateSettingsDebouncer = debounce(() => {
      this.settings.settingsVersion += 1;
      this.emit('settings', this.settings);
    }, 250);
  }

  async init(id: string, name: string, infoUrl: string, emitter: Function) {
    this.id = id;
    this.name = name;
    this.emitter = emitter;
    await this.setInfoUrl(infoUrl);
  }

  async setInfoUrl(infoUrl: string) {
    this.infoUrl = infoUrl;

    if (!this.infoUrl) {
      this.localServerIsNotAvailable('empty info url');
      return;
    }

    try {
      await this.testLocalServerAvailability();
      this.localServerIsAvailable();
    } catch (error) {
      this.localServerIsNotAvailable(error);
    }

    if (!this.isConnected) {
      this.emit('disconnect');
    }
  }

  setDisconnectionListener(fn: Function = null) {
    this.disconnectionListener = fn;
  }

  addPhoto(id: string, data) {
    this.photos.push(data);
    if (this.onCaptureSuccess) {
      this.onCaptureSuccess(data);
      this.onCaptureSuccess = null;
    }
  }

  addPhotoFailed(error: string) {
    this.logger.error('Failed to add photo', error);
    if (this.onCaptureFailure) {
      this.onCaptureFailure(error);
      this.onCaptureFailure = null;
    }
  }

  setFocusPoint = (x = null, y = null) => {
    if (x === null) {
      this.settings.focusPoint = null;
      this.updateSettings();
      return;
    }

    let pointX = x;
    let pointY = y;

    // coordinate system is always relative to a landscape device orientation with
    // the home button on the right, regardless of the actual device orientation
    if (this.settings.orientation === ORIENTATION_LANDSCAPE_RIGHT) {
      pointX = 1 - x;
      pointY = 1 - y;
    }
    if (this.settings.orientation === ORIENTATION_PORTRAIT) {
      pointX = 1 - y;
      pointY = 1 - x;
    }

    this.settings.focusPoint = [pointX, pointY];
    // hide advanced exposure since focus point also sets exposure measuring point
    this.settings.exposureISO = null;
    this.settings.exposureDuration = null;
    this.updateSettings();
  }

  get latestPhoto() {
    return this.photos[this.photos.length - 1];
  }

  getPhotoByIndex(index) {
    return this.photos[index];
  }

  getDefaultSettings(): CameraSettings {
    const settings = {
      preset: null,
    };

    each(this.settings.defaultSettings, (val, key) => {
      if (isObject(val)) {
        settings[key] = isUndefined(val.resetValue) ? val.defaultValue : val.resetValue;
      } else {
        settings[key] = val;
      }
    });

    return getTransformedCameraSettings(settings);
  }

  getTransformedSettings(): CameraSettings {
    return getTransformedCameraSettings(this.settings);
  }

  setSettings(settings: CameraSettings) {
    this.logger.debug('Update camera settings', settings);
    if (!this.settings.settingsVersion || this.settings.settingsVersion < settings.settingsVersion) {
      this.settings = settings;
    }
    this.settingsResolver.forEach(resolve => resolve());
    this.settingsResolver = [];
  }

  setOrientation(orientation: string) {
    if (this.settings.orientation === orientation) {
      return;
    }

    this.settings.orientation = orientation;
    this.updateSettings();
  }

  destroy() {
    this.disconnect();
    this.photos = [];
  }

  disconnect() {
    this.settingsResolver = [];

    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }

    this.isConnected = false;
    this.emit('disconnect');

    if (this.disconnectionListener) {
      this.disconnectionListener();
    }
  }

  connect() {
    if (this.isConnected && this.isConnectable) {
      return Promise.resolve();
    }

    if (this.isConnecting) {
      this.logger.debug('device is in connecting state', this.id);
      return;
    }

    this.logger.debug('Connect device', this.id);
    return new Promise(async (resolve, reject) => {
      try {
        await this.testLocalServerAvailability();
        this.localServerIsAvailable();

        this.isConnecting = true;
        this.isConnected = true;
        this.emit('connect');
        this.getSettings(() => {
          this.isConnecting = false;
          resolve();
        });
      } catch (error) {
        this.localServerIsNotAvailable(error);
        reject(error);
      }
    });
  }

  capture(data: object = null): any {
    return new Promise((resolve, reject) => {
      this.onCaptureSuccess = resolve;
      this.onCaptureFailure = reject;
      this.emit('capture', data);
    });
  }

  uploaded(error: string, data = undefined) {
    const { onUploadSuccess, onUploadFailure } = this;
    this.onUploadProgress = null;
    this.onUploadSuccess = null;
    this.onUploadFailure = null;
    clearTimeout(this.uploadTimeout);

    if (!onUploadFailure || !onUploadSuccess) {
      return;
    }

    if (error) {
      onUploadFailure(error);
      return;
    }

    onUploadSuccess(data);
  }

  private setUploadTimeout(timeout = 10000) {
    clearTimeout(this.uploadTimeout);
    this.uploadTimeout = window.setTimeout(() => { this.uploaded('timeout'); }, timeout);
  }

  upload(data, onProgress) {
    return new Promise((resolve, reject) => {
      this.onUploadSuccess = resolve;
      this.onUploadFailure = reject;

      this.setUploadTimeout();
      this.onUploadProgress = (payload) => {
        this.setUploadTimeout();
        return onProgress && onProgress(payload);
      };
      this.emit('upload', {
        ...this.latestPhoto,
        ...data,
      });
    });
  }

  uploadProgress(payload) {
    if (this.onUploadProgress) {
      this.onUploadProgress(payload);
    }
  }

  resetSettings() {
    this.settings.preset = null;
    each(this.getDefaultSettings(), (setting, key) => {
      this.settings[key] = setting;
    });
    this.emit('reset-settings');
  }

  getSettings(onReady = null) {
    this.emit('get-settings');

    if (onReady) {
      this.settingsResolver.push(onReady);
    }
  }

  setPreset(id: number, settings: CameraSettings) {
    this.settings.preset = id;
    each(getTransformedCameraSettings(settings), (val, key) => {
      this.settings[key] = val;
    });
    this.updateSettings();
  }

  setAspectRatio(aspectRatio: string) {
    this.logger.debug('device.setAspectRatio', aspectRatio);
    this.settings.aspectRatio = aspectRatio;
    this.updateSettings();
  }

  setZoomLevel = (zoomLevel: number) => {
    this.settings.zoomLevel = zoomLevel;
    this.updateSettings();
  }

  setExposure = (exposure: number) => {
    this.settings.exposure = exposure;
    this.updateSettings();
  }

  setExposureISO = (exposureISO: number = null) => {
    this.settings.exposureISO = exposureISO;
    this.updateSettings();
  }

  setExposureDuration = (exposureDuration: number = null) => {
    this.settings.exposureDuration = exposureDuration;
    this.updateSettings();
  }

  setColorTemperature = (colorTemperature: number) => {
    this.settings.colorTemperature = colorTemperature;
    this.updateSettings();
  }

  setTint = (tint: number) => {
    this.settings.tint = tint;
    this.updateSettings();
  }

  addIceCandidate(candidate) {
    this.getPeerConnection().addIceCandidate(new RTCIceCandidate(candidate));
  }

  setRemoteDescription(sdp) {
    this.getPeerConnection().setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
      if (this.pc.remoteDescription.type === 'offer') {
        this.pc.createAnswer().then((desc) => {
          this.logger.debug('createAnswer', desc);
          this.pc.setLocalDescription(desc).then(() => {
            this.logger.debug('setLocalDescription', this.pc.localDescription);
            this.emit('exchange', { sdp: this.pc.localDescription });
          }, this.logger.error);
        }, this.logger.error);
      }
    }, this.logger.error);
  }

  get isConnectable() {
    return this.isLocalServerAvailable;
  }

  private getPeerConnection(): RTCPeerConnection {
    return this.pc || this.createPeerConnection(false);
  }

  private createPeerConnection(isOffer: boolean) {
    this.pc = new RTCPeerConnection({});

    this.pc.onicecandidate = (event) => {
      this.logger.debug('onicecandidate', event);
      if (event.candidate) {
        this.emit('exchange', { candidate: event.candidate });
      }
    };

    this.pc.onnegotiationneeded = () => {
      this.logger.debug('onnegotiationneeded');
      if (isOffer) {
        this.createOffer();
      }
    };

    this.pc.oniceconnectionstatechange = (event) => {
      this.logger.debug('oniceconnectionstatechange', event);
    };

    this.pc.onsignalingstatechange = (event) => {
      this.logger.debug('onsignalingstatechange', event);
    };

    (<any>this.pc).ontrack = (event) => {
      this.logger.debug('ontrack', event);
      this.addRemoteTrack(event);
    };

    return this.pc;
  }

  private addRemoteVideo({ stream }: { stream: any }) {
    this.logger.debug('addRemoteVideo');
    this.videoSrc = stream;
  }

  private addRemoteTrack(event) {
    this.logger.debug('addRemoteTrack');
    this.videoSrc = event.streams[0];
  }

  private createOffer() {
    this.pc.createOffer().then((desc) => {
      this.logger.debug('createOffer', desc);
      this.pc.setLocalDescription(desc).then(() => {
        this.logger.debug('setLocalDescription', this.pc.localDescription);
        this.emit('exchange', { sdp: this.pc.localDescription });
      }, this.logger.error);
    }, this.logger.error);
  }

  emit(action: string, payload: object = null) {
    this.emitter(this.id, action, payload);
  }

  private testLocalServerAvailability() {
    return new Promise((resolve, reject) => {
      const image = new Image();

      const timer = setTimeout(() => {
        image.onerror = image.onabort = image.onload = () => {};
        reject();
      }, 3500);

      image.onload = () => {
        clearTimeout(timer);
        resolve();
      };
      image.onerror = image.onabort = () => {
        clearTimeout(timer);
        reject();
      };
      image.src = `${this.infoUrl}&rnd=${Math.random()}`;
    });
  }

  private localServerIsAvailable() {
    this.isLocalServerAvailable = true;
    this.logger.debug('Device local server is available at', this.infoUrl);
  }

  private localServerIsNotAvailable(error) {
    this.isLocalServerAvailable = false;
    this.logger.debug('Device local server is not available at', this.infoUrl, error);
  }

  private updateSettings() {
    this.updateSettingsDebouncer();
  }
}
