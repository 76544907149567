import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Observable, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProductEditorComponent } from '@app/product/product-editor.component';
import { PhotoEditorComponent } from '@app/photo/photo-editor.component';
import { CollectionService } from '@app/collection/collection.service';
import { UtilService } from '@app/core/util.service';

@Component({
  selector: 'app-public-collection',
  templateUrl: './public-collection.component.html',
  styleUrls: ['./public-collection.component.scss'],
})
export class PublicCollectionComponent implements OnInit {
  @ViewChild('downloadProductTemplate') downloadProductTemplate: TemplateRef<any>;
  @ViewChild('downloadPhotoTemplate') downloadPhotoTemplate: TemplateRef<any>;
  modalRef;
  isLoading = true;
  items = [];
  collection;
  columns = {
    [CollectionService.TYPE_PRODUCT]: [
      { name: 'sku', label: 'SKU', type: 'text' },
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'files', label: 'Image', type: 'first-image' },
    ],
    [CollectionService.TYPE_PHOTO]: [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'url', label: 'Image', type: 'image' },
    ],
  };
  actions;
  titles = {
    [CollectionService.TYPE_PRODUCT]: 'Products',
    [CollectionService.TYPE_PHOTO]: 'Photos',
  };

  basicInfo;

  constructor(
    private route: ActivatedRoute,
    private collectionService: CollectionService,
    private ngbModal: NgbModal,
    private toasterService: ToasterService,
    private util: UtilService,
  ) { }

  get type() {
    return this.collection.collection_type;
  }

  get title() {
    return this.titles[this.type];
  }

  ngOnInit() {
    this.basicInfo = this.route.parent.snapshot.data['basicInfo'];

    this.route.params.subscribe(params => {
      this.actions  = {
        [CollectionService.TYPE_PRODUCT]: [{ tpl: this.downloadProductTemplate, tplVars: { collectionKey: params.key } }],
        [CollectionService.TYPE_PHOTO]: [{ tpl: this.downloadPhotoTemplate, tplVars: { collectionKey: params.key } }],
      };

      this.collectionService.getByKey(params.key)
        .pipe(finalize(() => { this.isLoading = false; }))
        .subscribe((response: any) => {
          this.collection = response.body;
          this.items = response.body.items.data;
          this.basicInfo = { user: { organization: {
            settings: { definitions: this.collection.schema },
          } } };
        });
    });
  }

  loadMore() {
    this.collectionService.getByKey(this.collection.key, this.collection.items.page + 1)
      .pipe(finalize(() => { this.isLoading = false; }))
      .subscribe((response: any) => {
        this.collection = response.body;
        this.items = this.items.concat(response.body.items.data);
      });
  }

  getColumns() {
    return this.columns[this.type];
  }

  getActions() {
    return this.actions[this.type];
  }

  isProductCollection() {
    return this.collection.collection_type === CollectionService.TYPE_PRODUCT;
  }

  isPhotoCollection() {
    return this.collection.collection_type === CollectionService.TYPE_PHOTO;
  }

  openItem(item) {
    // the timer is a workaround for this: https://github.com/angular/angular/issues/15634
    timer(0).subscribe(() => {
      if (this.isPhotoCollection()) {
        this.openPhoto(item);
      }
      if (this.isProductCollection()) {
        this.openProduct(item);
      }
    });
  }

  private openProduct(product) {
    this.modalRef = this.ngbModal.open(ProductEditorComponent, { size: 'lg' });
    this.modalRef.componentInstance.isReadOnly = true;
    this.modalRef.componentInstance.product = product;
    this.modalRef.componentInstance.products = this.collection.products;
    this.modalRef.componentInstance.basicInfo = this.basicInfo;
    this.modalRef.componentInstance.changeProduct.call(this.modalRef.componentInstance);
  }

  private openPhoto(photo) {
    this.modalRef = this.ngbModal.open(PhotoEditorComponent, { size: 'lg' });
    this.modalRef.componentInstance.isReadOnly = true;
    this.modalRef.componentInstance.photo = photo;
    this.modalRef.componentInstance.photos = this.collection.files;
    this.modalRef.componentInstance.basicInfo = this.basicInfo;
    this.modalRef.componentInstance.changePhoto.call(this.modalRef.componentInstance);
  }
}
