import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { set, get, isArray } from 'lodash';

import { ResizedImagePipe } from '@app/shared/resized-image.pipe';

import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_PORTRAITUPSIDEDOWN,
} from '@app/remote-control/device';

@Injectable()
export class UtilService {
  constructor(private cookieService: CookieService) { }

  linkPrevNext(arr) {
    for (let i = 0; i < arr.length; i++) {
      // remove previous links
      arr[i].next = arr[i].prev = undefined;
      // establish new links if they're available
      if (i < arr.length - 1) {
        arr[i].next = arr[i + 1];
      }
      if (i > 0) {
        arr[i].prev = arr[i - 1];
      }
    }
    return arr;
  }

  setTemporaryAuthCookie() {
    // use cookies as a temporary auth method for download link
    // the server will respond with a header telling the browser to remove the cookie
    this.cookieService.put('Authorization', localStorage.getItem('accessToken'));
  }

  setOnEach(items, key, value) {
    items.forEach(p => set(p, key, value));
  }

  getSelectedIds(items = []) {
    if (!isArray(items)) {
      return [];
    }
    return items.filter(p => p.isSelected).map(p => p.id);
  }

  selectAllContent(event) {
    if (!event) { return; }
    if (event.target) { event.target.select(); }
    if (typeof event.select === 'function') { event.select(); }
  }

  getAspectParts(aspect: string) {
    if (!aspect || !aspect.length) {
      return;
    }
    const parts = aspect.split(':');
    if (parts.length === 2) {
      return parts.map(p => Number(p));
    }
  }

  getNumericAspect(aspect: string) {
    const parts = this.getAspectParts(aspect);
    if (!parts) { return null; }
    return Number(parts[0]) / Number(parts[1]);
  }

  flipAspectRatioNumber(aspect: string): number {
    const numericAspect = this.getNumericAspect(aspect);
    if (numericAspect === 0) { return null; }
    return 1 / numericAspect;
  }

  flipAspectRatio(aspect: string): string {
    const parts = this.getAspectParts(aspect);
    if (!parts || parts.length !== 2 || isNaN(parts[0]) || isNaN(parts[1])) {
      return aspect;
    }
    return `${parts[1]}:${parts[0]}`;
  }

  flipAspectRatioToOrientation(aspect: string, orientation: string): string {
    const isOrientationPortrait = orientation === ORIENTATION_PORTRAIT || orientation === ORIENTATION_PORTRAITUPSIDEDOWN;
    const numericAspect = this.getNumericAspect(aspect);
    const isAspectPortrait = numericAspect < 1;
    // flip the aspect if orientation differs from aspect
    if (isOrientationPortrait !== isAspectPortrait && numericAspect !== 1) {
      return this.flipAspectRatio(aspect);
    }

    return aspect;
  }

  getDownloadUrl(image, basicInfo) {
    const resizedImage = new ResizedImagePipe();
    // TODO: allow user to select which preset to use if any
    const sizePreset = get(basicInfo, 'settings.default_photo_preset.settings.export', {});

    let size = null;
    let methodFlag = '';
    const { resize } = sizePreset;
    if (resize) {
      const { width, height, method } = resize;
      size = `${width}x${height}`;
      methodFlag = method ? `${method}-` : '';
    }

    return resizedImage.transform(image.url, size, `${methodFlag}download`);
  }

  greatestCommonDivisor(a, b) {
    return b ? this.greatestCommonDivisor(b, a % b) : a;
  }

  reduceFraction(numerator, denominator, checkNeighbours) {
    const divisorCandidates = [{
      numerator,
      denominator,
      divisor: this.greatestCommonDivisor(numerator, denominator),
    }];

    // check neighbours flag allows +/- 1 error in numerator and denominator.
    // This way we can guess the original aspect ratio of rounded numbers
    if (checkNeighbours) {
      for (let n = -1; n <= 1; n++) {
        for (let d = -1; d <= 1; d++) {
          const numeratorCandidate = numerator + n;
          const denominatorCandidate = denominator + d;
          divisorCandidates.push({
            numerator: numeratorCandidate,
            denominator: denominatorCandidate,
            divisor: this.greatestCommonDivisor(numeratorCandidate, denominatorCandidate),
          });
        }
      }
      // sort in descending order
      divisorCandidates.sort((a, b) => b.divisor - a.divisor);
    }
    const greatestCommon = divisorCandidates[0].divisor;
    return [
      divisorCandidates[0].numerator / greatestCommon,
      divisorCandidates[0].denominator / greatestCommon,
    ];
  }
}
