import { ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Observable, timer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, merge } from 'rxjs/operators';
import { isArray, get, cloneDeep, find } from 'lodash';
import { TreeviewItem } from 'ngx-treeview';
import { ProductService } from '@app/product/product.service';
const defaultKeyDown = () => { };
const ɵ0 = defaultKeyDown;
export class AttributeFieldComponent {
    constructor(productService) {
        this.productService = productService;
        this.showLabel = true;
        this.copyButton = false;
        this.keydown = defaultKeyDown;
        this.searching = false;
        this.searchFailed = false;
        this.hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
        this.treeConf = {};
        this.treeItems = [];
        this.selectedNodes = [];
        this.isTreeEditable = false;
        this.copyButtonText = "Copy";
        this.propagateChange = (event) => { };
        this.productTagSearch = (field, text$) => {
            return text$
                .pipe(debounceTime(300), distinctUntilChanged(), tap(() => this.searching = true), switchMap(term => this.productService.searchTag(field, term)
                .pipe(tap(() => this.searchFailed = false), catchError(() => {
                this.searchFailed = true;
                return of([]);
            }))), tap(() => this.searching = false), merge(this.hideSearchingWhenUnsubscribed));
        };
        this.isArray = isArray;
        this.get = get;
    }
    ngOnInit() {
        if (this.field.input_element === 'tree' && this.field.settings.options) {
            const options = cloneDeep(this.field.settings.options);
            this.updateNodes(options);
            this.treeItems = options.map(option => {
                return new TreeviewItem(option);
            });
        }
    }
    toggleTreeEditable() {
        this.isTreeEditable = !this.isTreeEditable;
        if (this.isTreeEditable) {
            timer().subscribe(() => this.updateNodes(get(this.treeElement, 'items')));
        }
    }
    getSelectedNodes() {
        if (this.field.input_element !== 'tree' || !this.treeElement) {
            return [];
        }
        const selectedNodes = this.findSelectedChildren(get(this.treeElement, 'items'));
        return selectedNodes.map(node => ({ text: node.text, value: node.value }));
    }
    findSelectedChildren(nodes) {
        const selectedNodes = [];
        nodes.forEach(node => {
            if (node.checked) {
                selectedNodes.push(node);
            }
            if (isArray(node.children)) {
                selectedNodes.push(...this.findSelectedChildren(node.children));
            }
        });
        return selectedNodes;
    }
    isNodeSelected(node) {
        return !!find(this.value, item => item.value === node.value);
    }
    updateNodes(nodes) {
        if (!nodes) {
            return;
        }
        nodes.forEach(node => {
            if (node.text && !node.value) {
                node.value = node.text;
            }
            node.checked = this.isNodeSelected(node);
            if (node.children) {
                this.updateNodes(node.children);
            }
        });
    }
    isCheckedOption(option) {
        if (!isArray(this.value)) {
            return false;
        }
        return this.value.includes(option.id || option);
    }
    treeSelectionChanged(event) {
        if (!this.treeElement) {
            return;
        }
        this.value = this.getSelectedNodes();
        this.propagateChange(this.value);
    }
    writeValue(value) {
        this.value = value;
        if (this.field.input_element === 'tree' && isArray(value)) {
            this.updateNodes(get(this.treeElement, 'items'));
        }
        if (this.field.type === 'date-time-range') {
            this.value = { start: value ? value.start : null, end: value ? value.end : null };
        }
        if (this.field.type === 'array' && !isArray(this.value)) {
            this.value = [];
        }
    }
    changeMultiSelectOption(event, value) {
        if (event.target.checked) {
            this.value.push(value);
        }
        else {
            this.value.splice(this.value.indexOf(value), 1);
        }
        this.propagateChange(this.value);
    }
    registerOnChange(fn) {
        this.propagateChange = (value) => {
            const fieldOnChange = get(this.field, 'settings.onChange');
            if (fieldOnChange) {
                fieldOnChange(value);
            }
            if (this.field.type === 'date-time-range') {
                if (!get(this.value, 'start') && !get(this.value, 'end')) {
                    fn(undefined);
                    return;
                }
                else {
                    if (get(this.value, 'start') && !get(this.value, 'end')) {
                        this.value.end = new Date();
                    }
                    if (get(this.value, 'end')) {
                        this.value.end.setHours(23);
                        this.value.end.setMinutes(59);
                    }
                }
                fn(Object.assign({}, this.value));
                return;
            }
            fn(value);
        };
    }
    registerOnTouched() { }
    onProductTagSelect(event, input) {
        if (event.item) {
            event.preventDefault();
            input.value = event.item;
            this.addProductTag(event, input);
        }
    }
    onProductTagInputKeyUp(event, input) {
        if (event.key === 'Enter') {
            this.addProductTag(event, input);
        }
    }
    addProductTag(event, input) {
        if (!isArray(this.value)) {
            this.value = [];
        }
        if (input.value && this.value.indexOf(input.value) === -1) {
            this.value.push(input.value);
            input.value = '';
            this.propagateChange(this.value);
        }
    }
    removeProductTag(tag) {
        this.value.splice(this.value.indexOf(tag), 1);
    }
    getDateFormat() {
        return get(this.field, 'settings.dateFormat') || 'YYYY/MM/DD HH:mm (UTC Z)';
    }
    getDatePickerType() {
        return get(this.field, 'settings.type') || 'both';
    }
    select() {
        if (this.textField) {
            this.textField.nativeElement.select();
        }
    }
    copyText(event) {
        console.log(this.value);
        /* Get the text field */
        var copyText = this.textField.nativeElement;
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        /* Copy the text inside the text field */
        // @ts-ignore
        navigator.clipboard.writeText(copyText.value);
        this.copyButtonText = "Copied !!";
        setTimeout(() => {
            this.copyButtonText = "Copy";
        }, 1500);
    }
}
export { ɵ0 };
