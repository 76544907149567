<app-pagination-list-view
  title="Photos"
  [service]="photoService"
  [(items)]="photos"
>
  <ng-container header-buttons>
    <app-batch-actions-dropdown
      [selectedCount]="selectedCount()"
      [actions]="batchActions()"
    ></app-batch-actions-dropdown>
  </ng-container>

  <app-item-list
    view-content
    [items]="photos"
    [actions]="photoActions"
    [columns]="photoColumns"
    [isSelectable]="true"
    (onAction)="photoActionHandler($event)"
    (onSelectItem)="openPhoto($event)"
  ></app-item-list>
</app-pagination-list-view>
