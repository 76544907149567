import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Slider, SliderInterface } from '../slider/slider';
import {zip} from "rxjs";

@Component({
  selector: 'app-zoom-component',
  templateUrl: './zoom-button.component.html',
  styleUrls: ['./zoom-button.component.scss'],
})
export class ZoomButtonComponent implements OnInit {
  zoomSlider: Slider;
  zoomDevice = {
    settings: {
      zoom: 0,
      defaultSettings: { zoom: {
          step: 0.01,
          minimumValue: 0,
          maximumValue: 10,
        } },
    },
    setZoomIn: (zoom) => {
      if (this.zoom + zoom <= 3)
      this.zoom += zoom;
    },
    setZoomOut:(zoom)=>{
      this.zoom-=zoom;
    }
  };
  @Output() zoomChange = new EventEmitter<number>();

  private _zoom = 0;

  constructor(private domSanitizer: DomSanitizer) { }

  @Input()
  set zoom(zoom) {
    this._zoom =zoom;
    this.zoomDevice.settings.zoom = zoom;
    this.zoomChange.emit(this._zoom);
  }
  get zoom(){
    return this._zoom;
  }


  ngOnInit() {

  }
}
