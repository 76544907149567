import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PhotoService } from './photo.service';
import { UtilService } from '@app/core/util.service';
import { PhotoEditorComponent } from '@app/photo/photo-editor.component';
import { CollectionService } from '@app/collection/collection.service';
import { AddToCollectionComponent } from '@app/shared/add-to-collection/add-to-collection.component';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
})
export class PhotoComponent implements OnInit {
  photos = [];
  basicInfo;
  mode = 'list';
  cdnUrl;
  modalRef;
  areAllSelected;
  photoActions = [{ type: 'delete', icon: 'fa fa-trash', title: 'Delete' }];
  photoColumns = [
    { name: 'id', label: 'ID', type: 'text' },
    { name: 'product.sku', label: 'Product SKU', type: 'text' },
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'url', label: 'Image', type: 'image' },
    { name: 'created_at', label: 'Created', type: 'date' },
    { name: 'updated_at', label: 'Modified', type: 'date' },
  ];


  constructor(
    public photoService: PhotoService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private ngbModal: NgbModal,
    private util: UtilService,
    private collectionService: CollectionService,
  ) { }

  ngOnInit() {
    // store basic info that was fetched in the route resolve to the state
    this.basicInfo = this.route.parent.snapshot.data['basicInfo'];
    this.cdnUrl = this.basicInfo.cdnUrl;
  }

  async photoActionHandler($event) {
    if ($event.action.type === 'delete') {
      $event.event.stopPropagation();
      try {
        await this.photoService.confirmAndDelete($event.item.id);
      } catch (error) {

      }
    }
  }

  openPhoto(photo) {
    this.modalRef = this.ngbModal.open(PhotoEditorComponent, { size: 'lg' });
    this.modalRef.componentInstance.photo = photo;
    this.modalRef.componentInstance.basicInfo = this.basicInfo;
  }

  batchActions() {
    return [{
      label: 'Add to collection',
      action: () => this.addToCollection(),
      icon: 'folder-open',
    }, {
      label: 'Download',
      action: () => this.export(),
      href: `/api/file/export/${this.selectedIds()}`,
      icon: 'download',
    }, {
      label: 'Delete',
      action: () => this.batchDelete(),
      icon: 'trash',
      btnType: 'danger',
      count: 2,
    }];
  }

  toggleIsSelected($event, photo) {
    $event.stopPropagation();
    photo.isSelected = !photo.isSelected;
    this.areAllSelected = false;
  }

  setEveryPhoto($event, key, value) {
    this.util.setOnEach(this.photos, key, value);
  }

  selectedCount() {
    return this.util.getSelectedIds(this.photos).length;
  }

  selectedIds() {
    return this.util.getSelectedIds(this.photos).join(',');
  }

  export() {
    this.util.setTemporaryAuthCookie();
  }

  async batchDelete() {
    try {
      await this.photoService.confirmAndDeleteBatch(this.util.getSelectedIds(this.photos));
    } catch (error) {

    }
  }

  addToCollection() {
    this.modalRef = this.ngbModal.open(AddToCollectionComponent, { size: 'lg' });
    this.modalRef.componentInstance.selectedPhotos = this.util
      .getSelectedIds(this.photos).map(i => ({ id: i }));
  }

}
