<link href="https://fonts.googleapis.com/css?family=Raleway" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet">

<header>
  <div class="full-width">
    <nav>
      <i class="icon-menu mobile-only"></i>
      <!-- <a href="/" class="header-img"><img src="assets/img/logo-black.svg" /></a> -->
    </nav>

    <nav class="main-nav desktop-only">
      <a href="#">Home</a>
      <a href="#">Blog</a>
      <a href="#">Shop</a>
      <a href="#">Contact</a>
    </nav>

    <nav>
      <a style="font-size: 1.5em;"><i class="fa fa-shopping-cart"></i></a>
    </nav>
  </div>
</header>

<section class="hero">
  <img src="assets/img/hero.jpg">
</section>

<section class="full-width">
  <h2>Watches</h2>
  <h3>Featured items</h3>

  <div class="liftup" *ngIf="watchLiftup">
    <app-fixed-ratio-img
        [src]="watchLiftup.files[0].url | resizedImage"
        [ratio]="'545:441'">
      <div class="overlay">
        <div>
          <span class="title">{{ watchLiftup.attribute_values['liftup-title'] }}</span><br>
          <span class="highlighted">{{ watchLiftup.attribute_values['liftup-description'] }}</span><br>
          <!-- <span class="category">{{ watchLiftup.attribute_values['category'] }}</span> -->
        </div>
      </div>
    </app-fixed-ratio-img>
  </div>

  <div class="grid">
    <figure
      *ngFor="let item of watches"
      (mouseenter)="startCarousel(item)"
      (mouseleave)="stopCarousel(item)">

      <ng-container *ngIf="item.files">
        <app-fixed-ratio-img
          *ngFor="let file of item.files; let i = index"
          [src]="file.url | resizedImage"
          [ratio]="'3:2'"
          [ngClass]="{ visible: item.thumbFile === i }"></app-fixed-ratio-img>
      </ng-container>

      <app-fixed-ratio-img *ngIf="item.url" [src]="item.url | resizedImage"
        [ratio]="'3:2'"></app-fixed-ratio-img>

      <app-fixed-ratio-img *ngIf="item.files && item.files.length === 0"
        [src]="'assets/img/placeholder.svg'"
        [ratio]="'3:2'"
        [isCropped]="false"></app-fixed-ratio-img>

      <figcaption>
        {{ item.attribute_values.name }}<br>
        <span class="price"
          *ngIf="item.attribute_values.price"
          [ngClass]="{ regular: item.attribute_values['sale-price'] }">
            {{ item.attribute_values.price }}&nbsp;&euro;
        </span>
        <span *ngIf="item.attribute_values['sale-price']" class="sale-price">{{ item.attribute_values['sale-price'] }}&nbsp;&euro;</span>
      </figcaption>
    </figure>
  </div>
</section>

<section class="full-width">
  <h2>Wallets</h2>
  <h3>Featured items</h3>

  <div class="liftup" *ngIf="walletLiftup">
    <app-fixed-ratio-img
        [src]="walletLiftup.files[0].url | resizedImage"
        [ratio]="'545:441'">
      <div class="overlay">
        <div>
          <span class="title">{{ walletLiftup.attribute_values['liftup-title'] }}</span><br>
          <span class="highlighted">{{ walletLiftup.attribute_values['liftup-description'] }}</span><br>
          <!-- <span class="category">{{ walletLiftup.attribute_values['category'] }}</span> -->
        </div>
      </div>
    </app-fixed-ratio-img>
  </div>

  <div class="grid">
    <figure
      *ngFor="let item of wallets"
      (mouseenter)="startCarousel(item)"
      (mouseleave)="stopCarousel(item)">

      <ng-container *ngIf="item.files">
        <app-fixed-ratio-img
          *ngFor="let file of item.files; let i = index"
          [src]="file.url | resizedImage"
          [ratio]="'3:2'"
          [ngClass]="{ visible: item.thumbFile === i }"></app-fixed-ratio-img>
      </ng-container>

      <app-fixed-ratio-img *ngIf="item.url" [src]="item.url | resizedImage"
        [ratio]="'3:2'"></app-fixed-ratio-img>

      <app-fixed-ratio-img *ngIf="item.files && item.files.length === 0"
        [src]="'assets/img/placeholder.svg'"
        [ratio]="'3:2'"
        [isCropped]="false"></app-fixed-ratio-img>

      <figcaption>
        {{ item.attribute_values.name }}<br>
        <span class="price"
          *ngIf="item.attribute_values.price"
          [ngClass]="{ regular: item.attribute_values['sale-price'] }">
            {{ item.attribute_values.price }}&nbsp;&euro;
        </span>
        <span *ngIf="item.attribute_values['sale-price']" class="sale-price">{{ item.attribute_values['sale-price'] }}&nbsp;&euro;</span>
      </figcaption>
    </figure>
  </div>
</section>

<ng-template #modalTemplate let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ selectedProduct.attribute_values.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col">
        <app-fixed-ratio-img class="big-img" *ngIf="getSelectedImageUrl()"
          [src]="getSelectedImageUrl() | resizedImage"
          [ratio]="'4:3'"
          [isCropped]="false"
          [link]="getSelectedImageUrl()"
        ></app-fixed-ratio-img>

        <app-fixed-ratio-img *ngIf="!getSelectedImageUrl()"
            [src]="'assets/img/placeholder.svg'"
            [ratio]="'4:3'"
            [isCropped]="false"></app-fixed-ratio-img>

        <ul class="thumb-strip">
          <li *ngFor="let image of selectedProduct.files" (click)="selectImage(image)" [ngClass]="{ 'selected': selectedProduct.selectedImage && image.id === selectedProduct.selectedImage.id}">
            <app-fixed-ratio-img *ngIf="selectedProduct.files.length" [src]="image.url | resizedImage"></app-fixed-ratio-img>
          </li>
        </ul>
      </div>

      <div class="col">
        <p>{{ selectedProduct.attribute_values.description }}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
  </div>
</ng-template>
