import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { get, isEqual } from 'lodash';
import { Observable, timer } from 'rxjs';

import {
  ItemListComponent,
  ColumnsInterface,
  ActionsInterface,
} from '../item-list/item-list.component';

export interface PaginationItemListInterface {
  columns: Array<ColumnsInterface>;
  actions: Array<ActionsInterface>;
  isSelectable: boolean;
  onAction: Function;
  onSelectItem: Function;
}

@Component({
  selector: 'app-pagination-list-view',
  templateUrl: './pagination-list-view.component.html',
  styleUrls: ['./pagination-list-view.component.scss'],
})
export class PaginationListViewComponent implements OnInit {
  @ViewChild(ItemListComponent) itemListView: ItemListComponent;

  @Input() title: string;
  @Input() service;
  @Input() itemList;
  @Input() isFiltersOpen;
  @Output() itemsChange = new EventEmitter();
  @Output() filtersChange = new EventEmitter();
  @Output() saveAsCollection = new EventEmitter();
  filtersCount = 0;
  @Input()
  totalPhotosForProcessing:number=0;
  private itemsList;
  private filtersList;
  private appliedFilters;

  constructor() { }

  ngOnInit() {
    this.fetchItems(true);
  }

  fetchItems(reset: boolean = false) {
    this.service.getNextPage(reset, this.getFiltersAndUpdateCount()).subscribe(items => this.items = items);
  }

  @Input()
  get items() {
    return this.itemsList;
  }

  set items(items) {
    this.itemsList = items;
    this.itemsChange.emit(this.items);
  }

  @Input()
  get filters() {
    return this.filtersList;
  }

  set filters(filters) {
    filters.forEach((filter) => {
      if (filter.type === 'date-time') {
        filter.type = 'date-time-range';
      }
    });
    this.filtersList = filters;
    this.filtersChange.emit(this.filters);
  }

  applyFiltersAction() {
    if (this.itemListView) {
      this.itemListView.toggleAll(false);
    }
    timer(0).subscribe(() => this.fetchItems(true));
  }

  clearFiltersAction() {
    this.filters.forEach((filter) => {
      filter.filter_value = undefined;
      const fieldOnChange = get(filter, 'settings.onChange', () => undefined);
      fieldOnChange(filter.filter_value);
    });
    this.applyFiltersAction();
  }

  getUnappliedFiltersCount(): number {
    const filters = this.getFilters();
    return filters ? filters.length : 0;
  }

  hasFiltersChanged() {
    return !isEqual(this.appliedFilters, this.getFilters());
  }

  getSelectedFilters(string = false) {
    const getFilter = string ? this.service.getFiltersString : this.service.getFilters;

    return getFilter(this.isOverSelected() ? null : this.itemListView.getSelectedIds());
  }

  isOverSelected() {
    return !this.itemListView || this.itemListView.selectAllTotal;
  }

  getSelectedIds() {
    if (!this.itemListView) {
      return [];
    }

    return this.itemListView.getSelectedIds();
  }

  saveCollection() {
    this.saveAsCollection.emit(this.getFilters());
  }

  private getFilters() {
    if (!this.filters) {
      return null;
    }

    return this.filters
      .filter(({ filter_value: value, type, input_element: inputElement }) => {
        if (type === 'string' && inputElement === 'text' && !value) {
          return false;
        }

        return value !== undefined;
      })
      .map(({ slug, isField, filter_value }) => ({
        [isField ? 'field' : 'attribute']: slug,
        value: filter_value,
      }));
  }

  private getFiltersAndUpdateCount() {
    const filters = this.getFilters();

    this.filtersCount = filters ? filters.length : 0;
    this.appliedFilters = filters;

    return filters;
  }
}
