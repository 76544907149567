import { Component, Input, HostListener, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { cloneDeep, get, isArray, uniqBy, flatten, findIndex, orderBy, isObject, isString } from 'lodash';
import { ToasterService } from 'angular2-toaster';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PhotoPostProductionComponent } from '@app/shared/photo-post-production/photo-post-production.component';
import { ProductService } from './product.service';
import { UtilService } from '@app/core/util.service';
import { ResizedImagePipe } from '@app/shared/resized-image.pipe';

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.scss'],
})
export class ProductEditorComponent implements OnChanges {
  @ViewChild(PhotoPostProductionComponent) postProductionElement: PhotoPostProductionComponent;
  @ViewChild(NgForm) productForm: NgForm;

  mode = null;
  isSaving = false;
  isClosing = false;
  batchCreateCount = 1;
  selectedImage;
  editor;
  photoDate:any=null;
  @Input() isReadOnly;
  @Input() basicInfo;
  @Input() products;
  @Input() productTypes;

  sortablejsOptions = {
    onChoose: (evt) => evt.item.click(),
  };

  private editableProducts;

  constructor(
    private productService: ProductService,
    private toasterService: ToasterService,
    private ngbActiveModal: NgbActiveModal,
    private router: Router,
    private util: UtilService,
    private resizedImage: ResizedImagePipe,
    private ngbModal: NgbModal,
  ) { }

  get skuGenerators() {
    return this.basicInfo.skuGenerators;
  }

  get selectedSkuGenerator() {
    return this.basicInfo.skuGenerators.find(({ id }) => id === this.editor.product.sku.type);
  }

  get productLanguage() {
    const img = this.getSelectedImage();
    const lang = img && img.attribute_values ? img.attribute_values.language : ""

    switch(lang) {
      case "fi":
        return "Finnish";
      case "en":
        return "English";
      case "se":
        return "Swedish";
      default:
        return "";
      }
  }

  get skuGeneratorConfiguration() {
    return {
      default: this.skuGenerators[0].id,
      settings: {},
      ...get(this.basicInfo, 'settings.skuGenerators', {}),
    };
  }

  get defaultSkuGenerator() {
    const defaultId = this.skuGeneratorConfiguration.default;
    const generator = this.skuGenerators.find(({ id }) => id === defaultId);
    return generator || this.skuGenerators[0];
  }

  getSkuGeneratorSettings(generatorId = this.selectedSkuGenerator.id) {
    return this.skuGeneratorConfiguration.settings[generatorId];
  }

  @Input()
  get product() {
    return this.editableProducts;
  }

  set product(product) {
    this.editableProducts = isArray(product) ? product : [product];
  }

  getEditableProductCount() {
    return this.editableProducts.length;
  }

  isBatchEdit() {
    return this.getEditableProductCount() > 1;
  }

  ngOnChanges(changes) {
    if (changes.product) {
      this.changeProduct();
    }
  }

  isSelectedImage(id: number) {
    if (this.selectedImage) {
      return id === this.selectedImage.id;
    }

    return get(this.getSelectedImage(), 'id') === id;
  }

  changeProduct() {
    if (!this.getEditableProductCount()) {
      return;
    }

    this.editor = { product: this.isBatchEdit() ? {} : cloneDeep(this.product[0]) };
    if (!this.editor.product.attribute_values) {
      this.editor.product.attribute_values = {};
    }

    if (!this.editor.product.id && !this.isBatchEdit()) {
      this.editor.product.item_type_id = this.getDefaultProductTypeId();
    }
  }

  getDefaultProductTypeId() {
    return get(this.productTypes.find(({ is_default }) => is_default), 'id', null);
  }

  cancel() {
    if (this.mode === 'share') {
      this.mode = null;
    } else {
      this.ngbActiveModal.close();
    }
  }

  isNew() {
    return !this.isBatchEdit() && !get(this.editor, 'product.id');
  }

  save(close = !this.editor.product.id) {
    this.isClosing = false;
    this.isSaving = true;

    if (this.isBatchEdit()) {
      this.saveBatch();
      return;
    }

    if (this.batchCreateCount > 1) {
      this.createBatch();
      return;
    }

    if (this.editor.product.id) {
      this.isClosing = close;
    }
    this.upsert().subscribe(() => {
      if (close) {
        this.ngbActiveModal.close();
      }
    });
  }
  ngOnInit() {
    this.photoDate=this.editor.product.created_at;
  }
  ngAfterViewInit(){
    if (this.editor.product.attribute_values.hasOwnProperty('status')){
      if (this.editor.product.attribute_values.status !== null){
        this.setStatus({approved:this.editor.product.attribute_values.status && this.editor.product.attribute_values.status === 'approved'?true:false });
      }
    }
  }
  selectImage(img) {
    if (img){
      this.photoDate=img.created_at;
    }
    this.selectedImage = img;
  }

  getSelectedImage() {
    if (this.selectedImage) {
      return this.selectedImage;
    }

    return get(this.product, '0.files[0]');
  }

  getSelectedImageUrl() {
    return get(this.getSelectedImage(), 'url', null);
  }

  getSelectedImagePhotographer() {
    const user = get(this.getSelectedImage(), 'user');
    if (!user) { return; }
    return `${user.first_name || ''} ${user.last_name || ''}`;
  }

  getSelectedImageLanguage() {
    const attrs = get(this.getSelectedImage(), 'attribure_values');
    if (!attrs) { return; }
    return attrs.language || "";
  }

  getProductTypeField() {
    return {
      type: 'string',
      input_element: 'select',
      title: 'Product type',
      settings: {
        options: this.productTypes,
      },
    };
  }

  getLanguages() {
    return {
      type: 'string',
      input_element: 'select',
      title: 'Language',
      settings: {
        options: ["Finnish", "English", "Swedish"],
      },
    };
  }
  clearStatus(data){
    if (data.double){
      document.getElementById('btnApprove').classList.remove('btn-success');
      document.getElementById('btnReject').classList.remove('btn-danger');
      this.editor.product.attribute_values.status=null;
    }
  }
  setStatus(data){
    if (data.approved){
      document.getElementById('btnReject').classList.remove('btn-danger');
      document.getElementById('btnApprove').classList.add('btn-success');

    }else{
      document.getElementById('btnApprove').classList.remove('btn-success');
      document.getElementById('btnReject').classList.add('btn-danger');
    }

    if (this.editor.product.attribute_values){
      this.editor.product.attribute_values.status= data.approved ? 'approved':'rejected';
    }
    console.log(this.editor.product.attribute_values.status);

  }
  getAttributeFields() {
    const productTypes = this.productTypes || [];
    const productType = flatten(productTypes
      .filter(this.productTypeFilter)
      .map(product => product.custom_attributes));

    let attributeFields = uniqBy(productType, 'slug').filter(this.isFieldEditAllowed);
    let newAttrFields=[];
    for (let key of Object.keys(attributeFields)){
      if (attributeFields[key].slug !== 'flaw'){
        newAttrFields.push(attributeFields[key]);
      }
    }
    //swap phone with email
    let phoneUIIndex = newAttrFields.findIndex(ui=>ui.slug === 'phone');
    let emailUIIndex = newAttrFields.findIndex(ui=>ui.slug === 'email');
    if (phoneUIIndex > emailUIIndex){
       let tempUiPhone=newAttrFields[phoneUIIndex];
       let tempUIEmail=newAttrFields[emailUIIndex];
       newAttrFields[emailUIIndex]=tempUiPhone;
       newAttrFields[phoneUIIndex]=tempUIEmail;
    }
    return newAttrFields;
  }

  openCropper() {
    const modalRef = this.ngbModal.open(PhotoPostProductionComponent, {
      windowClass: 'modal-xl modal-dark',
      backdrop : 'static',
    });

    const photo = this.getSelectedImage();
    modalRef.componentInstance.setInitialValues(photo, get(this.basicInfo, 'settings.cropper'));

    modalRef.componentInstance.onCancel.subscribe(() => modalRef.close());
    modalRef.componentInstance.onSave.subscribe((response) => {
      const index = this.editor.product.files.findIndex(({ id }) => id === photo.id);
      this.editor.product.files[index] = response.body;
      this.selectedImage = response.body;
      this.product[0].files = this.editor.product.files;

      modalRef.close();
    });

    return modalRef;
  }

  openShare() {
    const open = () => {
      this.reset();
      this.mode = 'share';
    };

    if (!this.productForm.dirty) {
      open();
      return;
    }

    if (window.confirm('There are unsaved changes, do you want to save now? If you select cancel, you will lose all unsaved changes.')) {
      this.upsert().subscribe(open);
      return;
    }

    open();
  }

  getDownloadUrl(image) {
    return image.download_url;
  }

  batchCreateCountChange() {
    if (this.batchCreateCount < 1) {
      this.batchCreateCount = 1;
    }
    if (this.batchCreateCount > this.basicInfo.productMaxBatchCreateLimit) {
      this.batchCreateCount = this.basicInfo.productMaxBatchCreateLimit;
    }

    if (this.batchCreateCount > 1 && !isObject(this.editor.product.sku)) {
      const generator = this.defaultSkuGenerator;
      const settings = this.getSkuGeneratorSettings(generator.id);
      this.editor.product.sku = {
        type: generator.id,
        len: get(settings, 'defaultLength', generator.defaultLength),
        prefix: get(settings, 'defaultPrefix', generator.defaultPrefix || ''),
      };
    }

    if (this.batchCreateCount === 1 && !isString(this.editor.product.sku)) {
      this.editor.product.sku = '';
    }
  }

  skuLengthChange() {
    const { minLength, maxLength } = this.selectedSkuGenerator;
    const length = this.editor.product.sku.len;
    this.editor.product.sku.len = Math.max(minLength, Math.min(maxLength, length));
  }

  @HostListener('window:keyup', ['$event'])
  keyPressed($event) {
    // do nothing if an input is focused or in batch edit
    if (
      ($event.path && $event.path.length && ['INPUT', 'TEXTAREA'].includes($event.path[0].tagName)) ||
      this.isBatchEdit()
    ) {
      return;
    }

    if (['ArrowRight', 'ArrowDown'].indexOf($event.key) !== -1 && this.editor.product.next) {
      this.product = this.editor.product.next;
      this.changeProduct();
    }
    if (['ArrowLeft', 'ArrowUp'].indexOf($event.key) !== -1 && this.editor.product.prev) {
      this.product = this.editor.product.prev;
      this.changeProduct();
    }
  }

  private reset() {
    this.changeProduct();
  }

  private productTypeFilter = ({ id }) => {
    if (!this.isBatchEdit() || this.editor.product.item_type_id) {
      return this.editor.product.item_type_id === id;
    }

    return !!this.product.find(({ item_type_id }) => item_type_id === id);
  }

  private isFieldEditAllowed = ({ is_batch_edit_enabled }) => {
    if (!this.isBatchEdit()) {
      return true;
    }

    return is_batch_edit_enabled;
  }

  private updateList(newProduct) {
    Object.assign(this.product[0], newProduct, {
      files: orderBy(get(this.product[0], 'files', []).map(file => ({
        ...file,
        product_order_number: newProduct.filesOrder.indexOf(file.id),
      })), 'product_order_number'),
    });
  }

  private upsert() {
    this.isSaving = true;
    return new Observable((observer) => {
      this.productService.upsert(this.editor.product)
        .pipe(finalize(() => { this.isSaving = false; }))
        .subscribe((response: any) => {
          const newProduct = response.body;
          const action = this.editor.product.id ? 'updated' : 'created';
          this.toasterService.pop('success', `Product ${action}!`, `The product has been successfully ${action}.`);

          // add the new product to the beginning of the list if this is a new product
          if (!this.editor.product.id && this.products) {
            this.products.unshift(newProduct);
            this.util.linkPrevNext(this.products);
          } else {
            // make changes visible on the front end listing
            this.updateList(newProduct);
          }

          this.productForm.form.markAsPristine();
          observer.next();
          observer.complete();
        });
      });
  }
  downloadBlob(content, filename, contentType) {
    // Create a blob
    let blob = new Blob([content], { type: contentType });
    let url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }
  private createBatch() {
    this.editor.product.sku.count = this.batchCreateCount;
    this.productService.batchCreate(this.editor.product)
      .pipe(finalize(() => { this.isSaving = false; }))
      .subscribe((response: any) => {
        const createdProducts = response.body.total;
        const csvData=response.body.csv.list.map(m=>{return {sku:m,timestamp:response.body.csv.timestamp}});
        let csvString='sku,timestamp\n';
        for (let line of csvData){
          csvString+=`${line.sku},${line.timestamp}\n`;
        }
        this.downloadBlob(csvString,`${new Date().toDateString()}_sku_list.csv`,'text/csv;charset=utf-8;');
        this.toasterService.pop('success', `Products created!`, `${createdProducts} products has been successfully created.`);
        this.ngbActiveModal.close({ reload: true });
      });
  }

  private saveBatch() {
    const editCount = this.getEditableProductCount();
    this.productService.batchEdit(this.editor.product, this.util.getSelectedIds(this.product))
      .pipe(finalize(() => { this.isSaving = false; }))
      .subscribe((response: any) => {
        const updatedProducts = response.body;
        this.toasterService.pop('success', `Products updated!`, `${editCount} products has been successfully updated.`);

        updatedProducts.forEach((product) => {
          const index = findIndex(this.product, { id: product.id });
          if (index > -1) {
            Object.assign(this.product[index], product);
          }
        });

        this.ngbActiveModal.close();
      });
  }
}
