/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-post-production.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../photo-cropper/photo-cropper.component.ngfactory";
import * as i3 from "../photo-cropper/photo-cropper.component";
import * as i4 from "angular2-logger/core";
import * as i5 from "../../core/util.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../rotation-slider/rotation-slider.component.ngfactory";
import * as i8 from "../rotation-slider/rotation-slider.component";
import * as i9 from "../zoom-button-component/zoom-button-component.ngfactory";
import * as i10 from "../zoom-button-component/zoom-button-component";
import * as i11 from "../aspect-ratio-selector/aspect-ratio-selector.component.ngfactory";
import * as i12 from "../aspect-ratio-selector/aspect-ratio-selector.component";
import * as i13 from "../overlay-selector/overlay-selector.component.ngfactory";
import * as i14 from "../overlay-selector/overlay-selector.component";
import * as i15 from "@ng-bootstrap/ng-bootstrap";
import * as i16 from "../../photo/photo.service";
import * as i17 from "@angular/common";
import * as i18 from "./photo-post-production.component";
var styles_PhotoPostProductionComponent = [i0.styles];
var RenderType_PhotoPostProductionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoPostProductionComponent, data: {} });
export { RenderType_PhotoPostProductionComponent as RenderType_PhotoPostProductionComponent };
export function View_PhotoPostProductionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cropperTool: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "cropper-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-photo-cropper", [["class", "cropper"]], null, [[null, "rotationChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rotationChange" === en)) {
        var pd_0 = ((_co.rotation = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PhotoCropperComponent_0, i2.RenderType_PhotoCropperComponent)), i1.ɵdid(3, 4374528, [[1, 4], ["cropperTool", 4]], 0, i3.PhotoCropperComponent, [i4.Logger, i5.UtilService, i1.ChangeDetectorRef, i6.DomSanitizer], { photo: [0, "photo"], overlay: [1, "overlay"], rotation: [2, "rotation"], zoom: [3, "zoom"], orientation: [4, "orientation"], aspectRatio: [5, "aspectRatio"] }, { rotationChange: "rotationChange" }), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "settings-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "settings-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-rotation-slider", [], null, [[null, "rotationChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rotationChange" === en)) {
        var pd_0 = ((_co.rotation = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_RotationSliderComponent_0, i7.RenderType_RotationSliderComponent)), i1.ɵdid(7, 114688, null, 0, i8.RotationSliderComponent, [i6.DomSanitizer], { rotation: [0, "rotation"] }, { rotationChange: "rotationChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-zoom-component", [], [[8, "hidden", 0]], [[null, "zoomChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("zoomChange" === en)) {
        var pd_0 = ((_co.zoom = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ZoomButtonComponent_0, i9.RenderType_ZoomButtonComponent)), i1.ɵdid(9, 114688, null, 0, i10.ZoomButtonComponent, [i6.DomSanitizer], { zoom: [0, "zoom"] }, { zoomChange: "zoomChange" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-aspect-ratio-selector", [], null, [[null, "aspectRatioChange"], [null, "orientationChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("aspectRatioChange" === en)) {
        var pd_0 = ((_co.aspectRatio = $event) !== false);
        ad = (pd_0 && ad);
    } if (("orientationChange" === en)) {
        var pd_1 = ((_co.orientation = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_AspectRatioSelectorComponent_0, i11.RenderType_AspectRatioSelectorComponent)), i1.ɵdid(11, 49152, null, 0, i12.AspectRatioSelectorComponent, [i4.Logger, i5.UtilService], { hasFreeAspectRatio: [0, "hasFreeAspectRatio"], hasFullAspectRatio: [1, "hasFullAspectRatio"], aspectRatio: [2, "aspectRatio"], orientation: [3, "orientation"] }, { aspectRatioChange: "aspectRatioChange", orientationChange: "orientationChange" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-overlay-selector", [], null, [[null, "overlayImageChange"], [null, "isOverlayVisibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("overlayImageChange" === en)) {
        var pd_0 = ((_co.overlayImage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isOverlayVisibleChange" === en)) {
        var pd_1 = ((_co.isOverlayVisible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_OverlaySelectorComponent_0, i13.RenderType_OverlaySelectorComponent)), i1.ɵdid(13, 114688, null, 0, i14.OverlaySelectorComponent, [i15.NgbModal, i16.PhotoService], { overlayImage: [0, "overlayImage"], isOverlayVisible: [1, "isOverlayVisible"] }, { overlayImageChange: "overlayImageChange", isOverlayVisibleChange: "isOverlayVisibleChange" }), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", "form-group settings-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "button", [["class", "btn btn-lg btn-success btn-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(19, 278528, null, 0, i17.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "fa-save": 0, "fa-spinner fa-pulse fa-fw": 1 }), (_l()(), i1.ɵted(-1, null, [" Save "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photo; var currVal_1 = ((_co.isOverlayVisible && _co.overlayImage) ? _co.overlayImage.url : undefined); var currVal_2 = _co.rotation; var currVal_3 = _co.zoom; var currVal_4 = _co.orientation; var currVal_5 = _co.aspectRatio; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.rotation; _ck(_v, 7, 0, currVal_6); var currVal_8 = _co.zoom; _ck(_v, 9, 0, currVal_8); var currVal_9 = true; var currVal_10 = false; var currVal_11 = _co.aspectRatio; var currVal_12 = _co.orientation; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.overlayImage; var currVal_14 = _co.isOverlayVisible; _ck(_v, 13, 0, currVal_13, currVal_14); var currVal_15 = "fa"; var currVal_16 = _ck(_v, 20, 0, !_co.isSaving, _co.isSaving); _ck(_v, 19, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_7 = true; _ck(_v, 8, 0, currVal_7); }); }
export function View_PhotoPostProductionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo-post-production", [], null, null, null, View_PhotoPostProductionComponent_0, RenderType_PhotoPostProductionComponent)), i1.ɵdid(1, 49152, null, 0, i18.PhotoPostProductionComponent, [i16.PhotoService, i5.UtilService], null, null)], null, null); }
var PhotoPostProductionComponentNgFactory = i1.ɵccf("app-photo-post-production", i18.PhotoPostProductionComponent, View_PhotoPostProductionComponent_Host_0, { photo: "photo", rotation: "rotation", zoom: "zoom", aspectRatio: "aspectRatio", orientation: "orientation", overlayImage: "overlayImage", isOverlayVisible: "isOverlayVisible" }, { onSave: "onSave", onCancel: "onCancel" }, []);
export { PhotoPostProductionComponentNgFactory as PhotoPostProductionComponentNgFactory };
