<div class="card">
  <div class="toolbar">
    <div class="pull-left">
      <h1 class="pull-left title">{{title}}</h1>

      <button  *ngIf="filters" class="btn btn-primary btn-sm btn-filters" (click)="isFiltersOpen = !isFiltersOpen">
        <i class="fa fa-filter"></i>
        Filters
        <i class="fa" [ngClass]="{ 'fa-angle-right': !isFiltersOpen, 'fa-angle-down': isFiltersOpen }"></i>
        <span class="badge badge-pill badge-success" *ngIf="filtersCount">{{ filtersCount }}</span>
      </button>
    </div>

    <div class="col col-buttons">
      <div class="form-group pull-right">
        <ng-content select="[header-buttons]"></ng-content>
      </div>
    </div>
    <div class="badge badge-info photos-to-process-badge"><span>Photos to be processed:{{totalPhotosForProcessing}}</span></div>
  </div>

  <div class="card filterContainer" *ngIf="filters && isFiltersOpen">
    <div class="filters row">
      <div *ngFor="let field of filters" class="col-md-4">
        <app-attribute-field
          [field]="field"
          [(ngModel)]="field.filter_value"
        ></app-attribute-field>
      </div>
    </div>
    <div class="row action-buttons">
      <div class="col">
        <button (click)="applyFiltersAction()" class="btn btn-primary btn-apply-filters" [disabled]="!hasFiltersChanged()">
          <i class="fa fa-check"></i>
          Apply filters
          <span class="badge badge-pill badge-success" *ngIf="hasFiltersChanged()">{{ getUnappliedFiltersCount() }}</span>
        </button>

        <button (click)="clearFiltersAction()" class="btn btn-primary" [disabled]="!filtersCount">
          <i class="fa fa-times"></i>
          Clear filters
        </button>

        <button *ngIf="saveAsCollection.observers.length" (click)="saveCollection()" class="btn btn-success pull-right">
          <i class="fa fa-save"></i>
          Save as a smart collection
        </button>
      </div>
    </div>
  </div>

  <ng-content select="[view-content]" *ngIf="!itemList"></ng-content>

  <app-item-list
    #itemListView
    *ngIf="itemList"
    [items]="items"
    [totalCount]="service.getCount()"
    [actions]="itemList.actions"
    [columns]="itemList.columns"
    [isSelectable]="itemList.isSelectable"
    (onAction)="itemList.onAction($event)"
    (onSelectItem)="itemList.onSelectItem($event)"
  ></app-item-list>

  <button class="btn btn-primary btn-wide"
    *ngIf="service.isLoading || items.length < service.getCount()"
    [disabled]="service.isLoading"
    (click)="fetchItems()">
      <i *ngIf="service.isLoading" class="fa fa-circle-o-notch fa-spin"></i> Load more
  </button>

</div>
