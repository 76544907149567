<ng-container *ngIf="mode === 'share'">
  <ng-container *ngTemplateOutlet="shareView"></ng-container>
</ng-container>
<ng-container *ngIf="mode !== 'share'">
  <div class="modal-header" *ngIf="isBatchEdit()">
     <h4 class="modal-title">Batch edit {{ getEditableProductCount() }} products</h4>
  </div>
  <div class="modal-body" [ngSwitch]="mode">
    <ng-template ngSwitchDefault><ng-container *ngTemplateOutlet="productEditorView"></ng-container></ng-template>
  </div>

  <div class="modal-footer" *ngIf="!isReadOnly && mode !== 'share'">
    <div class="container-fluid no-padding">
      <div class="form-group pull-left" *ngIf="!mode && !this.isBatchEdit() && editor.product.id">
        <button [hidden]="true" type="submit" class="btn btn-sm btn-secondary" (click)="openShare()" [disabled]="isSaving"><i class="fa fa-envelope"></i> Share</button>
      </div>
      <app-modal-save-buttons
        (save)="save($event)"
        (cancel)="cancel()"
        [isClosing]="isClosing"
        [isSaving]="isSaving"
        [enableSaveAndClose]="!this.isBatchEdit() && editor.product.id"
      ></app-modal-save-buttons>
    </div>
  </div>
</ng-container>

<ng-template #productEditorView>
  <form #productForm>
    <div class="row" [ngClass]="{'batch-editor': isBatchEdit()}">
      <div class="col photo-col" *ngIf="!isBatchEdit() && !isNew()">
        <div class="big-img-wrap">
          <div class="photo-edit-tools" *ngIf="getSelectedImageUrl() && !isReadOnly">
            <button class="btn btn-sm btn-primary" (click)="openCropper(); false" title="Crop photo"><i class="fa fa-crop"></i></button>
            <a class="btn btn-sm btn-primary" [href]="getDownloadUrl(getSelectedImage())" title="Download"><i class="fa fa-download"></i></a>
          </div>
          <app-fixed-ratio-img
            class="big-img"
            *ngIf="getSelectedImageUrl()"
            [src]="getSelectedImageUrl() | resizedImage"
            [ratio]="'4:3'"
            [isCropped]="false"
            [link]="getSelectedImageUrl()"
          ></app-fixed-ratio-img>

          <app-fixed-ratio-img
              *ngIf="!getSelectedImageUrl()"
              [src]="'assets/img/placeholder.svg'"
              [ratio]="'4:3'"
              [isCropped]="false"></app-fixed-ratio-img>
        </div>

        <div class="photographer" *ngIf="getSelectedImagePhotographer()">
          Photo taken by: {{ getSelectedImagePhotographer() }}
        </div>

        <ul
          class="thumb-strip"
          *ngIf="editor.product.files && editor.product.files.length"
          [sortablejs]="editor.product.files"
          [sortablejsOptions]="sortablejsOptions"
        >
          <li
            *ngFor="let image of editor.product.files"
            (click)="selectImage(image)"
            [ngClass]="{ 'selected': isSelectedImage(image.id)}"
          >
            <app-fixed-ratio-img *ngIf="editor.product.files.length" [src]="image.url | resizedImage"></app-fixed-ratio-img>
          </li>
        </ul>
      </div>

      <div class="col" *ngIf="!isBatchEdit()">
        <div class="form-group" *ngIf="isNew()">
          <label for="count-field">Number of new products (1 - {{basicInfo.productMaxBatchCreateLimit}})</label>
          <input
            class="form-control"
            name="count-field"
            type="number"
            min="1"
            [max]="basicInfo.productMaxBatchCreateLimit"
            [(ngModel)]="batchCreateCount"
            (change)="batchCreateCountChange($event)"
            (ngModelChange)="batchCreateCountChange($event)"
            [readonly]="isReadOnly" >
        </div>

        <div class="row" *ngIf="!isNew()">
          <div class="col">
            <div class="form-group">
              <label>Photo date & time</label>
              <p title="{{ photoDate | date: 'dd. MMM yyyy hh:mm:ss' }}">
                {{ photoDate | date: 'dd.MM.yyyy hh:mm:ss' }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="!isNew() || batchCreateCount === 1">
          <label for="sku-field">SKU</label>
          <input
            class="form-control"
            name="sku-field"
            placeholder="Enter the identifier of the product"
            [(ngModel)]="editor.product.sku"
            [readonly]="isReadOnly">
        </div>

        <div class="row" *ngIf="isNew() && batchCreateCount > 1">
          <div class="form-group col col-md-4">
            <label for="sku-type">SKU type</label>
            <select *ngIf="isNew() && batchCreateCount > 1" [(ngModel)]="editor.product.sku.type" name="sku-type">
              <option *ngFor="let option of skuGenerators" [ngValue]="option.id">{{ option.name }}</option>
            </select>
          </div>
          <div class="form-group col col-md-4">
            <label for="sku-length-field">
              SKU Prefix
            </label>
            <input
              class="form-control"
              name="sku-prefix-field"
              type="text"
              placeholder="Enter the prefix of sku"
              [(ngModel)]="editor.product.sku.prefix">
          </div>
          <div class="form-group col col-md-4">
            <label for="sku-length-field">
              SKU Length
              <span *ngIf="selectedSkuGenerator.maxLength !== selectedSkuGenerator.minLength">({{selectedSkuGenerator.minLength}} - {{selectedSkuGenerator.maxLength}})</span>
            </label>
            <input
              class="form-control"
              name="sku-length-field"
              type="number"
              placeholder="Enter the sku length of products"
              [min]="selectedSkuGenerator.minLength"
              [max]="selectedSkuGenerator.maxLength"
              [(ngModel)]="editor.product.sku.len"
              (change)="skuLengthChange($event)"
              (ngModelChange)="skuLengthChange($event)"
              [readonly]="isReadOnly || selectedSkuGenerator.maxLength === selectedSkuGenerator.minLength">
          </div>
        </div>

        <app-attribute-field
          [hidden]="true"
          name="item-type"
          [field]="getProductTypeField()"
          [(ngModel)]="editor.product.item_type_id"
          [isReadOnly]="isReadOnly"
        ></app-attribute-field>

        <div class="form-group" [hidden]="true">
          <label for="name-field">Name</label>
          <input
            class="form-control"
            name="name-field"
            placeholder="Enter the name of the product"
            [(ngModel)]="editor.product.attribute_values.name"
            [readonly]="isReadOnly" >
        </div>
        <div class="form-group" *ngIf="!isBatchEdit()">
          <label for="description-field">Description</label>
          <textarea
            class="form-control"
            name="description-field"
            placeholder="Description"
            [(ngModel)]="editor.product.attribute_values.description"
            [readonly]="isReadOnly"></textarea>
        </div>

        <div lass="form-group" *ngIf="!isBatchEdit()">
          <app-attribute-field
            name="language-field"
            [field]="getLanguages()"
            [(ngModel)]="productLanguage"
            [isReadOnly]="true"
        ></app-attribute-field>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="{'batch-editor': isBatchEdit()}">
      <div class="col-12" *ngIf="isBatchEdit()">
        <app-attribute-field
          name="item_type_id"
          [field]="getProductTypeField()"
          [(ngModel)]="editor.product.item_type_id"
          [isReadOnly]="isReadOnly"
        ></app-attribute-field>
      </div>

      <div class="col-6" *ngFor="let field of getAttributeFields()">
        <app-attribute-field *ngIf="field.slug !== 'status'"
          [field]="field"
          [name]="field.slug"
          [(ngModel)]="editor.product.attribute_values[field.slug]"
          [isReadOnly]="isReadOnly"
          [copyButton]=true
        ></app-attribute-field>
      </div>
      <div class="form-group status-btn-container">
        <p>Status</p>
        <button id="btnReject"  class="btn" (dblclick)="clearStatus({double:true,event:$event})" (click)="setStatus({approved:false,event:$event})">Rejected</button>
        <button id="btnApprove" style="margin-left: 6pt" class="btn" (dblclick)="clearStatus({double:true,event:$event})" (click)="setStatus({approved:true,event:$event})">Approved</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #shareView>
  <app-share
    [product]="this.editor.product"
    (onCancel)="cancel()"
  >
  </app-share>
</ng-template>
