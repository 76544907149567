import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fixed-ratio-img',
  templateUrl: './fixed-ratio-img.component.html',
  styleUrls: ['./fixed-ratio-img.component.scss'],
})
export class FixedRatioImgComponent implements OnInit {
  // default ratio is 1:1
  bottomPad = 100;
  @Input() src: string;
  @Input() ratio: string;
  @Input() isCropped = true;
  @Input() link = false;

  constructor() { }
  ngOnInit() {
    if (this.ratio) {
      this.parseRatio();
    }
  }

  parseRatio() {
    const parts = this.ratio.split(':');

    if (parts.length === 2 && parseInt(parts[0], 10) > 0) {
      this.bottomPad = Math.round(parseInt(parts[1], 10) / parseInt(parts[0], 10) * 100);
    }
  }

}
