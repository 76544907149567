import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Logger } from 'angular2-logger/core';
import { get, mapValues } from 'lodash';
import  Cropper from 'cropperjs';

import { UtilService } from '@app/core/util.service';
import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from '@app/remote-control/device';

interface Photo {
  url: string;
  original_url?: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-photo-cropper',
  templateUrl: './photo-cropper.component.html',
  styleUrls: ['./photo-cropper.component.scss'],
})
export class PhotoCropperComponent implements AfterViewInit, OnDestroy {
  @Input() photo: Photo;
  @Input() overlay: string;
  @ViewChild('photoElement') photoElement: ElementRef;
  @ViewChild('rotateElement') rotateElement: ElementRef;
  isEnabled = false;
  containerData;
  dragStartX = null;
  dragStartY = null;
  dragStartRotation = 0;
  // two way binding for rotation;
  _rotation = 0;
  _zoom=0;
  mouseUpListener;
  mouseMoveListener;
  mouseDownListener;
  initialZoom=0.4;
  setInitialZoom=false;
  @Output() rotationChange = new EventEmitter<number>();
  @Output() zoomChange = new EventEmitter<number>();
  private cropper: Cropper;
  private _orientation: string;
  private _aspectRatio: string;
  private shouldEnable = true;

  constructor(
    private logger: Logger,
    private util: UtilService,
    private cd: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
  ) { }

  @Input()
  set rotation(angle) {
    if (isNaN(angle)) {
      return;
    }
    this._rotation = Math.round(this.normalizeAngle(angle) * 10) / 10;
    this.rotationChange.emit(this._rotation);
    if (this.cropper) {
      this.cropper.rotateTo(this._rotation);
    }
  }
  get rotation() {
    return this._rotation;
  }
  @Input()
  set zoom(zoom) {
    if (isNaN(zoom)){
      return;
    }
    this._zoom=zoom;
    this.zoomChange.emit(this._zoom);
    if (this.cropper){
      this.cropper.zoom(this.zoom);
    }
  }
  get zoom(){
    return this._zoom;
  }
  ngAfterViewInit() {
    timer(0, 50).pipe(takeWhile(() => !this.photoElement))
      .subscribe(() => {
        this.logger.debug('Wait for photoElement for Cropper...', this.src);
      }, null, () => {
        if (this.shouldEnable) {
          this.enable();
        }
      });
    this.cd.detectChanges();

    this.mouseMoveListener = this.onDrag.bind(this);
    this.mouseUpListener = this.onDragEnd.bind(this);

    document.addEventListener('mousemove', this.mouseMoveListener);
    document.addEventListener('mouseup', this.mouseUpListener);
  }

  ngOnDestroy() {
    document.removeEventListener('mousemove', this.mouseMoveListener);
    document.removeEventListener('mouseup', this.mouseUpListener);
    this.destroy();
  }

  get src() {
    return this.photo.original_url || this.photo.url;
  }

  enable() {
    this.shouldEnable = true;
    if (!this.isEnabled) {
      this.initCropper();
    }
  }

  @Input()
  set orientation(orientation: string) {
    if (orientation && orientation.indexOf('PORTRAIT') > -1) {
      orientation = ORIENTATION_PORTRAIT;
    } else if (orientation) {
      orientation = ORIENTATION_LANDSCAPE;
    }

    this._orientation = orientation;
    this.logger.debug('set cropper orientation', this.orientation);
    this.updateCropperAspectRatio();
  }

  get orientation(): string {
    return this._orientation;
  }

  @Input()
  set aspectRatio(aspectRatio: string) {
    if (!aspectRatio) {
      return;
    }
    const newAspectRatio = aspectRatio.indexOf(':') > -1 ? aspectRatio : null;
    if (newAspectRatio === this.aspectRatio) {
      return;
    }
    this._aspectRatio = newAspectRatio;
    this.updateCropperAspectRatio();
  }

  get aspectRatio(): string {
    return this._aspectRatio;
  }

  get isLoading(): boolean {
    return this.shouldEnable && !this.isEnabled;
  }

  rotateOrientation() {
    this.orientation = this.orientation === ORIENTATION_PORTRAIT ? ORIENTATION_LANDSCAPE : ORIENTATION_PORTRAIT;
  }

  getData(rounded = false) {
    if (!this.cropper) {
      return null;
    }

    const data = this.cropper.getData(true);
    const { naturalWidth, naturalHeight } = this.cropper.getImageData();

    this.logger.debug('naturalSize', naturalWidth, naturalHeight);
    if (data.width === naturalWidth && data.height === naturalHeight && data.rotate === 0) {
      this.logger.debug('crop size not changed', data, naturalWidth, naturalHeight);
      return null;
    }
    this.logger.debug('cropper data', data);

    if (rounded) {
      // round everything except rotation
      const rotate = data.rotate;
      return {
        ...mapValues(data, value => Math.round(value)),
        rotate,
      };
    }

    return data;
  }

  destroy() {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }

    this.isEnabled = false;
    this.shouldEnable = false;
  }

  private updateCropperAspectRatio() {
    if (!this.cropper) {
      return;
    }

    if (!this.aspectRatio) {
      this.logger.debug('remove cropper aspect ratio');
      this.cropper.setAspectRatio(null);
      return;
    }

    // allow custom aspect ratios
    const numericAspect = this.util.getNumericAspect(this.aspectRatio);

    if (numericAspect && numericAspect > 0) {
      const data = this.cropper.getData();
      const { width } = this.getCrop() || data;
      const height = width / numericAspect;

      this.logger.debug('set cropper aspect ratio', this.aspectRatio, 'Orientation', this.orientation, numericAspect);
      this.cropper.setAspectRatio(numericAspect);

      // try to restore crop from before setting aspect ratio
      if (height !== data.height) {
        data.height = height;
        this.cropper.setData(data);
      }
    }
  }

  private initCropper() {
    this.logger.debug('Init cropper', this.photoElement.nativeElement);
    this.cropper = new Cropper(this.photoElement.nativeElement, {
      viewMode: 1,
      movable: false,
      zoomable: true,
      rotatable: true,
      scalable: false,
      autoCropArea: 1,
      responsive: true,
      restore: false,
      checkCrossOrigin: false,
      zoomOnWheel:false,
      /*preview:'.photo-preview',*/
      highlight: false,
      ready: () => {
        this.logger.debug('Cropper is ready');
        this.isEnabled = true;
        if (this.setInitialZoom){
          this.cropper.zoom(this.initialZoom);
        }
        const crop = this.getCrop();

        if (this.aspectRatio) {
          this.updateCropperAspectRatio();
        }
        if (crop) {
          this.cropper.setData(crop);
          this.logger.debug('set cropper data', crop);
        }
      },
      crop: (e) => {
        const cbd = this.cropper.getCropBoxData();

        this.rotateElement.nativeElement.style.left = Math.round(cbd.left - 44) + "px"
        this.rotateElement.nativeElement.style.top = Math.round(cbd.top - 44) + "px"

        this.logger.debug('crop.x', e.detail.x);
        this.logger.debug('crop.y', e.detail.y);
        this.logger.debug('crop.width', e.detail.width);
        this.logger.debug('crop.height', e.detail.height);
        this.logger.debug('crop.rotate', e.detail.rotate);
        this.logger.debug('crop.scaleX', e.detail.scaleX);
        this.logger.debug('crop.scaleY', e.detail.scaleY);
      },
    });
  }

  getCrop() {
    return get(this.photo, 'attribute_values.postProduction.crop', null) ||
      get(this.photo, 'attribute_values.crop', null);
  }

  getStyles() {
    if (!this.overlay) { return ''; }
    return this.domSanitizer.bypassSecurityTrustHtml(`
      <style>
        .cropper-crop-box .cropper-face {
          background: center / cover no-repeat url('/assets/img/help_lines_poliisi.png');
          opacity: 0.5;
        }
      </style>
    `);
  }

  onDragStart(event) {
    this.containerData = this.cropper.getContainerData();
    this.dragStartX = event.x;
    this.dragStartY = event.y;
    this.dragStartRotation = this.rotation;

    document.body.classList.add('cursor-rotate');
    setTimeout(() => event.target.classList.add('dragging'));
  }

  onDrag(event) {
    if (!this.dragStartX || !event.x || !event.y) {
      return undefined;
    }


    const deltaX = event.x - this.dragStartX;
    const deltaY = event.y - this.dragStartY;
    const halfWidth = this.containerData.width / 2;
    const halfHeight = this.containerData.height / 2;
    const cornerAngle = Math.atan2(halfHeight, -halfWidth);

    // calculate angle
    const angle = this.dragStartRotation + (cornerAngle + Math.atan2(deltaY - halfHeight, deltaX - halfWidth)) * 180 / Math.PI;
    this.rotation = angle;
  }

  onDragEnd(event) {
    this.dragStartX = null;
    this.dragStartY = null;
    document.body.classList.remove('cursor-rotate');
    const rotateHandle = document.getElementById('rotate-handle');
    if (rotateHandle) {
      rotateHandle.classList.remove('dragging');
    }
  }

  normalizeAngle(angle) {
    return angle >= 0 ? angle % 360 : (angle % 360) + 360;
  }

}
