<!-- <a href class="profile-button">
  <img gravatar [email]="'kingdam'.trim().toLowerCase()" [size]="97" class="img-avatar">
  <span >admin</span>
</a>-->

<toaster-container [toasterconfig]="basicInfoService.getToasterConfig()"></toaster-container>

<button class="btn btn-sm btn-secondary hamburger-button"
  (click)="toggleMenu()"
  [ngClass]="{ 'menu-open': basicInfoService.getIsMenuOpen(), 'force-mobile-menu': basicInfoService.getForceMobileMenu() }">
    <i [ngClass]="{ 'icon-menu': !basicInfoService.getIsMenuOpen(), 'fa fa-close': basicInfoService.getIsMenuOpen() }"></i>
</button>

<div class="app-body">
  <div class="sidebar" [ngClass]="{ 'open': basicInfoService.getIsMenuOpen(), 'force-mobile-menu': basicInfoService.getForceMobileMenu() }" (click)="toggleMenu()">
    <nav class="sidebar-nav">

    <a href="/" class="header-img"><img src="assets/img/logo.png" /></a>

      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/products']"><i class="fa fa-barcode"></i> Products</a>
        </li>
        <li class="nav-item" [hidden]="true">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/collections']"><i class="icon-folder"></i> Collections</a>
        </li>
        <li class="nav-item" [hidden]="true">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/photos']"><i class="icon-picture"></i> Photos</a>
        </li>
        <li class="nav-item" *ngIf="getBasicInfo('settings.hasRemoteControl')" [hidden]="true">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/remote-control']"><i class="icon-camera"></i> Remote control</a>
        </li>
        <li class="nav-item" *ngIf="getBasicInfo('settings.hasIntegrations')">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/integrations']"><i class="fa fa-exchange"></i> Export / Import</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/statistics']"><i class="fa fa-bar-chart"></i> Statistics</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/login']"><i class="icon-logout"></i> Log out</a>
        </li>
        <!--<li class="divider"></li>
        <li class="nav-title">
          UI Elements
        </li>
        <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
          <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="icon-puzzle"></i> Components</a>
          <ul class="nav-dropdown-items">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/buttons']"><i class="icon-puzzle"></i> Buttons</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/social-buttons']"><i class="icon-puzzle"></i> Social Buttons</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/cards']"><i class="icon-puzzle"></i> Cards</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/forms']"><i class="icon-puzzle"></i> Forms</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/modals']"><i class="icon-puzzle"></i> Modals</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/switches']"><i class="icon-puzzle"></i> Switches</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/tables']"><i class="icon-puzzle"></i> Tables</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/components/tabs']"><i class="icon-puzzle"></i> Tabs</a>
            </li>
          </ul>
        </li>
        <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
          <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="icon-star"></i> Icons</a>
          <ul class="nav-dropdown-items">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/icons/font-awesome']"><i class="icon-star"></i> Font Awesome</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/icons/simple-line-icons']"><i class="icon-star"></i> Simple Line Icons</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/widgets']"><i class="icon-calculator"></i> Widgets <span class="badge badge-info">NEW</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/charts']"><i class="icon-pie-chart"></i> Charts</a>
        </li>
        <li class="divider"></li>
        <li class="nav-title">
          Extras
        </li>
        <li class="nav-item nav-dropdown" appNavDropdown>
          <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="icon-star"></i> Pages</a>
          <ul class="nav-dropdown-items">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/pages/login']"><i class="icon-star"></i> Login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/pages/register']"><i class="icon-star"></i> Register</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/pages/404']"><i class="icon-star"></i> Error 404</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/pages/500']"><i class="icon-star"></i> Error 500</a>
            </li>
          </ul>
        </li>-->
      </ul>
    </nav>
  </div>

  <!-- Main content -->
  <main class="main">
  	<router-outlet></router-outlet>
  </main>

</div>
