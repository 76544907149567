/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./camera-preset-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../item-list/item-list.component.ngfactory";
import * as i5 from "../item-list/item-list.component";
import * as i6 from "angular2-toaster/src/toaster.service";
import * as i7 from "@angular/router";
import * as i8 from "../../core/util.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./camera-preset-manager.component";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
import * as i12 from "../../core/camera-preset.service";
var styles_CameraPresetManagerComponent = [i0.styles];
var RenderType_CameraPresetManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CameraPresetManagerComponent, data: {} });
export { RenderType_CameraPresetManagerComponent as RenderType_CameraPresetManagerComponent };
function View_CameraPresetManagerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update preset"])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update existing preset "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" or create new."])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save(_co.getSelectedPreset().id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Update preset "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSelectedPreset().name; _ck(_v, 8, 0, currVal_0); }); }
function View_CameraPresetManagerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Preset name already in use please choose another."]))], null, null); }
function View_CameraPresetManagerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-success"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Create new preset"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isNameAvailable(); _ck(_v, 0, 0, currVal_0); }); }
function View_CameraPresetManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraPresetManagerComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create new preset"])), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["for", "name-field"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name of the new preset"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "input", [["class", "form-control"], ["id", "name-field"], ["placeholder", "Enter the name of the preset"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.name = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(16, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraPresetManagerComponent_3)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraPresetManagerComponent_4)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canUpdate(); _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.name; _ck(_v, 14, 0, currVal_8); var currVal_9 = (!_co.isNameAvailable() && _co.name); _ck(_v, 18, 0, currVal_9); var currVal_10 = (_co.mode === "save"); _ck(_v, 21, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 16).ngClassValid; var currVal_6 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_CameraPresetManagerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Presets"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-item-list", [], null, [[null, "onSelectItem"], [null, "onAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectItem" === en)) {
        var pd_0 = (_co.selectPreset($event) !== false);
        ad = (pd_0 && ad);
    } if (("onAction" === en)) {
        var pd_1 = (_co.presetActionHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ItemListComponent_0, i4.RenderType_ItemListComponent)), i1.ɵprd(512, null, i2.DatePipe, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(7, 114688, null, 0, i5.ItemListComponent, [i6.ToasterService, i7.Router, i8.UtilService, i9.DomSanitizer, i2.DatePipe], { columns: [0, "columns"], maxSelect: [1, "maxSelect"], items: [2, "items"], isSelectable: [3, "isSelectable"], disableGrid: [4, "disableGrid"], actions: [5, "actions"] }, { onSelectItem: "onSelectItem", onAction: "onAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.presetColumns; var currVal_1 = 1; var currVal_2 = _co.presets; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.actions; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_CameraPresetManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraPresetManagerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraPresetManagerComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "form-group pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-sm btn-secondary"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode === "save"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.mode === "select"); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.mode === "save") ? "Cancel" : "Close"); _ck(_v, 8, 0, currVal_2); }); }
export function View_CameraPresetManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-camera-preset-manager", [], null, null, null, View_CameraPresetManagerComponent_0, RenderType_CameraPresetManagerComponent)), i1.ɵdid(1, 114688, null, 0, i10.CameraPresetManagerComponent, [i11.NgbActiveModal, i12.CameraPresetService, i6.ToasterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CameraPresetManagerComponentNgFactory = i1.ɵccf("app-camera-preset-manager", i10.CameraPresetManagerComponent, View_CameraPresetManagerComponent_Host_0, {}, {}, []);
export { CameraPresetManagerComponentNgFactory as CameraPresetManagerComponentNgFactory };
