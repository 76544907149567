import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeviewModule } from 'ngx-treeview';
import { DateTimePickerModule } from 'ng-pick-datetime';

// pipes
import { ResizedImagePipe } from '@app/shared/resized-image.pipe';

// components
import { AddToCollectionComponent } from '@app/shared/add-to-collection/add-to-collection.component';
import { AspectRatioSelectorComponent } from '@app/shared/aspect-ratio-selector/aspect-ratio-selector.component';
import { AttributeFieldComponent } from '@app/shared/attribute-field/attribute-field.component';
import { BatchActionsDropdownComponent } from '@app/shared/batch-actions-dropdown/batch-actions-dropdown.component';
import { CameraPresetManagerComponent } from '@app/shared/camera-preset-manager/camera-preset-manager.component';
import { FixedRatioImgComponent } from '@app/shared/fixed-ratio-img/fixed-ratio-img.component';
import { ItemListComponent } from '@app/shared/item-list/item-list.component';
import { ModalSaveButtonsComponent } from '@app/shared/modal-save-buttons/modal-save-buttons.component';
import { OverlaySelectorComponent } from '@app/shared/overlay-selector/overlay-selector.component';
import { PaginationListViewComponent } from '@app/shared/pagination-list-view/pagination-list-view.component';
import { PhotoCropperComponent } from '@app/shared/photo-cropper/photo-cropper.component';
import { PhotoPostProductionComponent } from '@app/shared/photo-post-production/photo-post-production.component';
import { RotationSliderComponent } from '@app/shared/rotation-slider/rotation-slider.component';
import { ShareComponent } from '@app/shared/share/share.component';
import { SliderComponent, FormatSliderValuePipe } from '@app/shared/slider/slider.component';
import { RichEditorComponent } from '@app/shared/rich-editor/rich-editor.component'
import {ZoomButtonComponent} from "@app/shared/zoom-button-component/zoom-button-component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    TreeviewModule,
    DateTimePickerModule,
  ],
  declarations: [
    ResizedImagePipe,
    FormatSliderValuePipe,
    AddToCollectionComponent,
    AspectRatioSelectorComponent,
    AttributeFieldComponent,
    BatchActionsDropdownComponent,
    CameraPresetManagerComponent,
    FixedRatioImgComponent,
    ItemListComponent,
    ModalSaveButtonsComponent,
    OverlaySelectorComponent,
    PaginationListViewComponent,
    PhotoCropperComponent,
    PhotoPostProductionComponent,
    RotationSliderComponent,
    ZoomButtonComponent,
    ShareComponent,
    SliderComponent,
    RichEditorComponent,
  ],
  providers: [
    ResizedImagePipe,
    FormatSliderValuePipe,
  ],
  exports: [
    ResizedImagePipe,
    FormatSliderValuePipe,
    AddToCollectionComponent,
    AspectRatioSelectorComponent,
    AttributeFieldComponent,
    BatchActionsDropdownComponent,
    CameraPresetManagerComponent,
    FixedRatioImgComponent,
    ItemListComponent,
    ModalSaveButtonsComponent,
    OverlaySelectorComponent,
    PaginationListViewComponent,
    PhotoCropperComponent,
    PhotoPostProductionComponent,
    RotationSliderComponent,
    ShareComponent,
    SliderComponent,
  ]
})
export class SharedModule {}
