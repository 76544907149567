import { Component, OnInit, Input, HostListener, OnChanges } from '@angular/core';
import { cloneDeep, flatten, get, set, uniqBy, defaults } from 'lodash';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, merge, catchError, finalize } from 'rxjs/operators';

import { ProductService } from '@app/product/product.service';
import { PhotoService } from './photo.service';
import { ItemTypeService } from '@app/core/item-type.service';
import { UtilService } from '@app/core/util.service';
import { PhotoPostProductionComponent } from '@app/shared/photo-post-production/photo-post-production.component';

@Component({
  selector: 'app-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss'],
})
export class PhotoEditorComponent implements OnInit, OnChanges {
  @Input() isReadOnly;
  @Input() basicInfo;
  @Input() photo: any = {};
  @Input() photos: any;
  @Input() reversedShortcutArrows = false;

  isSaving = false;
  isClosing = false;
  photoTypes;
  searchResult;
  searchTerm;
  onDeleteSuccess: Function;
  editor;
  searching = false;
  searchFailed = false;
  hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((term) => {
        this.searchTerm = term;
        if (term.length === 0) {
          this.searchResult = null;
          return of([]);
        }
        return this.productService.search(term)
          .pipe(
            tap((result) => {
              this.searchResult = result;
              this.searchFailed = false;
            }),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          );
      }),
      tap(() => this.searching = false),
      merge(this.hideSearchingWhenUnsubscribed)
    );

  constructor(
    private productService: ProductService,
    private photoService: PhotoService,
    private ngbActiveModal: NgbActiveModal,
    private toasterService: ToasterService,
    private itemTypeService: ItemTypeService,
    private ngbModal: NgbModal,
    private util: UtilService,
  ) { }

  ngOnInit() {
    if (!this.isReadOnly) {
      this.itemTypeService.load().subscribe(itemTypes => this.photoTypes = itemTypes);
    }
    this.changePhoto();
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  save(close = false) {
    this.isClosing = close;
    this.isSaving = true;
    // save changes to the DB
    this.photoService.update(this.editor.photo)
      .pipe(finalize(() => { this.isSaving = false; }))
      .subscribe((response: any) => {
        const photo = response.body;
        this.toasterService.pop('success', `Photo updated!`, `The photo has been successfully updated.`);

        // make changes visible on the front end listing
        Object.assign(this.photo, {
          ...photo,
          product: photo.product || null,
        });

        if (close) {
          this.ngbActiveModal.close();
        }
      });
  }

  canCreateNewProduct(): boolean {
    if (this.searchResult && this.searchResult.find(({ id, name, sku }) => [id, name, sku].indexOf(this.searchTerm) > -1)) {
      return false;
    }

    return !!this.searchTerm;
  }

  createNewProduct() {
    if (!this.canCreateNewProduct()) {
      return;
    }

    this.editor.photo.product = { sku: this.searchTerm };
  }

  productName(product) {
    return get(product, 'attribute_values.name');
  }

  productFormatter = (prod) => {
    return !prod.id ? null : `${this.productName(prod) || ''} (${prod.sku ? 'SKU' : 'ID'}: ${prod.sku || prod.id})`;
  }

  unlinkProduct() {
    this.editor.photo.product = null;
    this.editor.photo.product_id = null;
  }

  ngOnChanges(changes) {
    if (changes.photo) {
      this.changePhoto();
    }
  }

  changePhoto() {
    const photo = defaults(cloneDeep(this.photo), { item_type_id: null });

    this.editor = { photo };
    if (!this.editor.photo.attribute_values) {
      this.editor.photo.attribute_values = {};
    }
  }

  getPhotoTypeField() {
    return {
      type: 'string',
      input_element: 'select',
      title: 'Photo type',
      settings: {
        options: this.photoTypes,
      },
    };
  }

  private photoTypeFilter = ({ id }) => this.editor.photo.item_type_id === id;

  getAttributeFields() {
    const photoTypes = this.photoTypes || [];
    const photoType = flatten(photoTypes
      .filter(this.photoTypeFilter)
      .map(photo => photo.custom_attributes));

    const attributeFields = uniqBy(photoType, 'slug');
    return attributeFields;
  }

  async deletePhoto(id) {
    try {
      await this.photoService.confirmAndDelete(this.editor.photo.id);
      this.ngbActiveModal.close();
      if (this.onDeleteSuccess) {
        this.onDeleteSuccess(this.editor.photo.id);
      }
    } catch (error) {

    }
  }

  getDownloadUrl() {
    return this.util.getDownloadUrl(this.photo, this.basicInfo);
  }

  openCropper() {
    const modalRef = this.ngbModal.open(PhotoPostProductionComponent, {
      windowClass: 'modal-xl modal-dark',
      backdrop : 'static',
    });

    modalRef.componentInstance.setInitialValues(this.photo, get(this.basicInfo, 'settings.cropper'));

    modalRef.componentInstance.onCancel.subscribe(() => modalRef.close());
    modalRef.componentInstance.onSave.subscribe((response) => {
      if (response.body && response.body.url) {
        // update url, postProduction and crop (depracated) values of photo and editor.photo
        [this.photo, this.editor.photo].forEach(photo => {
          photo.url = response.body.url;
          set(photo, 'attribute_values.postProduction', get(response.body, 'attribute_values.postProduction'));
          set(photo, 'attribute_values.crop', get(response.body, 'attribute_values.crop'));
        });
      }
      modalRef.close();
    });

    return modalRef;
  }

  @HostListener('window:keyup', ['$event'])
  keyPressed($event) {
    // do nothing if an input is focused
    if ($event.path && $event.path.length && ['INPUT', 'TEXTAREA'].includes($event.path[0].tagName)) {
      return;
    }

    const next = this.reversedShortcutArrows ? this.photo.prev : this.photo.next;
    const prev = this.reversedShortcutArrows ? this.photo.next : this.photo.prev;

    if (['ArrowRight', 'ArrowDown'].indexOf($event.key) !== -1 && next) {
      this.photo = next;
      this.changePhoto();
    }
    if (['ArrowLeft', 'ArrowUp'].indexOf($event.key) !== -1 && prev) {
      this.photo = prev;
      this.changePhoto();
    }
  }

}
