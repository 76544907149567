<div class="cropper-col">
  <app-photo-cropper
    #cropperTool
    class="cropper"
    [photo]="photo"
    [overlay]="isOverlayVisible && overlayImage ? overlayImage.url : undefined"
    [aspectRatio]="aspectRatio"
    [orientation]="orientation"
    [(rotation)]="rotation"
    [zoom]="zoom"
  ></app-photo-cropper>
</div>
<div class="settings-col">
  <div class="settings-top">
    <app-rotation-slider [(rotation)]="rotation"></app-rotation-slider>
    <app-zoom-component [hidden]="true" [(zoom)]="zoom"></app-zoom-component>
    <app-aspect-ratio-selector
      [hasFreeAspectRatio]="true"
      [hasFullAspectRatio]="false"
      [(aspectRatio)]="aspectRatio"
      [(orientation)]="orientation"></app-aspect-ratio-selector>

    <app-overlay-selector
      [(overlayImage)]="overlayImage"
      [(isOverlayVisible)]="isOverlayVisible"></app-overlay-selector>
  </div>
  <div class="form-group settings-bottom">
    <button class="btn btn-action" (click)="cancel()">Cancel</button>
    <button class="btn btn-lg btn-success btn-action" (click)="save()">
      <i class="fa" [ngClass]="{ 'fa-save': !isSaving, 'fa-spinner fa-pulse fa-fw': isSaving }"></i>
      Save
    </button>
  </div>
</div>
