import { Injectable } from '@angular/core';
import { findIndex, omit } from 'lodash';
import { map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { UtilService } from '@app/core/util.service';

@Injectable()
export class BaseApiService {
  fetchedRows = [];
  currentPage = 1;
  isLoading = false;
  totalCount = 0;
  filters;
  modelEndpoint;

  constructor(
    public authService: AuthService,
    public util: UtilService,
  ) { }

  getFilters = (idList = null): Array<any> => {
    if (idList) {
      return [{ field: 'id', value: idList }];
    }

    return this.filters || [];
  }

  getFiltersString = (idList = null): string => {
    return JSON.stringify(this.getFilters(idList));
  }

  getPage(page: number = 1) {
    let url = `${this.modelEndpoint}/all?page=${page}`;

    if (this.filters && this.filters.length) {
      url += `&filters=${this.getFiltersString()}`;
    }

    return this.authService.get(url);
  }

  getNextPage(reset: boolean = false, filters = null) {
    if (reset) {
      this.fetchedRows = [];
      this.totalCount = 0;
      this.currentPage = 1;
      this.filters = filters;
    }

    this.isLoading = true;
    return this.getPage(this.currentPage)
      .pipe(map((response: any) => {
        this.fetchedRows.push(...response.body);
        this.totalCount = response.headers.get('X-Total-Count');
        this.currentPage++;
        this.isLoading = false;
        this.util.linkPrevNext(this.fetchedRows);

        return this.fetchedRows;
      }));
  }

  create(item) {
    return this.authService.post(this.modelEndpoint, item);
  }

  update(id, item) {
    return this.authService.put(`${this.modelEndpoint}/${id}`, item);
  }

  upsert(item) {
    const id = item.id;
    const data = omit(item, 'id');
    if (id) {
      return this.update(item.id, data);
    }

    return this.create(data);
  }

  getCount() {
    return this.totalCount;
  }

  getById(id, config?: any) {
    return this.authService.get(`${this.modelEndpoint}/${id}`, config);
  }

  deleteLocalRows(idList) {
    idList.map((id) => {
      // avoid changing the array reference
      this.fetchedRows.splice(findIndex(this.fetchedRows, row => row.id === id), 1);
    });
    if (this.totalCount) {
      this.totalCount -= idList.length;
    }
    this.util.linkPrevNext(this.fetchedRows);
  }

  delete(id: number, suffix: string = null) {
    let url = `${this.modelEndpoint}/${id}`;
    if (suffix) {
      url += `/${suffix}`;
    }
    return this.authService.delete(url)
      .pipe(map(() => {
        this.deleteLocalRows([id]);
        return this.fetchedRows;
      }));
  }

  batchDelete(filters, extraData = {}) {
    return this.authService.post(`${this.modelEndpoint}/batch-delete`, { ...extraData, filters })
      .pipe(map((res: any) => {
        this.deleteLocalRows(res.body);
        return this.fetchedRows;
      }));
  }
}
