<h1>
  <i class="fa fa-info-circle"></i>
  {{title}}
</h1>
<ul>
  <li>Make sure your <b>device is online</b> and <b>Kingdam app</b> is up and running</li>
  <li *ngIf="!isForceRemoteControllerEnabled()"><b>Activate remote controller</b> on the <b>Kingdam app</b> from the menu <i class="fa fa-sliders"></i></li>
  <li>Make sure you are <b>logged in</b> with your credentials ({{basicInfo.user.email}}) on <b>Kingdam app</b></li>
  <li>Make sure your device is on the <b>same</b> wifi <b>network</b> with this computer</li>
  <li>Try refreshing the device status by pressing the <i class="fa fa-refresh"></i> button</li>
  <li>If you are still unable to connect, try reloading this page, restarting the <b>Kingdam app</b> or restarting the phone</li>
</ul>
