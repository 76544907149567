import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { ClipboardModule } from 'ngx-clipboard';
import { TreeviewModule } from 'ngx-treeview';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import * as $ from 'jquery';
// window['jQuery'] = window['$'] = $;
import { DateTimePickerModule } from 'ng-pick-datetime';
import { SortablejsModule } from 'angular-sortablejs';

import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { ProductService, ProductResolve } from '@app/product/product.service';
import { CollectionService } from '@app/collection/collection.service';
import { CameraPresetService } from '@app/core/camera-preset.service';
import { RemoteControlService, RemoteControlConnectionResolve } from '@app/remote-control/remote-control.service';
import { PhotoService } from '@app/photo/photo.service';
import { ProductComponent } from './product/product.component';
import { LoginComponent } from './login/login.component';
import { ProductEditorComponent } from '@app/product/product-editor.component';
import { RemoteControlComponent, RemoteControlMainComponent } from './remote-control/remote-control.component';
import { RemoteControlDeviceComponent } from '@app/remote-control/remote-control-device.component';
import { RemoteControlDeviceListComponent } from '@app/remote-control/remote-control-device-list.component';
import { RemoteVideoComponent } from '@app/remote-control/remote-video.component';
import { RemoteControlHelperComponent } from '@app/remote-control/remote-control-helper.component';
import { PhotoComponent } from './photo/photo.component';
import { PhotoEditorComponent } from '@app/photo/photo-editor.component';
import { CollectionComponent } from '@app/collection/collection.component';
import { CollectionEditorComponent } from '@app/collection/collection-editor.component';
import { PublicCollectionComponent } from './public-collection/public-collection.component';
import { ShopDemoComponent } from './shop-demo/shop-demo.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { AddToCollectionComponent } from '@app/shared/add-to-collection/add-to-collection.component';
import { ItemListComponent } from '@app/shared/item-list/item-list.component';
import { CameraPresetManagerComponent } from '@app/shared/camera-preset-manager/camera-preset-manager.component';
import { PhotoPostProductionComponent } from '@app/shared/photo-post-production/photo-post-production.component';
import { IntegrationComponent } from '@app/integration/integration.component';
import { IntegrationJobEditorComponent } from '@app/integration/integration-job-editor.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    LoginComponent,
    ProductEditorComponent,
    RemoteControlComponent,
    RemoteControlMainComponent,
    RemoteControlDeviceComponent,
    RemoteControlDeviceListComponent,
    RemoteVideoComponent,
    PhotoComponent,
    PhotoEditorComponent,
    CollectionComponent,
    CollectionEditorComponent,
    PublicCollectionComponent,
    RemoteControlHelperComponent,
    ShopDemoComponent,
    StatisticsComponent,
    IntegrationComponent,
    IntegrationJobEditorComponent,
  ],
    imports: [
        routing,
        NgbModule.forRoot(),
        CookieModule.forRoot(),
        ClipboardModule,
        TreeviewModule.forRoot(),
        DateTimePickerModule,
        CoreModule,
        SharedModule,
        SortablejsModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
  providers: [
    DatePipe,
    ProductService,
    ProductResolve,
    CollectionService,
    PhotoService,
    RemoteControlService,
    RemoteControlConnectionResolve,
    CameraPresetService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProductEditorComponent,
    PhotoEditorComponent,
    CollectionEditorComponent,
    AddToCollectionComponent,
    ItemListComponent,
    CameraPresetManagerComponent,
    PhotoPostProductionComponent,
  ],
})

export class AppModule {
  constructor() {
    SortablejsModule.forRoot({animation:150});
  }
}
