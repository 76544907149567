<div class="form-group aspect-settings">
  <label for="aspect-ratio-setting">Aspect ratio</label>

  <div class="btn-group aspect-ratio" role="group">
    <div ngbDropdown class="d-inline-block">
      <button
        class="btn btn-sm btn-primary"
        ngbDropdownToggle
      >
        <div class="content">
          <i class="material-icons">aspect_ratio</i>
          <span *ngIf="!isCustomInputActive">
            {{ getName(aspectRatio) }}
          </span>
          <div *ngIf="isCustomInputActive" class="custom">
            <input type="number"
              class="custom-aspect-ratio-textbox"
              [(ngModel)]="width"
              (change)="customAspectRatioChanged($event)">
            :
            <input type="number"
              class="custom-aspect-ratio-textbox"
              [(ngModel)]="height"
              (change)="customAspectRatioChanged($event)">
          </div>
        </div>
      </button>
      <div ngbDropdownMenu aria-labelledby="aspectdropdown">
        <button *ngFor="let aspect of getAspectRatios()" class="dropdown-item" (click)="selectAspectRatio(aspect)" [ngClass]="{ selected: isSelectedAspectRatio(aspect) }">
          {{ getName(aspect) }}
        </button>
      </div>
    </div>
  </div>

  <button
    class="btn btn-sm narrow pull-right btn-rotate"
    title="Change orientation"
    (click)="turnOrientation()"
    [disabled]="!aspectRatio || aspectRatio === 'Free'"
  >
    <div class="orientation" [ngClass]="orientation">
      <i class="material-icons">phone_iphone</i>
    </div>
  </button>
</div>
