<div class="modal-body">
  <div class="row">
    <div class="col img-col">
      <div class="photo-edit-tools" *ngIf="!isReadOnly">
        <button class="btn btn-sm btn-primary" (click)="openCropper(); false" title="Crop photo"><i class="fa fa-crop"></i></button>
        <a class="btn btn-sm btn-primary" [href]="getDownloadUrl()" title="Download"><i class="fa fa-download"></i></a>
      </div>

      <app-fixed-ratio-img class="big-img" *ngIf="photo.url"
        [src]="photo.url | resizedImage"
        [ratio]="'3:2'"
        [isCropped]="false"
        [link]="photo.url"
      ></app-fixed-ratio-img>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <div class="form-group">
        <label for="name-field">Name</label>
        <input class="form-control" id="name-field"
          placeholder="Enter the name of the photo"
          [(ngModel)]="editor.photo.name"
          [readonly]="isReadOnly">
      </div>

      <div class="form-group">
        <label for="ngb-typehead-products">Product</label>
        <div class="input-group" *ngIf="!editor.photo.product">
          <input
            id="ngb-typehead-products"
            type="text"
            class="form-control"
            placeholder="Type a product name or SKU"
            [(ngModel)]="editor.photo.product"
            [ngbTypeahead]="search"
            [editable]="false"
            [resultFormatter]="productFormatter"
            [inputFormatter]="productFormatter"
            [readonly]="isReadOnly"
          />
          <div class="input-group-append">
            <button class="btn btn-secondary" (click)="createNewProduct()" [disabled]="!canCreateNewProduct()"><i class="fa fa-plus"></i> Create new product</button>
          </div>
        </div>
        <ng-container *ngIf="editor.photo.product">
          <div class="input-group">
            <input
              type="text"
              id="ngb-typehead-products"
              class="form-control"
              disabled
              readonly
              value="SKU: {{editor.photo.product.sku}}"
            />
            <div class="input-group-append">
              <button class="btn btn-secondary" (click)="unlinkProduct()">Unlink <i class="fa fa-times-circle"></i></button>
            </div>
          </div>
          <span class="badge badge-primary">ID: {{editor.photo.product.id || 'New product'}}</span>
          <span class="badge badge-primary" *ngIf="productName(editor.photo.product)">Name: {{productName(editor.photo.product)}}</span>
        </ng-container>
      </div>

      <app-attribute-field
        [field]="getPhotoTypeField()"
        [(ngModel)]="editor.photo.item_type_id"
        [isReadOnly]="isReadOnly"
      ></app-attribute-field>

    </div>
  </div>
  <div class="row">
    <div class="col-6" *ngFor="let field of getAttributeFields()">
      <app-attribute-field
        [field]="field"
        [(ngModel)]="editor.photo.attribute_values[field.slug]"
        [isReadOnly]="isReadOnly"
      ></app-attribute-field>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!isReadOnly">
  <div class="container-fluid no-padding">
    <div class="form-group pull-left">
      <button type="submit" class="btn btn-sm btn-danger" (click)="deletePhoto()"><i class="fa fa-trash"></i> Delete</button>
    </div>

    <app-modal-save-buttons
      (save)="save($event)"
      (cancel)="cancel()"
      [isClosing]="isClosing"
      [isSaving]="isSaving"
    ></app-modal-save-buttons>
  </div>
</div>
