import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { UtilService } from '@app/core/util.service';
import { Slider } from './slider';

@Pipe({ name: 'formatSliderValue' })
export class FormatSliderValuePipe implements PipeTransform {
  transform(val, format) {
    return format(val);
  }
}

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
  @Input() slider: Slider;
  @ViewChild('numberInput') numberInput: ElementRef;

  private hasNumberInputFocus = false;
  private updateTimer;

  constructor(
    private util: UtilService,
  ) { }

  ngAfterViewInit() {
    this.slider.focus = () => {
      this.numberInput.nativeElement.focus();
    };
  }

  onInputKeyPress({ keyCode }) {
    if (keyCode === 27 || keyCode === 13) {
      (<HTMLElement>document.activeElement).blur();
    }
  }

  onInputFocus($event) {
    this.hasNumberInputFocus = true;
    this.util.selectAllContent($event);
  }

  onInputBlur($event) {
    clearTimeout(this.updateTimer);
    this.hasNumberInputFocus = false;
    this.slider.value = Number(this.slider.unFormatNumber($event.target.value));
  }

  onModelChange($event) {
    if (!this.hasNumberInputFocus) {
      this.slider.value = Number(this.slider.format($event));
    } else {
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(() => this.updateValue(this.numberInput.nativeElement.value), 1000);
    }
  }

  showAdvanced($event) {
    $event.preventDefault();
    this.slider.onShowAdvanced();
    return false;
  }

  hideAdvanced($event) {
    $event.preventDefault();
    this.slider.onHideAdvanced();
    return false;
  }

  private updateValue(value) {
    this.slider.value = Number(this.slider.unFormatNumber(value));
  }
}
