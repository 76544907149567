import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-batch-actions-dropdown',
  templateUrl: './batch-actions-dropdown.component.html',
  styleUrls: ['./batch-actions-dropdown.component.scss'],
})
export class BatchActionsDropdownComponent implements OnInit {
  @Input() selectedCount = 0;
  @Input() actions = [];

  constructor() { }

  ngOnInit() {
  }

  btnClass({ count, disabled }) {
    const classes = [this.isActionDisabled(disabled, count) ? 'disabled' : 'enabled'];

    return classes;
  }

  actionIconClass({ icon, btnType, count, disabled }) {
    if (!icon) {
      return null;
    }

    const classes = [`fa-${icon}`];

    if (btnType && !this.isActionDisabled(disabled, count)) {
      classes.push(`text-${btnType}`);
    }

    return classes;
  }

  actionClick($event, { action, href, count, disabled }) {
    if (this.isActionDisabled(disabled, count) || !href) {
      $event.preventDefault();
    }

    if (this.isActionDisabled(disabled, count)) {
      return;
    }

    action();
  }

  private isActionDisabled(disabled, count) {
    return disabled || this.isActionAllowedBySelectedCount(count);
  }

  private isActionAllowedBySelectedCount(count) {
    return this.selectedCount < count;
  }
}
