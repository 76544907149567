import Device from './device';

export default class Devices {
    private map;

    constructor() {
      this.reset();
      this[Symbol.iterator] = this.values;
    }

    add(device: Device) {
      return this.map.set(device.id, device);
    }

    set(id: string, device: Device) {
      return this.map.set(id, device);
    }

    get(id: string): Device {
      return this.map.get(id);
    }

    has(id: string): boolean {
      return this.map.has(id);
    }

    delete(id: string) {
      if (this.has(id)) {
        this.get(id).destroy();
        this.map.delete(id);
      }
    }

    clear() {
      this.map.forEach(device => device.destroy());
      this.reset();
    }

    values() {
      return this.map.values();
    }

    private reset() {
      this.map = new Map<string, Device>();
    }
}
