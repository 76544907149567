<div *ngIf="collection" class="toolbar">
  <div class="wrapper">
    <img class="pull-left logo" src="assets/img/logo-black.svg">
    <h1 class="pull-left title">{{ collection.name }}</h1>

    <div class="col-buttons">
      <div class="form-group pull-right">

        <a class="btn btn-sm btn-primary btn-export" target="_blank" ng-reflect-klass="btn btn-sm btn-primary btn-exp" href="/api/collection/public/{{ collection.key }}/export">
          <i class="fa fa-download"></i>
          <span class="mobile-hide">Download</span>
        </a>

      </div>
    </div>
  </div>
</div>

<div *ngIf="collection" class="wrapper row">
  <div class="col">
    <p>{{ collection.description }}</p>
    <div class="row">
      <div class="col">
        <h2>{{title}}</h2>
        <app-item-list
          [items]="items"
          [columns]="getColumns()"
          [actions]="getActions()"
          (onSelectItem)="openItem($event)">
        </app-item-list>
      </div>
    </div>
    <div class="row load-more" *ngIf="isLoading || collection.items.pageCount > collection.items.page">
      <button class="btn btn-primary btn-wide"
        [disabled]="isLoading"
        (click)="loadMore()">
          <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i> Load more
      </button>
    </div>
  </div>
</div>

<ng-template #downloadProductTemplate let-item let-templateVars="templateVars">
  <a class="btn btn-sm btn-secondary btn-fade"
    [attr.href]="'/api/collection/public/' + templateVars.collectionKey + '/export/' + item.id"
    target="_blank"
    title="Download"
    (click)="$event.stopPropagation()"
    download
    ><i class="fa fa-download"></i></a>
</ng-template>

<ng-template #downloadPhotoTemplate let-item let-templateVars="templateVars">
  <a class="btn btn-sm btn-secondary btn-fade"
    [attr.href]="item.url"
    target="_blank"
    title="Download"
    download
    (click)="$event.stopPropagation()"
    ><i class="fa fa-download"></i></a>
</ng-template>
