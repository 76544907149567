/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../shared/pagination-list-view/pagination-list-view.component.ngfactory";
import * as i5 from "../shared/pagination-list-view/pagination-list-view.component";
import * as i6 from "../shared/batch-actions-dropdown/batch-actions-dropdown.component.ngfactory";
import * as i7 from "../shared/batch-actions-dropdown/batch-actions-dropdown.component";
import * as i8 from "./product.component";
import * as i9 from "@angular/router";
import * as i10 from "./product.service";
import * as i11 from "../collection/collection.service";
import * as i12 from "../core/item-type.service";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
import * as i14 from "ngx-cookie/src/cookie.service";
import * as i15 from "angular2-toaster/src/toaster.service";
import * as i16 from "../core/util.service";
var styles_ProductComponent = [i0.styles];
var RenderType_ProductComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductComponent, data: {} });
export { RenderType_ProductComponent as RenderType_ProductComponent };
function View_ProductComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.deleteProductPhotos = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(7, null, [" Delete also photos (", ") "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.deleteProductPhotos; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.deletingProduct.files.length; _ck(_v, 7, 0, currVal_8); }); }
function View_ProductComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to delete "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" product?"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.deletingProduct.files && _co.deletingProduct.files.length); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deletingProduct.sku; _ck(_v, 4, 0, currVal_0); }); }
function View_ProductComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to delete "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" products?"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.deleteProductPhotos = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, [" Delete also all photos linked to these products "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.deleteProductPhotos; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deletingProduct.count; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ProductComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm delete"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes, delete"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deletingProduct.id; _ck(_v, 7, 0, currVal_0); var currVal_1 = !_co.deletingProduct.id; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ProductComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { listView: 0 }), i1.ɵqud(402653184, 2, { confirmDeleteModal: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 8, "app-pagination-list-view", [["title", "Products"]], null, [[null, "itemsChange"], [null, "saveAsCollection"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemsChange" === en)) {
        var pd_0 = ((_co.products = $event) !== false);
        ad = (pd_0 && ad);
    } if (("saveAsCollection" === en)) {
        var pd_1 = (_co.saveAsCollection($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_PaginationListViewComponent_0, i4.RenderType_PaginationListViewComponent)), i1.ɵdid(3, 114688, [[1, 4], ["listView", 4]], 0, i5.PaginationListViewComponent, [], { title: [0, "title"], service: [1, "service"], itemList: [2, "itemList"], isFiltersOpen: [3, "isFiltersOpen"], totalPhotosForProcessing: [4, "totalPhotosForProcessing"], items: [5, "items"], filters: [6, "filters"] }, { itemsChange: "itemsChange", saveAsCollection: "saveAsCollection" }), (_l()(), i1.ɵeld(4, 0, null, 0, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-batch-actions-dropdown", [], null, null, null, i6.View_BatchActionsDropdownComponent_0, i6.RenderType_BatchActionsDropdownComponent)), i1.ɵdid(6, 114688, null, 0, i7.BatchActionsDropdownComponent, [], { selectedCount: [0, "selectedCount"], actions: [1, "actions"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-sm btn-success btn-create-product"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openProduct({}) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "mobile-hide"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create"])), (_l()(), i1.ɵand(0, [[2, 2], ["confirmDeleteModal", 2]], null, 0, null, View_ProductComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Products"; var currVal_1 = _co.productService; var currVal_2 = _co.itemList; var currVal_3 = _co.isFiltersOpen; var currVal_4 = _co.countProcessingPhotos; var currVal_5 = _co.products; var currVal_6 = _co.getFilterFields(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.selectedCount(); var currVal_8 = _co.batchActions(); _ck(_v, 6, 0, currVal_7, currVal_8); }, null); }
export function View_ProductComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product", [], null, null, null, View_ProductComponent_0, RenderType_ProductComponent)), i1.ɵdid(1, 245760, null, 0, i8.ProductComponent, [i9.ActivatedRoute, i10.ProductService, i11.CollectionService, i12.ItemTypeService, i13.NgbModal, i14.CookieService, i15.ToasterService, i16.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductComponentNgFactory = i1.ɵccf("app-product", i8.ProductComponent, View_ProductComponent_Host_0, {}, {}, []);
export { ProductComponentNgFactory as ProductComponentNgFactory };
