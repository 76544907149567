import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-save-buttons',
  templateUrl: './modal-save-buttons.component.html',
  styleUrls: ['./modal-save-buttons.component.scss'],
})
export class ModalSaveButtonsComponent implements OnInit {
  @Output() public save: EventEmitter<any> = new EventEmitter();
  @Output() public cancel: EventEmitter<any> = new EventEmitter();
  @Input() public isSaving = false;
  @Input() public isClosing = false;
  @Input() public enableSaveAndClose = true;

  constructor() { }

  ngOnInit() {
  }

  onSave(close = false) {
    this.save.emit(close);
  }

  onCancel() {
    this.cancel.emit();
  }

}
