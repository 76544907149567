import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie';
import { ToasterService } from 'angular2-toaster';
import { Observable, timer } from 'rxjs';
import { get } from 'lodash';

import { ProductService } from './product.service';
import { ProductEditorComponent } from './product-editor.component';
import { AddToCollectionComponent } from '@app/shared/add-to-collection/add-to-collection.component';
import { CollectionService } from '@app/collection/collection.service';
import { ItemTypeService } from '@app/core/item-type.service';
import { UtilService } from '@app/core/util.service';
import { ColumnsInterface, ActionsInterface } from '@app/shared/item-list/item-list.component';
import { PaginationListViewComponent, PaginationItemListInterface } from '@app/shared/pagination-list-view/pagination-list-view.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})

export class ProductComponent implements OnInit, OnDestroy {
  @ViewChild(PaginationListViewComponent) listView: PaginationListViewComponent;
  @ViewChild('confirmDeleteModal') private confirmDeleteModal: TemplateRef<any>;

  deletingProduct;
  deleteProductPhotos = true;
  modalRef;
  products = [];
  selectedProduct;
  editor;
  onEditorKeyup;
  basicInfo;
  productTypes;
  filterProductTypeId;
  isFiltersOpen = false;
  initialFilters = [];
  countProcessingPhotos=0;
  productActions: Array<ActionsInterface> = [{ type: 'delete', icon: 'fa fa-trash', title: 'Delete' }];
  productColumns: Array<ColumnsInterface> = [
    /*{ name: 'id', label: 'ID', type: 'text' },*/
    { name: 'sku', label: 'SKU', type: 'text' },
    /*{ name: 'attribute_values.name', label: 'Name', type: 'text' },*/
    { name: 'files', label: 'Image', type: 'first-image' },
    { name: 'created_at', label: 'Created', type: 'date' },
    { name: 'updated_at', label: 'Modified', type: 'date' },
  ];
  private filterFields;

  constructor(
    private route: ActivatedRoute,
    public productService: ProductService,
    private collectionService: CollectionService,
    private itemTypeService: ItemTypeService,
    private ngbModal: NgbModal,
    private cookieService: CookieService,
    private toasterService: ToasterService,
    private util: UtilService,
  ) { }

  ngOnInit() {
    this.basicInfo = this.route.parent.snapshot.data['basicInfo'];
    this.productService.countProcessingPhotos().subscribe(res=>this.countProcessingPhotos=res.count);
    this.route.params.subscribe(params => {
      /*const selectedProd = this.products.find(prod => prod.id === parseInt(params.id, 10));
      if (selectedProd) {
        this.openProduct(selectedProd);
      }*/
      try {
        const filters = JSON.parse(params.id);
        this.initialFilters = [];
        if (filters.length) {
          this.isFiltersOpen = true;
          filters.forEach(filter => this.initialFilters[filter.field || filter.attribute] = filter);
        }
      } catch (e) {}
    });

    this.fetchProductTypes();
  }

  batchActions() {
    return [{
      label: 'Add to collection',
      action: () => this.addToCollection(),
      icon: 'folder-open',
      disabled: this.listView.isOverSelected(),
    }, {
      label: 'Download',
      action: () => this.export(),
      href: `/api/product/export/zip?filters=${this.listView.getSelectedFilters(true)}`,
      icon: 'download',
    }, {
      label: 'Download CSV',
      action: () => this.export(),
      href: `/api/product/export/csv?filters=${this.listView.getSelectedFilters(true)}`,
      icon: 'download',
    }, {
      label: 'Batch edit',
      count: 2,
      action: () => this.openBatchEdit(),
      icon: 'edit',
      disabled: this.listView.isOverSelected(),
    }, {
      label: 'Delete',
      action: () => this.batchDelete(),
      icon: 'trash',
      btnType: 'danger',
      count: 2,
      disabled: this.listView.isOverSelected(),
    }];
  }

  fetchProductTypes() {
    this.itemTypeService.load().subscribe((itemTypes) => {
      this.productTypes = itemTypes;

      this.productColumns = this.getColumns();

      this.productColumns.push(...this.itemTypeService
        .filter(null, ({show_in_list, slug}) => {
          const alreadyAdded = this.productColumns.find(col => col.name === `attribute_values.${slug}`);
          return show_in_list && !alreadyAdded;
        }).map(({ slug, title, type, settings, input_element }) => ({
          name: `attribute_values.${slug}`,
          label: title,
          type,
          input_element,
          settings,
        })));

      this.filterFields = null;
    });
  }
  getColumns(){
    let settingsDefaultColumns=get(this.basicInfo, 'settings.defaultProductColumns');
    if (settingsDefaultColumns){
      settingsDefaultColumns=settingsDefaultColumns.filter(dfc=>dfc.name !== 'id' && dfc.name !== 'attribute_values.name');
      return settingsDefaultColumns;
    }else{
      return this.productColumns;
    }
  }
  get itemList(): PaginationItemListInterface {
    return {
      columns: this.productColumns,
      actions: this.productActions,
      isSelectable: true,
      onAction: this.productActionHandler,
      onSelectItem: this.openProduct,
    };
  }

  ngOnDestroy() {
    tinymce.remove('#description-field');
  }

  openProduct = (product) => {
    this.selectedProduct = product;
    this.openProductEditor(product);
  }

  closeModal() {
    this.selectedProduct = null;
    this.modalRef.hide();
  }

  selectedCount() {
    if (!this.listView.itemListView) {
      return 0;
    }
    return this.listView.itemListView.getSelectedCount();
  }

  selectedIds() {
    if (!this.listView.itemListView) {
      return '';
    }
    return this.listView.itemListView.getSelectedIds().join(',');
  }

  export() {
    this.util.setTemporaryAuthCookie();
  }

  addToCollection(filters?) {
    this.modalRef = this.ngbModal.open(AddToCollectionComponent, { size: 'lg' });
    if (filters) {
      this.modalRef.componentInstance.filters = filters;
    }
    this.modalRef.componentInstance.selectedProducts = this.util
      .getSelectedIds(this.products).map(i => ({ id: i }));
  }

  openBatchEdit() {
    this.openProductEditor(this.products.filter(p => p.isSelected));
  }

  productActionHandler = ($event) => {
    if ($event.action.type === 'delete') {
      $event.event.stopPropagation();
      this.openDeleteConfirmModal($event.item, () => {
        const imageIds = this.deleteProductPhotos ? $event.item.files.map(({ id }) => id) : null;
        this.productService.delete($event.item.id, imageIds)
          .subscribe(() => {
            this.toasterService.pop('success', 'Product deleted!', 'The product has been deleted.');
          });
      });
    }
  }

  getFilterFields() {
    // get filterFields if the result is not yet cached
    if (!this.filterFields) {
      const attributeFields = this.itemTypeService
        .filter(this.productTypeFilter, this.isFieldFilteringAllowed);

      let filters = get(this.basicInfo, 'defaultFilterAttributes.product', [])
        .concat(attributeFields)
        .filter(f=>f.slug !== 'id' && f.slug !== 'name' && f.slug !== 'item_type_id');

      if (filters.findIndex(s=>s.slug === 'user') === -1){
        this.productService.getAllUsersByOrganization().subscribe((res)=>{
          let users=res.map(u=>{return {'id':u.id,'name':u.email,'badge':'success'}})

          filters.push({
            "id": filters.length+1,
            "created_at": null,
            "updated_at": null,
            "parent_id": null,
            "organization_id": this.basicInfo.organization_id,
            "settings": {
              "view": "badge",
              "options": [...users
              ],
              "viewConfig": {
                "icon": "fa fa-lock"
              }
            },
            "slug": "user",
            "title": "User",
            "type": "string",
            "input_element": "select",
            "is_filtering_enabled": true,
            "is_batch_edit_enabled": true,
            "show_in_list": true,
            "show_in_remote_controller": false,
            "_pivot_item_type_id": 1,
            "_pivot_attribute_id": 3
          });
        })

      }
      // the item type filter should have the product types as selectable values
      const itemTypeFilter = filters.find(({ slug }) => slug === 'item_type_id');
      if (itemTypeFilter) {
        itemTypeFilter.settings = {
          options: this.productTypes,
          onChange: (value) => {
            this.filterProductTypeId = value;
          },
        };
      }

      // apply initial filter values
      filters.forEach(filter => {
        const initialFilter = this.initialFilters[filter.slug];
        if (initialFilter && initialFilter.value) {
          filter.filter_value = initialFilter.value;
        } else if (filter.filter_value) {
          filter.filter_value = null;
        }
      });
      this.filterFields = filters;
    }
    return this.filterFields;
  }

  saveAsCollection(filters) {
    this.addToCollection(filters);
  }

  async batchDelete() {
    const count = this.selectedCount();
    this.openDeleteConfirmModal({ count }, () => {
      this.productService.batchDelete(this.listView.getSelectedFilters(), this.deleteProductPhotos)
        .subscribe(() => {
          this.toasterService.pop('success', 'Products deleted!', `${count} products has been deleted.`);
        });
    });
  }

  private productTypeFilter = ({ id }) => !this.filterProductTypeId || this.filterProductTypeId === id;

  private isFieldFilteringAllowed = ({ is_filtering_enabled }) => is_filtering_enabled;

  private openProductEditor(product) {
    // the timer is a workaround for this: https://github.com/angular/angular/issues/15634
    timer(0).subscribe(() => {
      this.modalRef = this.ngbModal.open(ProductEditorComponent, { size: 'lg' });
      this.modalRef.componentInstance.product = product;
      this.modalRef.componentInstance.products = this.products;
      this.modalRef.componentInstance.basicInfo = this.basicInfo;
      this.modalRef.componentInstance.productTypes = this.productTypes;
      this.modalRef.componentInstance.changeProduct.call(this.modalRef.componentInstance);
      this.modalRef.result.then((closed) => {
        this.productService.countProcessingPhotos().subscribe(res=>this.countProcessingPhotos=res.count);
        if (closed && closed.reload) {
          this.listView.fetchItems(true);
        }
      }).catch((error) => {

      });
    });
  }

  private openDeleteConfirmModal(deletingProduct, action) {
    this.deletingProduct = deletingProduct;
    this.deleteProductPhotos = true;
    const modalRef = this.ngbModal.open(this.confirmDeleteModal);
    modalRef.result
      .then(action)
      .catch(() => {
        // delete cancelled
      }).then(() => {
        this.deletingProduct = null;
      });
  }
}
