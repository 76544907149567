<div class="card">
  <div class="toolbar">
    <h1 class="pull-left title">
      Remote control
    </h1>
  </div>

  <div *ngIf="!isReady" class="connecting">
    <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
    Connecting...
  </div>

  <div *ngIf="isReady">
    <table class="table" *ngIf="devices">
      <thead>
        <tr>
          <th>Device name</th>
          <th>Reachable?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of devices">
          <td>
            <i class="icon-screen-smartphone"></i>
            {{ device.name }}
          </td>
          <td>
            <i class="fa" [ngClass]="{'fa-times': !device.isConnectable, 'fa-check': device.isConnectable}"></i>
          </td>
          <td>
            <button class="btn btn-success" (click)="openDevice(device)" [disabled]="!device.isConnectable">
              <i class="fa fa-plug"></i>
              Connect
            </button>
            <button
              (click)="refreshDevice(device)"
              class="btn btn-primary"
              title="Refresh device status">
              <i class="fa fa-refresh"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">
            <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
            Searching...
          </td>
        </tr>
      </tfoot>
    </table>

    <div class="info">
      <app-remote-control-helper title="Can't see your device here or it is not reachable?"></app-remote-control-helper>
    </div>
  </div>

</div>
