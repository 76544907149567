<app-pagination-list-view
  title="Collections"
  [service]="collectionService"
  [(items)]="collections"
>
  <app-item-list
    view-content
    [items]="getCollections()"
    [actions]="actions"
    [columns]="columns"
    [isSelectable]="false"
    (onAction)="actionHandler($event)"
    (onSelectItem)="openCollection($event)"
  ></app-item-list>
</app-pagination-list-view>

<ng-template #downloadTemplate let-item let-templateVars="templateVars">
  <a class="btn-fade btn btn-sm btn-secondary pull-right"
    [href]="'/api/collection/' + item.id + '/export/'"
    (click)="export($event)"
    download>
    <i class="fa fa-download"></i>
  </a>
</ng-template>
