import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { get, isString, isFunction } from 'lodash';
import { Logger } from 'angular2-logger/core';
import { timer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '@app/core/email.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Input() product;
  @Output() onCancel = new EventEmitter();
  @ViewChild('contentTextArea') contentTextArea: ElementRef;

  message = {
    template: null,
    templateId: null,
    from_name: null,
    from_email: null,
    to_name: null,
    to_email: null,
    subject: '',
    content: '',
  };
  templates = [];
  isSending = false;
  showPreview = false;
  success = false;
  closeTimer;

  constructor(
    private emailService: EmailService,
    private logger: Logger,
    private ngbActiveModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.emailService.loadTemplates().subscribe((templates) => {
      this.templates = templates.filter(this.templateFilter).map((template) => ({
        ...template,
        content: this.compile(template.content),
        subject: this.compile(template.subject),
      }));

      const defaultTemplate = this.templates.find(({ settings }) => settings && settings.isDefault);
      if (defaultTemplate) {
        this.message.templateId = defaultTemplate.id;
        this.onTemplateChange(defaultTemplate.id);
      }
    });

    this.setMessageToValues();
  }

  onTemplateChange(templateId) {
    this.message.template = this.templates.find(({ id }) => templateId === id);

    Object.keys(this.message)
      .filter(key => key.indexOf('template') === -1)
      .forEach((key) => {
        this.message[key] = (this.message.template ? this.message.template[key] : null) || this.message[key];
      });

    this.setMessageToValues();

    if (this.contentTextArea) {
      // adjust initial height of text area
      this.contentTextArea.nativeElement.value = this.message.content;
      this.contentTextArea.nativeElement.dispatchEvent(new Event('input'));
    }
  }

  send() {
    if (!this.showPreview) {
      this.showPreview = true;
      return;
    }

    this.isSending = true;
    this.emailService.send(this.message)
      .pipe(finalize(() => {
        this.isSending = false;
      }))
      .subscribe(() => {
        this.success = true;
        this.closeTimer = timer(5000).subscribe(() => this.cancel());
      });
  }

  cancel() {
    if (this.closeTimer) {
      this.closeTimer.unsubscribe();
    }

    if (this.showPreview && !this.success) {
      this.showPreview = false;
      return;
    }

    if (this.onCancel.observers.length) {
      this.onCancel.emit();
      return;
    }

    this.ngbActiveModal.close();
  }

  private templateFilter = (template) => {
    const settings = template.settings || {};

    if (settings.hidden) {
      return false;
    }

    if (!settings.type) {
      return true;
    }

    if (this.product && settings.type !== 'product') {
      return false;
    }

    return true;
  }

  private compile(template) {
    if (!isFunction(template)) {
      return '';
    }

    try {
      return template(this.product);
    } catch (error) {
      this.logger.error('Failed to compile template', error);
      return '';
    }
  }

  private setMessageToValues() {
    const email = get(this.product, 'attribute_values.email');
    const name = get(this.product, 'attribute_values.user_name');

    if (email && isString(email) && email.indexOf('@') > -1) {
      this.message.to_email = email;
    }
    if (name && isString(name)) {
      this.message.to_name = name;
    }
  }
}
