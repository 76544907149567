import { Injectable } from '@angular/core';
import { pick, get } from 'lodash';
import { ToasterService } from 'angular2-toaster';
import { AuthService } from '@app/core/auth.service';
import { BaseApiService } from '@app/core/base-api.service';
import { UtilService } from '@app/core/util.service';

@Injectable()
export class PhotoService extends BaseApiService {
  constructor(
    authService: AuthService,
    util: UtilService,
    private toasterService: ToasterService,
  ) {
    super(authService, util);
    this.modelEndpoint = 'api/file';
  }

  update(photoObj) {
    const photo = <any>pick(photoObj, ['name', 'item_type_id', 'attribute_values']);
    if (photoObj.product && photoObj.product.sku && !photoObj.product.id) {
      photo.product_id = null;
      photo.sku = photoObj.product.sku;
    } else {
      photo.product_id = get(photoObj, 'product.id', photoObj.product_id);
    }

    return this.authService.put(`${this.modelEndpoint}/${photoObj.id}`, photo);
  }

  confirmAndDelete(id: number) {
    return new Promise((resolve, reject) => {
      // save changes to the DB
      if (window.confirm('Are you sure you want to delete this photo?')) {
        this.delete(id)
          .subscribe(() => {
            this.toasterService.pop('success', 'Photo deleted!', 'The photo has been deleted.');
            resolve();
          });
      } else {
        reject();
      }
    });
  }

  confirmAndDeleteBatch(idList) {
    return new Promise((resolve, reject) => {
      // save changes to the DB
      if (window.confirm(`Are you sure you want to delete ${idList.length} photos?`)) {
        this.batchDelete(this.getFilters(idList))
          .subscribe(() => {
            this.toasterService.pop('success', 'Photos deleted!', `${idList.length} photos has been deleted.`);
            resolve();
          });
      } else {
        reject();
      }
    });
  }

  stats(from, to, userIds) {
    if (userIds && userIds.length > 0){
      return this.authService.get(`${this.modelEndpoint}/stats?from=${from}&to=${to}&users=${userIds.map(user=>user.id).join(',')}`);
    }else{
      return this.authService.get(`${this.modelEndpoint}/stats?from=${from}&to=${to}`);
    }

  }

  uniqueKey() {
    return this.authService.get(`${this.modelEndpoint}/unique-key`);
  }
}
