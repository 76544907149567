import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { flatten, uniqBy } from 'lodash';
import { AuthService } from '@app/core/auth.service';
import { BaseApiService } from '@app/core/base-api.service';
import { UtilService } from '@app/core/util.service';

type filterCallback = (value: any, index: number, array: {}[]) => any;

@Injectable()
export class ItemTypeService extends BaseApiService {
  private itemTypes;

  constructor(
    authService: AuthService,
    util: UtilService,
  ) {
    super(authService, util);
    this.modelEndpoint = 'api/itemtype';
  }

  load() {
    return Observable.create((observer) => {
      const close = () => {
        observer.next(this.itemTypes);
        observer.complete();
      };

      if (this.itemTypes) {
        close();
        return;
      }

      this.getNextPage(true)
      .pipe(catchError(error => observer.error(error)))
      .subscribe((itemTypes) => {
        this.itemTypes = itemTypes;
        close();
      });
    });
  }

  loadAndFilter(preFilter: filterCallback = null, postFilter: filterCallback = null) {
    return Observable.create((observer) => {
      this.load()
      .pipe(catchError(error => observer.error(error)))
      .subscribe(() => {
        observer.next(this.filter(preFilter, postFilter));
        observer.complete();
      });
    });
  }

  filter(preFilter: filterCallback = null, postFilter: filterCallback = null) {
    let itemTypes = this.itemTypes || [];
    if (preFilter) {
      itemTypes = itemTypes.filter(preFilter);
    }

    const productType = flatten(itemTypes
      .map(item => item.custom_attributes));

    const uniqItemTypes = uniqBy(productType, 'slug');
    if (!postFilter) {
      return uniqItemTypes;
    }

    return uniqItemTypes.filter(postFilter);
  }
}
