/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public-collection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../shared/item-list/item-list.component.ngfactory";
import * as i4 from "../shared/item-list/item-list.component";
import * as i5 from "angular2-toaster/src/toaster.service";
import * as i6 from "@angular/router";
import * as i7 from "../core/util.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./public-collection.component";
import * as i10 from "../collection/collection.service";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
var styles_PublicCollectionComponent = [i0.styles];
var RenderType_PublicCollectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicCollectionComponent, data: {} });
export { RenderType_PublicCollectionComponent as RenderType_PublicCollectionComponent };
function View_PublicCollectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "pull-left logo"], ["src", "assets/img/logo-black.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "pull-left title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "form-group pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "btn btn-sm btn-primary btn-export"], ["ng-reflect-klass", "btn btn-sm btn-primary btn-exp"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "mobile-hide"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collection.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "/api/collection/public/", _co.collection.key, "/export"); _ck(_v, 7, 0, currVal_1); }); }
function View_PublicCollectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_PublicCollectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row load-more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn btn-primary btn-wide"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicCollectionComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" Load more "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }); }
function View_PublicCollectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "wrapper row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-item-list", [], null, [[null, "onSelectItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectItem" === en)) {
        var pd_0 = (_co.openItem($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ItemListComponent_0, i3.RenderType_ItemListComponent)), i1.ɵprd(512, null, i2.DatePipe, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(10, 114688, null, 0, i4.ItemListComponent, [i5.ToasterService, i6.Router, i7.UtilService, i8.DomSanitizer, i2.DatePipe], { columns: [0, "columns"], items: [1, "items"], actions: [2, "actions"] }, { onSelectItem: "onSelectItem" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicCollectionComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getColumns(); var currVal_3 = _co.items; var currVal_4 = _co.getActions(); _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = (_co.isLoading || (_co.collection.items.pageCount > _co.collection.items.page)); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collection.description; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); }); }
function View_PublicCollectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-sm btn-secondary btn-fade"], ["download", ""], ["target", "_blank"], ["title", "Download"]], [[1, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-download"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((("/api/collection/public/" + _v.context.templateVars.collectionKey) + "/export/") + _v.context.$implicit.id); _ck(_v, 0, 0, currVal_0); }); }
function View_PublicCollectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-sm btn-secondary btn-fade"], ["download", ""], ["target", "_blank"], ["title", "Download"]], [[1, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-download"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_PublicCollectionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { downloadProductTemplate: 0 }), i1.ɵqud(402653184, 2, { downloadPhotoTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicCollectionComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicCollectionComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["downloadProductTemplate", 2]], null, 0, null, View_PublicCollectionComponent_5)), (_l()(), i1.ɵand(0, [[2, 2], ["downloadPhotoTemplate", 2]], null, 0, null, View_PublicCollectionComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collection; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.collection; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PublicCollectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-public-collection", [], null, null, null, View_PublicCollectionComponent_0, RenderType_PublicCollectionComponent)), i1.ɵdid(1, 114688, null, 0, i9.PublicCollectionComponent, [i6.ActivatedRoute, i10.CollectionService, i11.NgbModal, i5.ToasterService, i7.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicCollectionComponentNgFactory = i1.ɵccf("app-public-collection", i9.PublicCollectionComponent, View_PublicCollectionComponent_Host_0, {}, {}, []);
export { PublicCollectionComponentNgFactory as PublicCollectionComponentNgFactory };
