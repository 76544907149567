import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { pick } from 'lodash';
import Handlebars from 'handlebars';

import { BaseApiService } from '@app/core/base-api.service';

@Injectable()
export class EmailService extends BaseApiService {
  modelEndpoint = 'api/emailtemplate';
  sendEndpoint = 'api/emailer/send';
  private templates = null;
  private messageFields = ['to_name', 'to_email', 'from_name', 'from_email', 'subject', 'content'];

  loadTemplates() {
    if (this.templates !== null) {
      return of(this.templates);
    }

    return this.authService.get(this.modelEndpoint)
      .pipe(map((response: any) => {
        this.templates = response.body.map((template) => ({
          ...template,
          content: Handlebars.compile(template.content || ''),
          subject: Handlebars.compile(template.subject || ''),
        }));

        return this.templates;
      }));
  }

  send(message) {
    return this.authService
      .post(this.sendEndpoint, this.pickMessageFields(message))
      .pipe(map((response: any) => response.body));
  }

  sendTemplate(id, message) {
    return this.authService
      .post(`${this.sendEndpoint}/${id}`, {
        ...this.pickMessageFields(message),
        params: message.params || {},
      })
      .pipe(map((response: any) => response.body));
  }

  private pickMessageFields(message) {
    return pick(message, this.messageFields);
  }
}
