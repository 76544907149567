import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Logger } from 'angular2-logger/core';

import { UtilService } from '@app/core/util.service';

import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE_LEFT,
  ORIENTATION_LANDSCAPE_RIGHT,
  ORIENTATION_PORTRAITUPSIDEDOWN,
} from '@app/remote-control/device';

@Component({
  selector: 'app-aspect-ratio-selector',
  templateUrl: './aspect-ratio-selector.component.html',
  styleUrls: ['./aspect-ratio-selector.component.scss'],
})
export class AspectRatioSelectorComponent {
  basicInfo;
  aspectRatios = [
    '16:9',
    '3:2',
    '4:3',
    '1:1',
    'CUSTOM',
    'FULL',
    'FREE',
  ];
  aspectNames = {
    CUSTOM: 'Custom',
    FULL: 'Full',
    FREE: 'Free',
  };
  isCustomInputActive = false;
  width: number;
  height: number;
  @Input() hasFreeAspectRatio = false;
  @Input() hasFullAspectRatio = false;
  @Output() aspectRatioChange = new EventEmitter<any>();
  @Output() orientationChange = new EventEmitter<any>();

  private _aspectRatio: string;
  private _orientation: string;

  constructor(
    private logger: Logger,
    private util: UtilService,
  ) { }

  get aspectRatio() {
    return this._aspectRatio;
  }

  @Input()
  set aspectRatio(aspect) {
    this._aspectRatio = aspect;
    this.aspectRatioChange.emit(this._aspectRatio);

    // hide if the aspect ratio is found in the list and custom field doesn't have focus
    const customHasFocus = document.activeElement && document.activeElement.classList.contains('custom-aspect-ratio-textbox');
    const flippedAspect = this.util.flipAspectRatio(aspect);
    const isCustomAspect = !this.aspectRatios.find(listAspect => listAspect === aspect || listAspect === flippedAspect);

    if (!customHasFocus && !isCustomAspect) {
      this.isCustomInputActive = false;
    }
  }

  get orientation() {
    return this._orientation;
  }

  @Input()
  set orientation(orientation) {
    this._orientation = orientation;
    this.orientationChange.emit(orientation);
    // make sure the list of aspect ratios is coherent with the selected orientation
    this.ensureAspectRatioOrientation();
  }

  getAspectRatios() {
    return this.aspectRatios.filter(aspect => {
      if (aspect === 'FULL' && !this.hasFullAspectRatio) {
        return false;
      }
      if (aspect === 'FREE' && !this.hasFreeAspectRatio) {
        return false;
      }
      return true;
    });
  }

  getName(aspect) {
    return this.aspectNames[aspect] || aspect;
  }

  activateCustomInput() {
    const aspectParts = this.util.getAspectParts(this.aspectRatio);
    if (aspectParts && !isNaN(aspectParts[0]) && !isNaN(aspectParts[1])) {
      this.width = aspectParts[0];
      this.height = aspectParts[1];
    } else {
      this.width = 1;
      this.height = 1;
    }

    this.isCustomInputActive = true;
  }

  customAspectRatioChanged() {
    if (!isNaN(this.width) && !isNaN(this.height) && this.width > 0 && this.height > 0) {
      this.aspectRatio = `${this.width}:${this.height}`;
    }
  }

  isSelectedAspectRatio(aspect) {
    return aspect === this.aspectRatio;
  }

  selectAspectRatio(aspect) {
    if (aspect === 'CUSTOM') {
      this.activateCustomInput();
    } else {
      this.aspectRatio = aspect;
    }
  }

  turnOrientation() {
    let newOrientation = ORIENTATION_PORTRAIT;
    if (this.orientation === ORIENTATION_PORTRAIT) {
      newOrientation = ORIENTATION_LANDSCAPE_RIGHT;
    } else if (this.orientation === ORIENTATION_LANDSCAPE_RIGHT) {
      newOrientation = ORIENTATION_PORTRAITUPSIDEDOWN;
    } else if (this.orientation === ORIENTATION_PORTRAITUPSIDEDOWN) {
      newOrientation = ORIENTATION_LANDSCAPE_LEFT;
    }

    this.orientation = newOrientation;
    this.aspectRatio = this.util.flipAspectRatioToOrientation(this.aspectRatio, this.orientation);
  }

  ensureAspectRatioOrientation() {
    this.aspectRatios = this.aspectRatios.map(aspect => this.util.flipAspectRatioToOrientation(aspect, this.orientation));
  }

}
