import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { get, isArray } from 'lodash';
import { UtilService } from '@app/core/util.service';
export class ItemListComponent {
    constructor(toasterService, router, util, domSanitizer, datePipe) {
        this.toasterService = toasterService;
        this.router = router;
        this.util = util;
        this.domSanitizer = domSanitizer;
        this.datePipe = datePipe;
        this.selectAllTotal = false;
        this.columnCache = new Map();
        this.maxSelect = null;
        this.mode = 'list';
        this.isSelectable = false;
        this.disableGrid = false;
        this.totalCount = 0;
        this.onSelectItem = new EventEmitter();
        this.onAction = new EventEmitter();
    }
    ngOnInit() {
    }
    get areAllSelected() {
        return this.getSelectedIds().length === (this.items || []).length;
    }
    get canSelectMore() {
        return this.areAllSelected && (this.items || []).length < this.totalCount;
    }
    toggleAllTotal(clear = true) {
        this.selectAllTotal = !this.selectAllTotal;
        if (clear && !this.selectAllTotal) {
            this.toggleAll();
        }
    }
    getGridColumns(item) {
        const columns = this.getColumns(item);
        // set image column first
        columns.sort((a, b) => {
            if (a.name === 'files') {
                return -1;
            }
            if (b.name === 'files') {
                return 1;
            }
            return 0;
        });
        // remove id and default timestamp columns
        return columns.filter(col => ['id', 'created_at', 'updated_at'].indexOf(col.name) === -1);
    }
    getColumns(item) {
        const { prev, next, integration_data } = item, itemCopy = tslib_1.__rest(item, ["prev", "next", "integration_data"]);
        const cacheKey = JSON.stringify(itemCopy); // TODO: Refactor this ugly hack
        const cachedColumns = this.columnCache.get(cacheKey);
        if (cachedColumns) {
            return cachedColumns;
        }
        const columns = this.columns.map((columnData) => {
            const column = Object.assign({}, columnData, { item });
            const content = get(item, column.name);
            column.content = content;
            column.view = this.textColumn;
            const settings = get(column, 'settings') || {};
            const viewConfig = get(settings, 'viewConfig', {});
            let option = get(settings, 'options', [])
                .find(opt => opt === content || opt.id === content) || content;
            // option can be set with a function
            if (settings.getOption) {
                option = settings.getOption(item);
            }
            if (column.input_element === 'select' || column.type === 'badge') {
                column.content = get(option, 'name', content);
            }
            switch (settings.view || column.type) {
                case 'image':
                case 'first-image':
                    column.view = this.imageColumn;
                    column.cssClass = 'centered';
                    if (isArray(content)) {
                        column.content = get(content, '0.url');
                    }
                    else {
                        column.content = content;
                    }
                    break;
                case 'date':
                    column.content = this.datePipe.transform(content, 'dd.MM.yyyy HH:mm');
                    column.title = this.datePipe.transform(content, 'dd. MMM yyyy HH:mm:ss');
                    break;
                case 'visibility':
                    column.view = this.badgeColumn;
                    column.title = 'Visibility';
                    column.content = content ? 'Public' : 'Private';
                    column.settings = {
                        icon: `fa fa-${content ? 'unlock-alt' : 'lock'}`,
                        badge: content ? 'badge-success' : 'badge-danger',
                    };
                    break;
                case 'progress':
                    column.view = this.progressColumn;
                    break;
                case 'count':
                    column.content = this.getCount(item, column.name) + '';
                    break;
                case 'badge':
                    column.view = this.badgeColumn;
                    const badge = get(option, 'badge', viewConfig.badge);
                    column.settings = {
                        icon: get(option, 'icon', viewConfig.icon),
                        badge: badge ? `badge-${badge}` : null,
                        color: get(option, 'color', viewConfig.color),
                        textColor: get(option, 'textColor', viewConfig.textColor),
                    };
                    break;
            }
            return column;
        });
        this.columnCache.set(cacheKey, columns);
        return columns;
    }
    openItem(item) {
        this.onSelectItem.emit(item);
    }
    toggleIsSelected($event, item) {
        $event.stopPropagation();
        item.isSelected = !item.isSelected;
        if (!item.isSelected && this.selectAllTotal) {
            this.selectAllTotal = false;
        }
        if (this.maxSelect && this.maxSelect === 1) {
            this.onSelectItem.emit(item);
        }
    }
    toggleAll(newValue = !this.areAllSelected) {
        if (!newValue) {
            this.selectAllTotal = false;
        }
        this.items.forEach((item) => {
            item.isSelected = newValue;
        });
    }
    getSelectedCount(onlyItems = false) {
        if (this.selectAllTotal) {
            return this.totalCount;
        }
        if (!this.items) {
            return 0;
        }
        return this.items.filter(({ isSelected }) => isSelected).length;
    }
    getSelectedIds() {
        if (!this.items) {
            return [];
        }
        return this.items.filter(({ isSelected }) => isSelected).map(({ id }) => id);
    }
    getCount(item, name) {
        const value = get(item, name);
        return isArray(value) ? value.length : 0;
    }
}
