/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-cropper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./photo-cropper.component";
import * as i3 from "angular2-logger/core";
import * as i4 from "../../core/util.service";
import * as i5 from "@angular/platform-browser";
var styles_PhotoCropperComponent = [i0.styles];
var RenderType_PhotoCropperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoCropperComponent, data: {} });
export { RenderType_PhotoCropperComponent as RenderType_PhotoCropperComponent };
export function View_PhotoCropperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { photoElement: 0 }), i1.ɵqud(402653184, 2, { rotateElement: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["photoElement", 1]], null, 0, "img", [["class", "photo"], ["crossorigin", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "photo-preview"], ["id", "preview"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["rotateElement", 1]], null, 0, "i", [["class", "fa fa-rotate-right"], ["id", "rotate-handle"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onDragStart($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.src; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.getStyles(); _ck(_v, 4, 0, currVal_2); }); }
export function View_PhotoCropperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo-cropper", [], null, null, null, View_PhotoCropperComponent_0, RenderType_PhotoCropperComponent)), i1.ɵdid(1, 4374528, null, 0, i2.PhotoCropperComponent, [i3.Logger, i4.UtilService, i1.ChangeDetectorRef, i5.DomSanitizer], null, null)], null, null); }
var PhotoCropperComponentNgFactory = i1.ɵccf("app-photo-cropper", i2.PhotoCropperComponent, View_PhotoCropperComponent_Host_0, { photo: "photo", overlay: "overlay", rotation: "rotation", zoom: "zoom", orientation: "orientation", aspectRatio: "aspectRatio" }, { rotationChange: "rotationChange", zoomChange: "zoomChange" }, []);
export { PhotoCropperComponentNgFactory as PhotoCropperComponentNgFactory };
