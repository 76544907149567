import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Logger } from 'angular2-logger/core';
import { timer } from 'rxjs';
import { defaultsDeep } from 'lodash';
import { ToasterService } from 'angular2-toaster';
import { map } from 'rxjs/operators';

const MILLISECONDS_IN_SECOND = 1000;
const WARN_BEFORE_LOGOUT = MILLISECONDS_IN_SECOND * 60;

@Injectable()
export class AuthService {
  private tokenExpiryTimer;
  private tokenExpiryWarnTimer;
  private basicInfo;
  private basicInfoPromise: Promise<any>;

  constructor(
    private logger: Logger,
    private http: HttpClient,
    private router: Router,
    private toasterService: ToasterService,
  ) { }

  get accessToken() {
    return localStorage.getItem('accessToken');
  }

  set accessToken(accessToken) {
    if (!accessToken) {
      localStorage.removeItem('accessToken');
    } else {
      localStorage.setItem('accessToken', accessToken);
    }
  }

  get accessTokenExpiration(): number {
    return parseInt(localStorage.getItem('accessTokenExpiration'), 10);
  }

  set accessTokenExpiration(accessTokenExpiration: number) {
    if (!accessTokenExpiration) {
      localStorage.removeItem('accessTokenExpiration');
      this.clearTimer();
    } else {
      localStorage.setItem('accessTokenExpiration', (Date.now() + accessTokenExpiration * MILLISECONDS_IN_SECOND).toString());
      this.createTimer(true);
    }
  }

  get refreshToken() {
    return localStorage.getItem('refreshToken');
  }

  set refreshToken(refreshToken) {
    if (!refreshToken) {
      localStorage.removeItem('refreshToken');
    } else {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }

  login(username: string, password: string, getRefreshToken = false) {
    return this.http.post('api/user/login', JSON.stringify({
      username,
      password,
      get_refresh_token: getRefreshToken,
    }))
      .pipe(map((response: any) => {
        // login successful if there's a jwt token in the response
        if (response && response.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.accessToken = response.token;
          this.refreshToken = response.refresh_token;
          this.accessTokenExpiration = response.expires_in;
        }
      }));
  }

  requestNewAccessToken() {
    return this.http.get('api/user/refresh-access-token');
  }

  isRefreshTokenRequest(req: HttpRequest < any > ) {
    return req.url === 'api/user/refresh-access-token';
  }

  hasRefreshToken(): boolean {
    return !!this.refreshToken;
  }

  logout() {
    // remove access token from local storage to log user out
    if (this.accessToken || this.refreshToken) {
      this.toasterService.pop('success', 'Logged out!', 'You have succesfully been logged out.');
    }

    this.clearTokens();
  }

  clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
    this.accessTokenExpiration = null;
  }

  createAuthHeader(request?: HttpRequest<any>) {
    let headers = request ? request.headers : null;

    if (!headers) {
      headers = new HttpHeaders();
    }

    const token = request && this.isRefreshTokenRequest(request) ? this.refreshToken : this.accessToken;

    if (token) {
      this.logger.debug('Authorization header added with token');
      headers = headers.append('Authorization', `Bearer ${token}`);
    }

    return headers;
  }

  me(): Promise<any> {
    if (this.basicInfo) {
      this.logger.debug('basicInfo is already resolved', this.basicInfo);
      return Promise.resolve(this.basicInfo);
    }

    if (this.basicInfoPromise) {
      this.logger.debug('return this.basicInfoPromise');
      return this.basicInfoPromise;
    }

    this.logger.debug('create new basicInfoPromise');
    this.basicInfoPromise = this.get('api/user/me').toPromise()
      .then((response: any) => {
        this.basicInfo = response.body;
        this.basicInfoPromise = null;
        this.createTimer();
        return this.basicInfo;
      }).catch((err) => {
        this.basicInfoPromise = null;
        throw err;
      });

    return this.basicInfoPromise;
  }
  getAllUsersByOrganization() {
    return this.get(`api/user/list`).pipe(map((res: any) => res.body));
  }
  get(url: string, config?: any) {
    return this.http.get(url, defaultsDeep(config || {}, {
      observe: 'response',
    }));
  }

  post(url: string, data?: any, config?: any) {
    return this.http.post(url, data, defaultsDeep(config || {}, {
      observe: 'response',
    }));
  }

  put(url: string, data?: any, config?: any) {
    return this.http.put(url, data, defaultsDeep(config || {}, {
      observe: 'response',
    }));
  }

  delete(url: string, config?: any) {
    return this.http.delete(url, defaultsDeep(config || {}, {
      observe: 'response',
    }));
  }

  private createTimer(reset = false) {
    if (reset) {
      this.clearTimer();
    }

    if (this.tokenExpiryTimer) {
      this.logger.debug('tokenExpiryTimer already set');
      return;
    }

    // automatically log out when token expires
    const timeToExpiration = this.accessTokenExpiration - Date.now();
    if (timeToExpiration <= 0) {
      this.logger.debug('do not set tokenExpiryTimer', this.accessTokenExpiration, timeToExpiration);
      return;
    }

    let warnToast;
    this.tokenExpiryWarnTimer = timer(timeToExpiration - WARN_BEFORE_LOGOUT, MILLISECONDS_IN_SECOND)
      .subscribe(() => {
        if (this.refreshToken) {
          this.logger.debug('Token is expiring, refresh it');
          this.tokenExpiryWarnTimer.unsubscribe();
          this.requestNewAccessToken()
            .subscribe((newToken: any) => {
              this.accessToken = newToken.token;
              this.accessTokenExpiration = newToken.expires_in;
            });
        } else {
          // TODO: Instead of warning, prompt user to continue before logging out
          const timeLeft = Math.floor((this.accessTokenExpiration - Date.now()) / MILLISECONDS_IN_SECOND);
          const body = `Your session will expire in ${ timeLeft } seconds. Please save all unsaved work.`;

          if (!warnToast) {
            warnToast = this.toasterService.pop({
              type: 'warning',
              title: 'Session about to expire',
              body,
              timeout: 0,
            });
          } else {
            warnToast.body = body;
          }
        }
    });

    this.logger.debug('Set tokenExpiryTimer', timeToExpiration);
    this.tokenExpiryTimer = timer(timeToExpiration)
      .subscribe(() => {
        this.tokenExpiryWarnTimer.unsubscribe();
        this.logout();
        this.router.navigate(['/login']);
      });
  }

  private clearTimer() {
    if (this.tokenExpiryTimer) {
      this.tokenExpiryTimer.unsubscribe();
      this.tokenExpiryTimer = null;
    }

    if (this.tokenExpiryWarnTimer) {
      this.tokenExpiryWarnTimer.unsubscribe();
      this.tokenExpiryWarnTimer = null;
    }
  }
}

// Basic info resolver for routes
import { Resolve } from '@angular/router';

@Injectable()
export class BasicInfoResolve implements Resolve<any> {

  constructor(
    private authService: AuthService,
    private logger: Logger,
    private router: Router,
  ) {}

  resolve() {
    return this.authService.me().catch((error) => {
      this.logger.error('Failed to resolve me', error);
      this.router.navigate(['/login']);
    });
  }
}
