import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, merge, catchError } from 'rxjs/operators';
import { get } from 'lodash';

import { CollectionService } from '@app/collection/collection.service';

@Component({
  selector: 'app-add-to-collection',
  templateUrl: './add-to-collection.component.html',
  styleUrls: ['./add-to-collection.component.scss'],
})
export class AddToCollectionComponent implements OnInit {
  @Input() selectedProducts: any;
  @Input() selectedPhotos: any;
  @Input() filters: any;

  collection = <any>{};
  description;
  searching = false;
  searchFailed = false;
  hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
  collectionSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => this.collectionService.search(term, this.type)
        .pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => this.searching = false),
      merge(this.hideSearchingWhenUnsubscribed)
    );


  constructor(
    private collectionService: CollectionService,
    private ngbActiveModal: NgbActiveModal,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
  }

  get type() {
    return this.selectedPhotos ? CollectionService.TYPE_PHOTO : CollectionService.TYPE_PRODUCT;
  }

  collectionFormatter(collection) {
    return !collection.id ? null : `${collection.name || ''}`;
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  save() {
    if (this.collection.id) {
      if (this.selectedPhotos) {
        this.collection.files = this.collection.files.concat(this.selectedPhotos);
      }
      if (this.selectedProducts) {
        this.collection.products = this.collection.products.concat(this.selectedProducts);
      }

      // remove filters if saved as a static collection
      if (this.willOverrideSmartCollection()) {
        delete this.collection.settings.filters;
      }
      // remove relations if saved as a smart collection
      if (this.willOverrideStaticCollection()) {
        this.collection.files = [];
        this.collection.products = [];
      }

      // add filters
      if (this.filters) {
        if (!this.collection.settings) {
          this.collection.settings = {};
        }
        this.collection.settings.filters = this.filters;
      }
      this.upsertCollection(this.collection, 'Collection updated!', 'The collection was succesfully updated.');
    } else {
      const collection = <any>{
        name: this.collection,
        description: this.description,
      };

      if (this.selectedPhotos) {
        collection.files = this.selectedPhotos;
      }
      if (this.selectedProducts) {
        collection.products = this.selectedProducts;
      }
      if (this.filters) {
        collection.settings = { filters: this.filters };
        collection.collection_type = CollectionService.TYPE_PRODUCT;
      }

      this.upsertCollection(collection, 'Collection created!', 'The collection was succesfully created.');
    }
  }

  willOverrideStaticCollection() {
    return this.collection.id && this.filters && !get(this.collection, 'settings.filters');
  }

  willOverrideSmartCollection() {
    return this.collection.id && !this.filters && get(this.collection, 'settings.filters');
  }

  private upsertCollection(collection, title, message) {
    this.collectionService.upsert(collection).subscribe(() => {
      this.toasterService.pop('success', title, message);
      this.ngbActiveModal.close();
    });
  }

}
