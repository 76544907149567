import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { find } from 'lodash';
import { CameraSettings, getTransformedCameraSettings } from '@app/remote-control/device';
import { CameraPresetService } from '@app/core/camera-preset.service';

@Component({
  selector: 'app-camera-preset-manager',
  templateUrl: './camera-preset-manager.component.html',
  styleUrls: ['./camera-preset-manager.component.scss'],
})
export class CameraPresetManagerComponent implements OnInit {

  mode: string;
  settings: CameraSettings;
  name: string;
  overlay: number;
  onSelectPreset: Function;
  onSavePreset: Function;
  onDeletePreset: Function;

  presetColumns = [
    { name: 'id', label: 'ID', type: 'text' },
    { name: 'name', label: 'Name', type: 'text' },
  ];

  actions = [{ type: 'delete', icon: 'fa fa-trash', title: 'Delete preset' }];

  private presets;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private cameraPresetService: CameraPresetService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
  }

  isNameAvailable() {
    return this.name && !find(this.presets, ({ name }) => name.toLowerCase() === this.name.toLowerCase());
  }

  getSelectedPreset() {
    return this.presets.find(({ isSelected, id }) => id && isSelected);
  }

  canUpdate() {
    const selectedPreset = this.getSelectedPreset();

    return selectedPreset && selectedPreset.id && !selectedPreset.is_locked;
  }

  selectPreset(preset) {
    this.onSelectPreset(preset);
    this.ngbActiveModal.close();
  }

  presetActionHandler($event) {
    if ($event.action.type === 'delete') {
      $event.event.stopPropagation();
      this.delete($event.item);
    }
  }

  setPresets(presets) {
    this.presets = presets;
  }

  delete({ id, name, isSelected }) {
    if (window.confirm(`Are you sure you want to delete preset "${name}"?`)) {
      this.cameraPresetService.delete(id)
        .subscribe((preset) => {
          if (isSelected) {
            this.onSelectPreset({ id });
          }
          this.toasterService.pop('success', 'Preset deleted!', 'The preset has been successfully deleted.');
          this.presets = this.presets.filter(p => p.id !== id);
          this.onDeletePreset(this.presets);
        });
    }
  }

  save(id = null) {
    this.cameraPresetService.upsert({
      id,
      name: this.name,
      overlay: this.overlay,
      settings: getTransformedCameraSettings(this.settings),
    })
      .subscribe((result: any) => {
        const { body: preset } = result;
        this.toasterService.pop('success', 'Preset saved!', 'The preset has been successfully saved.');
        this.onSavePreset(preset);
        if (!id) {
          this.onSelectPreset(preset);
        }
        this.ngbActiveModal.close();
      });
  }

  cancel() {
    this.ngbActiveModal.close();
  }

}
