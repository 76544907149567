import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ItemListComponent } from '../item-list/item-list.component';
import { PhotoService } from '@app/photo/photo.service';

@Component({
  selector: 'app-overlay-selector',
  templateUrl: './overlay-selector.component.html',
  styleUrls: ['./overlay-selector.component.scss'],
})
export class OverlaySelectorComponent implements OnInit {
  private _overlayImage;
  private _isOverlayVisible;

  @Output() overlayImageChange = new EventEmitter<any>();
  @Output() isOverlayVisibleChange = new EventEmitter<any>();

  constructor(
    private ngbModal: NgbModal,
    private photoService: PhotoService,
  ) { }

  get overlayImage() {
    return this._overlayImage;
  }
  @Input()
  set overlayImage(img) {
    this._overlayImage = img;
    this.overlayImageChange.emit(img);
  }

  get isOverlayVisible() {
    return this._isOverlayVisible;
  }
  @Input()
  set isOverlayVisible(isVisible) {
    this._isOverlayVisible = isVisible;
    this.isOverlayVisibleChange.emit(isVisible);
  }

  ngOnInit() {
  }

  selectOverlay() {
    const modalRef = this.ngbModal.open(ItemListComponent);
    modalRef.componentInstance.onSelectItem.subscribe(photo => {
      this.isOverlayVisible = !this.overlayImage || this.overlayImage.id !== photo.id;
      this.overlayImage = this.isOverlayVisible ? photo : null;
      modalRef.close();
    });
    modalRef.componentInstance.isSelectable = true;
    modalRef.componentInstance.maxSelect = 1;
    modalRef.componentInstance.columns = [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'url', label: 'Image', type: 'image' },
    ];
    this.photoService.getPage().subscribe((response: any) => modalRef.componentInstance.items = response.body
      .map(photo => ({
        ...photo,
        isSelected: this.overlayImage && photo.id === this.overlayImage.id,
      })));
  }

}
