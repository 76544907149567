<div class="modal-body">
  <div class="row">
    <div class="col">

      <div class="form-group">
        <label for="ngb-typehead-name">Name</label>
        <input
          [(ngModel)]="collection"
          [ngbTypeahead]="collectionSearch"
          [resultFormatter]="collectionFormatter"
          [inputFormatter]="collectionFormatter"
          id="ngb-typehead-name"
          type="text"
          class="form-control"
          placeholder="Search for a collection or create a new one"
        />
      </div>

      <div *ngIf="willOverrideStaticCollection()" role="alert" class="alert alert-warning ng-star-inserted">
        <strong>Warning!</strong> You're about to override the content of the static collection <i>"{{ collection.name }}"</i>.
      </div>

      <div *ngIf="willOverrideSmartCollection()" role="alert" class="alert alert-warning ng-star-inserted">
        <strong>Warning!</strong> You're about to override the smart collection <i>"{{ collection.name }}"</i> with static content.
      </div>

      <div class="form-group">
        <label for="description-field">Description</label>
        <textarea
          *ngIf="collection && collection.id"
          [(ngModel)]="collection.description"
          class="form-control"
          id="description-field"
          placeholder="Description"
          disabled="disabled"
        ></textarea>

        <textarea
          *ngIf="!collection || !collection.id"
          [(ngModel)]="description"
          class="form-control"
          id="description-field"
          placeholder="Description"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="fluid-width no-padding">
    <div class="form-group pull-right">
      <button type="submit" class="btn btn-sm btn-secondary" (click)="cancel()"><i class="fa fa-close"></i> Cancel</button>
      <button type="submit" class="btn btn-sm btn-success" (click)="save()"><i class="fa fa-check"></i> Save</button>
    </div>
  </div>
</div>