import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { each, random, times } from 'lodash';
import { Logger } from 'angular2-logger/core';
import { Resolve, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { ToasterService } from 'angular2-toaster';

import { AuthService } from '@app/core/auth.service';
import Devices from './devices';
import Device from './device';

const Pusher = require('pusher-js');

export { Devices, Device };

const STORAGE_CLIENT_ID_KEY = 'remote-control-client-id';

@Injectable()
export class RemoteControlService {
  devices = new Devices();
  isReady = true;

  private channel;
  private socket;
  private selectedDeviceId: string;
  private clientId: string;
  private connectingPromise: Promise<any>;

  constructor(
    private authService: AuthService,
    private logger: Logger,
    private injector: Injector,
    private router: Router,
    private toasterService: ToasterService,
  ) {
    this.clientId = localStorage.getItem(STORAGE_CLIENT_ID_KEY);
    if (!this.clientId) {
      this.clientId = times(20, () => random(35).toString(36)).join('');
      localStorage.setItem(STORAGE_CLIENT_ID_KEY, this.clientId);
    }
  }

  connect(): Promise<any> {
    if (this.connectingPromise) {
      return this.connectingPromise;
    }

    if (this.socket) {
      return Promise.resolve(true);
    }

    this.isReady = false;
    this.connectingPromise = new Promise(async (resolve, reject) => {
      let basicInfo;
      try {
        basicInfo = await this.authService.me();
      } catch (error) {
        this.logger.error('Failed to get basicInfo for remote controller', error);
        reject(error);
        return;
      }

      const { cluster, appKey, channel: channelName } = basicInfo.pusher;
      this.socket = new Pusher(appKey, {
        cluster,
        encrypted: true,
        authEndpoint: '/api/pusher/auth',
        auth: {
          params: {
            uniqueId: this.clientId,
          },
          headers: { Authorization: this.authService.createAuthHeader().get('Authorization') },
        },
      });

      this.logger.debug('subscribe socket with channel', channelName, 'and id', this.clientId);

      this.channel = this.socket.subscribe(channelName);

      this.channel.bind('pusher:subscription_succeeded', ({ members }) => {
        this.logger.debug('subscribed', members);

        each(members, (info, id) => {
          this.addDevice(id, info);
        });

        this.isReady = true;
        resolve(true);
      });

      this.channel.bind('pusher:subscription_error', (status) => {
        this.logger.debug('subscription_error', status);

        if (status === 401) {
          this.toasterService.pop('error', 'Oops!', 'Failed to authorize socket connection');
          timer().subscribe(() => { this.router.navigate(['/login']); });
        }

        if (status === 403) {
          this.toasterService.pop('error', 'Oops!', 'Forbidden!');
        }

        reject();
      });

      this.channel.bind('pusher:member_added', ({ id, info }) => {
        this.logger.debug('member_added', id, info);
        this.addDevice(id, info);
      });

      this.channel.bind('pusher:member_removed', ({ id }) => {
        this.logger.debug('member_removed', id);
        this.removeDevice(id);
      });

      this.channel.bind(`client-${this.clientId}`, this.eventListener.bind(this));
      this.channel.bind('client-broadcast', this.eventListener.bind(this));
    }).then(() => {
      this.connectingPromise = null;
      return true;
    }).catch((error) => {
      this.disconnect();
      this.connectingPromise = null;
      throw error;
    });

    return this.connectingPromise;
  }

  disconnect() {
    this.logger.debug('disconnect remoteControlService');

    this.isReady = false;

    this.devices.clear();

    if (this.socket) {
      this.socket.allChannels().forEach(({ name }) => {
        this.socket.unsubscribe(name);
      });

      this.socket.disconnect();
    }

    this.socket = null;
  }

  isSelectedDevice(device: Device): boolean {
    return this.selectedDeviceId === device.id;
  }

  selectDevice(id: string = null): boolean {
    this.logger.debug('selectDevice', id);

    if (this.selectedDevice && id !== this.selectedDeviceId) {
      this.selectedDevice.disconnect();
    }

    this.selectedDeviceId = id;

    return !!this.selectedDevice;
  }

  get selectedDevice(): Device {
    return this.devices.has(this.selectedDeviceId) ? this.devices.get(this.selectedDeviceId) : null;
  }

  emit = (id: string, action: string, payload = null) => {
    this.logger.debug('emit', id, action, payload);
    const data = {
      senderId: this.clientId,
      action,
      payload,
    };
    this.channel.trigger(`client-${id}`, data);
  }

  getDevice(id: string): Device {
    return this.devices.get(id);
  }

  addDevice(id: string, { deviceName, infoUrl, isDevice }) {
    if (!isDevice) {
      this.logger.debug('ignore addDevice', id);
      return;
    }

    this.logger.debug('addDevice', id);

    const injector = Injector.create([
      { provide: Device,
        deps: [
          Logger,
          DomSanitizer,
        ],
      },
    ], this.injector);

    const device = injector.get(Device);
    device.init(id, deviceName, infoUrl, this.emit);

    this.devices.set(id, device);
  }

  removeDevice(id: string) {
    if (this.selectedDeviceId === id) {
      this.selectDevice(null);
    }

    if (this.devices.has(id)) {
      this.devices.delete(id);
    }
  }

  eventListener({ senderId, action, payload }) {
    this.logger.debug('Message received', senderId, action, payload);
    if (!this.devices.has(senderId)) {
      return;
    }

    const device = this.devices.get(senderId);

    if (action === 'exchange') {
      this.exchange(senderId, payload);
    }
    if (action === 'network-changed') {
      device.setInfoUrl(payload.infoUrl);
    }
    if (action === 'captured') {
      this.addPhoto(senderId, payload);
    }
    if (action === 'uploaded') {
      this.uploaded(senderId, payload);
    }
    if (action === 'upload-progress') {
      device.uploadProgress(payload);
    }
    if (action === 'settings') {
      device.setSettings(payload);
    }
    if (action === 'orientation') {
      device.setOrientation(payload.orientation);
    }
    if (action === 'disconnect') {
      device.disconnect();
    }
  }

  addPhoto(id: string, { success, photoId, data, errorMessage }) {
    const device = this.devices.get(id);
    if (success) {
      device.addPhoto(photoId, data);
    } else {
      device.addPhotoFailed(errorMessage);
    }
  }

  uploaded(id: string, { success, errorMessage, data }) {
    const device = this.devices.get(id);

    device.uploaded(success ? null : errorMessage, data);
  }

  exchange(senderId: string, { sdp, candidate }) {
    this.logger.debug('exchange received', senderId, sdp, candidate);

    const device = this.devices.get(senderId);
    if (sdp) {
      device.setRemoteDescription(sdp);
    } else {
      device.addIceCandidate(candidate);
    }
  }
}

@Injectable()
export class RemoteControlConnectionResolve implements Resolve<any> {

  constructor(
    private RemoteControlService: RemoteControlService,
  ) {}

  resolve() {
    return this.RemoteControlService.connect().catch(() => {
      // empty catch to avoid navigation errors
    });
  }
}
