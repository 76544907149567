/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./overlay-selector.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../photo/photo.service";
var styles_OverlaySelectorComponent = [i0.styles];
var RenderType_OverlaySelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverlaySelectorComponent, data: {} });
export { RenderType_OverlaySelectorComponent as RenderType_OverlaySelectorComponent };
function View_OverlaySelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "label", [["class", "btn-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.isOverlayVisible = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.isOverlayVisible; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_OverlaySelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "btn btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectOverlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-group-left": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-layers"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Overlay "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverlaySelectorComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-sm"; var currVal_1 = _ck(_v, 3, 0, _co.overlayImage); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.overlayImage; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_OverlaySelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overlay-selector", [], null, null, null, View_OverlaySelectorComponent_0, RenderType_OverlaySelectorComponent)), i1.ɵdid(1, 114688, null, 0, i4.OverlaySelectorComponent, [i5.NgbModal, i6.PhotoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverlaySelectorComponentNgFactory = i1.ɵccf("app-overlay-selector", i4.OverlaySelectorComponent, View_OverlaySelectorComponent_Host_0, { overlayImage: "overlayImage", isOverlayVisible: "isOverlayVisible" }, { overlayImageChange: "overlayImageChange", isOverlayVisibleChange: "isOverlayVisibleChange" }, []);
export { OverlaySelectorComponentNgFactory as OverlaySelectorComponentNgFactory };
