<table class="table" *ngIf="mode === 'list'">
  <thead>
    <tr>
      <th
        *ngIf="isSelectable"
        (click)="toggleAll()">
        <input type="checkbox" [checked]="areAllSelected" *ngIf="maxSelect > 1 || !maxSelect" />
      </th>
      <th *ngFor="let column of columns">{{ column.label }}</th>
      <th>
        <button *ngIf="!disableGrid" class="btn btn-sm btn-secondary pull-right" (click)="mode='grid'"><i class="icon-grid"></i></button>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="canSelectMore" class="alert-warning">
      <td (click)="toggleAllTotal(false)">
        <input type="checkbox" [checked]="selectAllTotal" />
      </td>
      <td class="select-rest" colspan="100">
        <span *ngIf="!selectAllTotal">
          All <b>{{items.length}}</b> items on this page are selected. <a href="#" (click)="toggleAllTotal(); false;">Select all <b>{{totalCount}}</b> items</a>.
        </span>
        <span *ngIf="selectAllTotal">
          All <b>{{totalCount}}</b> items are selected. <a href="#" (click)="toggleAllTotal(); false;">Clear selection</a>.
        </span>
      </td>
    </tr>
    <tr *ngFor="let item of items" (click)="openItem(item)">
      <td *ngIf="isSelectable" (click)="toggleIsSelected($event, item)">
        <input type="checkbox" [checked]="item.isSelected" />
      </td>
      <td *ngFor="let column of getColumns(item)" [title]="column.title || ''" [ngClass]="column.cssClass">
        <ng-container *ngTemplateOutlet="column.view;context:column"></ng-container>
      </td>
      <td>
        <div class="pull-right actions">
          <ng-container *ngFor="let action of actions">
            <a *ngIf="!action.tpl"
              class="btn btn-sm btn-secondary btn-fade"
              title="{{action.title}}"
              [ngClass]="action.class"
              (click)="onAction.emit({ event: $event, action: action, item: item })">
                <i [ngClass]="action.icon"></i>
            </a>
            <ng-container *ngIf="action.tpl">
              <ng-container *ngTemplateOutlet="action.tpl; context: { $implicit: item, templateVars: action.tplVars }"></ng-container>
            </ng-container>
          </ng-container>

        </div>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="mode === 'grid'">
  <div class="grid-header clearfix">
    <button class="btn btn-sm btn-secondary pull-right" (click)="mode='list'"><i class="icon-list"></i></button>
  </div>

  <div class="grid">
    <figure *ngFor="let item of items" (click)="openItem(item)">
      <figcaption>
        <div *ngFor="let column of getGridColumns(item)" [title]="column.title || ''" [ngClass]="column.cssClass">
          <ng-container *ngTemplateOutlet="column.view;context:column"></ng-container>
        </div>
      </figcaption>
    </figure>
  </div>
</div>

<ng-template #textColumn let-content="content">{{ content }}</ng-template>

<ng-template #imageColumn let-content="content">
  <app-fixed-ratio-img *ngIf="content"
    [src]="content | resizedImage"></app-fixed-ratio-img>

  <app-fixed-ratio-img *ngIf="!content"
    [src]="'assets/img/placeholder.svg'"
    [isCropped]="false"></app-fixed-ratio-img>
</ng-template>

<ng-template #badgeColumn let-content="content" let-settings="settings">
  <span
    *ngIf="content"
    class="badge"
    [ngClass]="settings.badge"
    [style.background-color]="settings.color"
    [style.color]="settings.textColor"
  >
    <i [ngClass]="settings.icon" *ngIf="settings.icon"></i>
    {{content}}
  </span>
</ng-template>

<ng-template #progressColumn let-content="content">
  <div class="progress">
    <div
      [style.width.%]="content"
      [ngClass]="{ 'bg-success': content === 100 }"
      class="progress-bar"
      role="progressbar"
      aria-valuenow="content"
      aria-valuemin="0"
      aria-valuemax="100">
        <span class="percentage-label">{{ content }}&nbsp;%</span>
    </div>
  </div>
</ng-template>
