import { Injectable, Inject, forwardRef, Injector  } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';


import { ToasterService } from 'angular2-toaster';
import { AuthService } from '@app/core/auth.service';


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    @Inject(forwardRef(() => Injector)) private injector: Injector,
    private logger: Logger,
    private toasterService: ToasterService,
    private router: Router,
  ) {

  }

  intercept(req: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    this.authService = this.injector.get(AuthService);
    this.logger.debug('Manage http request via AppHttpInterceptor', req.headers);

    return next.handle(this.cloneRequest(req))
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              return this.handle401Error(req, next);
          }

          return this.errorHandler(error);
        } else {
          return Observable.throw(error);
        }
      })) as any;
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      if (!this.authService.hasRefreshToken()) {
        this.logger.debug('Refresh token was not found, logout');
        return this.logoutUser();
      }

      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.requestNewAccessToken()
        .pipe(
          catchError(error => {
            // If there is an exception calling 'refreshToken', bad news so logout.
            this.logger.error('Failed to refresh token', error);
            return this.logoutUser();
          }),
          switchMap(newToken => this.handleNewToken(req, next, newToken)),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    }

    return this.tokenSubject
      .pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => this.retry(req, next))
      );
  }

  private retry(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(this.cloneRequest(req)).pipe(catchError(this.errorHandler));
  }

  private handleNewToken = (req: HttpRequest<any>, next: HttpHandler, newToken) => {
    this.logger.debug('New token received', newToken);

    if (!newToken || !newToken.token) {
      this.logger.debug('Invalid new token, logout');
      return this.logoutUser();
    }

    this.authService.accessToken = newToken.token;
    this.authService.accessTokenExpiration = newToken.expires_in;
    this.tokenSubject.next(newToken);
    return this.retry(req, next);
  }

  private cloneRequest(req: HttpRequest < any > ): HttpRequest < any > {
    let headers = this.authService.createAuthHeader(req);
    if (!headers.has('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }

    return req.clone({
      headers,
    });
  }

  private logoutUser() {
    this.logger.debug('Logout');
    this.authService.clearTokens();
    return Observable.create((observer) => {
      this.logger.debug('Navigate to login page');
      this.router.navigate(['/login']);
      observer.error(new Error('Unauthorized'));
    });
  }

  private errorHandler = (response: any) => {
    this.logger.error('http request error', response);

    if (response.status && response.status === 401) {
      this.logger.debug('logout with response status 401');
      return this.logoutUser();
    }

    // if response status is not 2xx
    if (!response.status || response.status > 299 || response.status < 200) {
      this.toasterService.pop('error', 'Oops!', /* response.json().message || */ 'Something went wrong!');
    }

    return Observable.throw('Something went wrong!');
  }
}
