<div class="form-group" (dblclick)="slider.value=slider.resetValue" [ngClass]="{ disabled: slider.isDisabled() }" *ngIf="!slider.isHidden()">
  <label>
    <i class="material-icons" *ngIf="slider.useMaterialIcon">{{slider.icon}}</i>
    <i [ngClass]="slider.icon"  *ngIf="!slider.useMaterialIcon"></i>
    <span class="mobile-hide">
      {{slider.name}}
      <button class="btn btn-sm advanced" *ngIf="slider.onShowAdvanced" (click)="showAdvanced($event)">
        <span class="fa fa-angle-right"></span>
      </button>
      <button class="btn btn-sm advanced" *ngIf="slider.onHideAdvanced" (click)="hideAdvanced($event)">
        <span class="fa fa-angle-down"></span>
      </button>
    </span>

    <span class="slider-value">
      <input
        #numberInput
        type="number"
        [disabled]="slider.isDisabled()"
        [min]="slider.min"
        [max]="slider.max"
        [placeholder]="slider.defaultValue | formatSliderValue: slider.format"
        [ngModel]="slider.value | formatSliderValue: slider.format"
        (ngModelChange)="onModelChange($event)"
        (focus)="onInputFocus($event)"
        (keydown)="onInputKeyPress($event)"
        (blur)="onInputBlur($event)"
        (dblclick)="$event.target.blur()"
      >
      <span class="slider-unit" *ngIf="slider.value !== null" [innerHTML]="slider.unit"></span>
    </span>
  </label>
  <input type="range"
    [disabled]="slider.isDisabled()"
    [min]="slider.min"
    [max]="slider.max"
    [step]="slider.step"
    [(ngModel)]="slider.value"
  >
</div>
