import { Injectable } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

@Injectable()
export class BasicInfoService {
  isMenuOpen;
  forceMobileMenu;
  toasterConfig;

  constructor() { }

  getIsMenuOpen() {
    return !!this.isMenuOpen;
  }

  setIsMenuOpen(newStatus) {
    this.isMenuOpen = newStatus;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  getForceMobileMenu() {
    return !!this.forceMobileMenu;
  }

  setForceMobileMenu(newStatus) {
    this.forceMobileMenu = newStatus;
  }

  getToasterConfig() {
    return this.toasterConfig;
  }

  setToasterConfig(conf) {
    this.toasterConfig = new ToasterConfig(conf);
  }
}
