<div class="card">
  <div class="toolbar">
    <div class="pull-left">
      <h1 class="pull-left title">Statistics</h1>
    </div>
    <div class="col col-buttons">
      <div class="form-group pull-right">
        <a class="btn btn-sm" target="_blank" [href]="downloadUrl()" (click)="export()">
          <i _ngcontent-c10="" class="fa fa-download ng-star-inserted" ng-reflect-klass="fa" ng-reflect-ng-class="fa-download"></i>
          Download
        </a>
      </div>
    </div>
  </div>

  <div class="row filters">

     <app-attribute-field
       [field]="{ type: 'date-time-range' }"
       [(ngModel)]="dateRange"
       (ngModelChange)="fetchStats()"
     ></app-attribute-field>
     <ng-multiselect-dropdown
       style="margin-top: 15pt;
       margin-left: 33pt;
      width: 200pt"
       [placeholder]="'Filter By User'"
       [settings]="dropdownSettings"
       [data]="dropdownList"
       [(ngModel)]="selectedItems"
       (onSelect)="onItemSelect($event)"
       (onSelectAll)="onSelectAll($event)"
       (onDeSelect)="onDeSelect($event)"
       (onDeSelectAll)="onDeSelectAll($event)"
     >
     </ng-multiselect-dropdown>

  </div>

  <app-item-list
    [items]="items"
    [columns]="columns"
    [disableGrid]="true"
  ></app-item-list>

  <div class="total-count">
    Total count: {{ getTotalCount() }}
  </div>
</div>
