import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { get } from 'lodash';
import { finalize } from 'rxjs/operators';

import { PhotoCropperComponent } from '../photo-cropper/photo-cropper.component';
import { PhotoService } from '@app/photo/photo.service';
import { UtilService } from '@app/core/util.service';
import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from '@app/remote-control/device';

@Component({
  selector: 'app-photo-post-production',
  templateUrl: './photo-post-production.component.html',
  styleUrls: ['./photo-post-production.component.scss'],
})
export class PhotoPostProductionComponent {
  @ViewChild(PhotoCropperComponent) cropperTool: PhotoCropperComponent;
  @Input() photo;
  @Input() rotation = 0;
  @Input() zoom = 0;
  @Input() aspectRatio;
  @Input() orientation;
  @Input() overlayImage;
  @Input() isOverlayVisible;
  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  isSaving = false;

  constructor(
    private photoService: PhotoService,
    private util: UtilService,
  ) { }
  setInitialZoom(photo){
    if(photo.meta && !photo.meta.deviceName){
      return true;
    }else{
      return false;
    }
  }
  setInitialValues(photo, settings: any = {}) {
    this.cropperTool.setInitialZoom=this.setInitialZoom(photo);
    const crop = get(photo, 'attribute_values.postProduction.crop') || get(photo, 'attribute_values.crop') || {};
    const cropW = crop.width;
    const cropH = crop.height;
    const overlay = settings.defaultOverlay;
    let orientation = settings.defaultOrientation;
    let aspect = settings.defaultAspectRatio;

    // use values from current crop instead of default values
    if (cropW && cropH) {
      if (cropH > cropW) {
        orientation = ORIENTATION_PORTRAIT;
      } else {
        orientation = ORIENTATION_LANDSCAPE;
      }

      if (!aspect) {
        // reduce the aspect ratio
        const [aspectW, aspectH] = this.util.reduceFraction(cropW, cropH, true);
        aspect = `${aspectW}:${aspectH}`;
      }
    }
    aspect = this.util.flipAspectRatioToOrientation(aspect, orientation);

    this.orientation = orientation;
    this.aspectRatio = aspect;
    if (overlay) {
      this.overlayImage = { url: overlay };
      this.isOverlayVisible = true;
    }
    this.rotation = get(photo, 'attribute_values.postProduction.crop.rotate', 0);
    this.photo = photo; // this contains the attribute_values which have the crop parameters
  }

  cancel() {
    this.onCancel.emit();
  }

  save() {
    const cropData = this.cropperTool.getData(true);
    this.isSaving = true;

    this.photoService.update({
      ...this.photo,
      attribute_values: {
        ...this.photo.attribute_values,
        crop: cropData, // depracated
        postProduction: {
          ...get(this.photo, 'attribute_values.postProduction'),
          crop: cropData
        },
      },
    })
    .pipe(finalize(() => { this.isSaving = false; }))
    .subscribe((response: any) => {
      this.onSave.emit(response);
    });
  }

}
