/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fixed-ratio-img.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./fixed-ratio-img.component";
var styles_FixedRatioImgComponent = [i0.styles];
var RenderType_FixedRatioImgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FixedRatioImgComponent, data: {} });
export { RenderType_FixedRatioImgComponent as RenderType_FixedRatioImgComponent };
function View_FixedRatioImgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [["class", "link-overlay"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.link, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_FixedRatioImgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "fixed-ratio"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "cropped": 0, "scaled": 1 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0, "padding-bottom": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FixedRatioImgComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fixed-ratio"; var currVal_1 = _ck(_v, 2, 0, _co.isCropped, !_co.isCropped); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, (("url('" + _co.src) + "')"), (_co.bottomPad + "%")); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.link; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_FixedRatioImgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fixed-ratio-img", [], null, null, null, View_FixedRatioImgComponent_0, RenderType_FixedRatioImgComponent)), i1.ɵdid(1, 114688, null, 0, i3.FixedRatioImgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FixedRatioImgComponentNgFactory = i1.ɵccf("app-fixed-ratio-img", i3.FixedRatioImgComponent, View_FixedRatioImgComponent_Host_0, { src: "src", ratio: "ratio", isCropped: "isCropped", link: "link" }, {}, ["*"]);
export { FixedRatioImgComponentNgFactory as FixedRatioImgComponentNgFactory };
