<div ngbDropdown class="d-inline-block">
  <button
    [disabled]="selectedCount < 1"
    class="btn btn-sm btn-primary"
    id="batchdropdown"
    ngbDropdownToggle
  ><i class="fa fa-wrench"></i> Batch actions <span *ngIf="selectedCount >= 1" class="badge badge-pill badge-success">{{ selectedCount }}</span></button>
  <div ngbDropdownMenu aria-labelledby="batchdropdown">
    <ng-container *ngFor="let action of actions">
      <a
        class="dropdown-item"
        [ngClass]="btnClass(action)"
        [href]="action.href"
        (click)="actionClick($event, action)"
        target="_blank"
      >
        <i class="fa" *ngIf="action.icon" [ngClass]="actionIconClass(action)"></i>
        {{action.label}}
      </a>
    </ng-container>
  </div>
</div>
