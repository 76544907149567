import { Component, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Logger } from 'angular2-logger/core';
import { Observable, timer, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';


import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE_RIGHT,
} from '../remote-control/device';
import { UtilService } from '@app/core/util.service';

interface FocusPointer {
  'top.px'?: number;
  'left.px'?: number;
  'display'?: string;
}

@Component({
  selector: 'app-remote-video',
  templateUrl: './remote-video.component.html',
  styleUrls: ['./remote-video.component.scss'],
})

export class RemoteVideoComponent implements AfterViewInit, OnDestroy {
  @Input() isCapturing: boolean;
  @Input() parentElement: ElementRef;
  @Input() videoSrc: any;
  @Input() setFocusPoint: Function;
  @Input() orientation: string;
  @Input() overlay: string;
  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild('focusPointElement') focusPointElement: ElementRef;
  showVideo = false;
  isInitialized = false;
  focusPointer: FocusPointer = {};
  focusPointValue: Array<number>;
  wrapperStyles = {};
  isFull = false;

  private _aspectRatio: string;

  constructor(
    private logger: Logger,
    private elRef: ElementRef,
    private util: UtilService,
  ) { }

  ngAfterViewInit() {
    timer().subscribe(() => {
      this.showVideo = true;
      interval(200).pipe(takeWhile(() => !this.isVideoElementReady && this.showVideo)).subscribe(() => {
        this.logger.debug('video element is not ready yet...');
        this.updateSize();
      }, null, () => {
        this.logger.debug('video element is ready!');
        this.isInitialized = true;
        this.updateSize(); // final ensure
      });
    });
  }

  ngOnDestroy() {
    this.showVideo = false;
  }

  get size() {
    const { offsetWidth, offsetHeight } = this.videoElement.nativeElement;

    return {
      width: offsetWidth,
      height: offsetHeight,
    };
  }

  get aspectRatio(): string {
    return this._aspectRatio;
  }

  @Input()
  set aspectRatio(aspectRatio: string) {
    this._aspectRatio = aspectRatio;
    this.updateSize();
  }

  get focusPoint(): Array<number> {
    return this.focusPointValue;
  }

  @Input()
  set focusPoint(focusPoint: Array<number>) {
    this.focusPointValue = focusPoint;
    timer().subscribe(() => this.setFocusPointer());
  }

  get isVideoElementReady(): boolean {
    return !!this.videoSrc;
  }

  get shouldShowLoadingOverlay(): boolean {
    return this.isCapturing || !this.isVideoElementReady;
  }

  setFocusPointer() {
    if (!this.focusPointElement || !this.showVideo || !this.focusPoint) {
      return;
    }

    const { offsetWidth, offsetHeight } = this.focusPointElement.nativeElement;
    const { width: videoWidth, height: videoHeight } = this.size;
    const [ pointX, pointY ] = this.focusPoint;
    let [ x, y ] = this.focusPoint;

    // coordinate system is always relative to a landscape device orientation with
    // the home button on the right, regardless of the actual device orientation
    if (this.orientation === ORIENTATION_LANDSCAPE_RIGHT) {
      x = 1 - pointX;
      y = 1 - pointY;
    }
    if (this.orientation === ORIENTATION_PORTRAIT) {
      x = 1 - pointY;
      y = 1 - pointX;
    }

    const left = (x * videoWidth) - (offsetWidth / 2);
    const top = (y * videoHeight) - (offsetHeight / 2);

    this.logger.debug('current focusPoint', left, top, pointX, pointY, x, y, videoWidth, videoHeight, this.orientation);
    this.logger.debug('pointX, pointY', pointX, pointY);
    this.logger.debug('x, y', x, y);
    this.logger.debug('left, top', left, top);
    this.logger.debug('videoSize', videoWidth, videoHeight);

    this.focusPointer = {
      'left.px': left,
      'top.px': top,
      display: 'block',
    };
  }

  resetFocusPoint() {
    this.setFocusPoint();
  }

  videoClicked(evt) {
    if (!this.isVideoElementReady) {
      return;
    }

    const { layerX: x, layerY: y } = evt;
    const { offsetWidth, offsetHeight } = this.videoElement.nativeElement;
    const pointX = x / offsetWidth;
    const pointY = y / offsetHeight;

    this.logger.debug('Video clicked', x, y, offsetWidth, offsetHeight, pointX, pointY);
    this.setFocusPoint(pointX, pointY);
  }

  private updateSize() {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    const { offsetWidth, offsetHeight } = this.elRef.nativeElement;

    if (!offsetHeight) {
      return;
    }

    this.isFull = this.aspectRatio === 'FULL';
    const maxWidth = offsetWidth;
    const maxHeight = offsetHeight;
    let elementWidth = maxWidth;
    let elementHeight = maxHeight;
    const maxNumericAspect = maxWidth !== 0 ? maxWidth / maxHeight : 1;

    if (!this.isFull) {
      const numericAspect = this.util.getNumericAspect(this.aspectRatio);

      // if element is wider than selected aspect ratio match height
      if (numericAspect < maxNumericAspect) {
        elementWidth = maxHeight * numericAspect;
        elementHeight = maxHeight;
      } else { // if element is taller than selected aspect ratio match width
        elementHeight = maxWidth / numericAspect;
        elementWidth = maxWidth;
      }

      this.logger.debug('aspect ratio cal', maxWidth, maxHeight, elementWidth, elementHeight, this.aspectRatio);
    }

    this.wrapperStyles = {
      'width.px': Math.round(elementWidth),
      'height.px': Math.round(elementHeight),
    };

    if (this.focusPointer) {
      this.focusPointer.display = 'none';
    }

    timer(100).subscribe(() => {
      this.setFocusPointer();
    });
  }

  aspectToLisp(str) {
    return str.replace(':', '-');
  }
}
