import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Slider, SliderInterface } from '../slider/slider';

@Component({
  selector: 'app-rotation-slider',
  templateUrl: './rotation-slider.component.html',
  styleUrls: ['./rotation-slider.component.scss'],
})
export class RotationSliderComponent implements OnInit {
  rotationSlider: Slider;
  rotationDevice = {
    settings: {
      rotation: 0,
      defaultSettings: { rotation: {
        step: 0.1,
        minimumValue: -180,
        maximumValue: 180,
      } },
    },
    setRotation: (angle) => {
      this.rotation = angle;
    },
  };
  @Output() rotationChange = new EventEmitter<number>();

  private _rotation = 0;

  constructor(private domSanitizer: DomSanitizer) { }

  @Input()
  set rotation(angle) {
    // set rotation in 360 format
    this._rotation = angle < 0 ? angle + 360 : angle;
    // show rotation in -180 - 180 format
    this.rotationDevice.settings.rotation = angle > 180 ? angle - 360 : angle;
    this.rotationChange.emit(this._rotation);
  }
  get rotation() {
    return this._rotation;
  }

  ngOnInit() {
    this.rotationSlider = new Slider('rotation', this.rotationDevice, <SliderInterface>{
      name: 'Rotation',
      icon: 'fa fa-rotate-right',
      unit: this.domSanitizer.bypassSecurityTrustHtml('&deg;'),
      fixed: 1,
    });
  }
}
