/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../fixed-ratio-img/fixed-ratio-img.component.ngfactory";
import * as i4 from "../fixed-ratio-img/fixed-ratio-img.component";
import * as i5 from "../resized-image.pipe";
import * as i6 from "./item-list.component";
import * as i7 from "angular2-toaster/src/toaster.service";
import * as i8 from "@angular/router";
import * as i9 from "../../core/util.service";
import * as i10 from "@angular/platform-browser";
var styles_ItemListComponent = [i0.styles];
var RenderType_ItemListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemListComponent, data: {} });
export { RenderType_ItemListComponent as RenderType_ItemListComponent };
function View_ItemListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.areAllSelected; _ck(_v, 0, 0, currVal_0); }); }
function View_ItemListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "th", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.maxSelect > 1) || !_co.maxSelect); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_ItemListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm btn-secondary pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.mode = "grid") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-grid"]], null, null, null, null, null))], null, null); }
function View_ItemListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" items on this page are selected. "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.toggleAllTotal();
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Select all "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" items"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.totalCount; _ck(_v, 8, 0, currVal_1); }); }
function View_ItemListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" items are selected. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.toggleAllTotal();
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear selection"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalCount; _ck(_v, 3, 0, currVal_0); }); }
function View_ItemListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [["class", "alert-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAllTotal(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "td", [["class", "select-rest"], ["colspan", "100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_8)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.selectAllTotal; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.selectAllTotal; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectAllTotal; _ck(_v, 2, 0, currVal_0); }); }
function View_ItemListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleIsSelected($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.isSelected; _ck(_v, 1, 0, currVal_0); }); }
function View_ItemListComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ItemListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_12)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.cssClass; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; var currVal_3 = _v.context.$implicit.view; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.title || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ItemListComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "btn btn-sm btn-secondary btn-fade"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAction.emit({ event: $event, action: _v.parent.context.$implicit, item: _v.parent.parent.context.$implicit }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_1 = "btn btn-sm btn-secondary btn-fade"; var currVal_2 = _v.parent.context.$implicit.class; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.icon; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.title, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ItemListComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ItemListComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ItemListComponent_16)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { $implicit: 0, templateVars: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.parent.context.$implicit, _v.parent.context.$implicit.tplVars); var currVal_1 = _v.parent.context.$implicit.tpl; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ItemListComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_15)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.tpl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.tpl; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ItemListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_11)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "pull-right actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_13)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelectable; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getColumns(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.actions; _ck(_v, 8, 0, currVal_2); }, null); }
function View_ItemListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_6)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_9)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelectable; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.columns; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.disableGrid; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.canSelectMore; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.items; _ck(_v, 14, 0, currVal_4); }, null); }
function View_ItemListComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ItemListComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_20)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.cssClass; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; var currVal_3 = _v.context.$implicit.view; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.title || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ItemListComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "figure", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "figcaption", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_19)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getGridColumns(_v.context.$implicit); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ItemListComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "grid-header clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-sm btn-secondary pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.mode = "list") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_18)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 6, 0, currVal_0); }, null); }
function View_ItemListComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.content; _ck(_v, 0, 0, currVal_0); }); }
function View_ItemListComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-fixed-ratio-img", [], null, null, null, i3.View_FixedRatioImgComponent_0, i3.RenderType_FixedRatioImgComponent)), i1.ɵdid(1, 114688, null, 0, i4.FixedRatioImgComponent, [], { src: [0, "src"] }, null), i1.ɵppd(2, 1)], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.content)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemListComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fixed-ratio-img", [], null, null, null, i3.View_FixedRatioImgComponent_0, i3.RenderType_FixedRatioImgComponent)), i1.ɵdid(1, 114688, null, 0, i4.FixedRatioImgComponent, [], { src: [0, "src"], isCropped: [1, "isCropped"] }, null)], function (_ck, _v) { var currVal_0 = "assets/img/placeholder.svg"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ItemListComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_23)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_24)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.content; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_v.context.content; _ck(_v, 3, 0, currVal_1); }, null); }
function View_ItemListComponent_27(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.settings.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemListComponent_26(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "badge"]], [[4, "background-color", null], [4, "color", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_27)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = "badge"; var currVal_3 = _v.parent.context.settings.badge; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.settings.icon; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.settings.color; var currVal_1 = _v.parent.context.settings.textColor; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.context.content; _ck(_v, 4, 0, currVal_5); }); }
function View_ItemListComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_26)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.content; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemListComponent_28(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["aria-valuenow", "content"], ["class", "progress-bar"], ["role", "progressbar"]], [[4, "width", "%"]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "bg-success": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "percentage-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", "\u00A0%"]))], function (_ck, _v) { var currVal_1 = "progress-bar"; var currVal_2 = _ck(_v, 3, 0, (_v.context.content === 100)); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.content; _ck(_v, 1, 0, currVal_0); var currVal_3 = _v.context.content; _ck(_v, 5, 0, currVal_3); }); }
export function View_ItemListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.ResizedImagePipe, []), i1.ɵqud(402653184, 1, { textColumn: 0 }), i1.ɵqud(402653184, 2, { imageColumn: 0 }), i1.ɵqud(402653184, 3, { progressColumn: 0 }), i1.ɵqud(402653184, 4, { badgeColumn: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemListComponent_17)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["textColumn", 2]], null, 0, null, View_ItemListComponent_21)), (_l()(), i1.ɵand(0, [[2, 2], ["imageColumn", 2]], null, 0, null, View_ItemListComponent_22)), (_l()(), i1.ɵand(0, [[4, 2], ["badgeColumn", 2]], null, 0, null, View_ItemListComponent_25)), (_l()(), i1.ɵand(0, [[3, 2], ["progressColumn", 2]], null, 0, null, View_ItemListComponent_28))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode === "list"); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.mode === "grid"); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ItemListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-item-list", [], null, null, null, View_ItemListComponent_0, RenderType_ItemListComponent)), i1.ɵprd(512, null, i2.DatePipe, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 114688, null, 0, i6.ItemListComponent, [i7.ToasterService, i8.Router, i9.UtilService, i10.DomSanitizer, i2.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ItemListComponentNgFactory = i1.ɵccf("app-item-list", i6.ItemListComponent, View_ItemListComponent_Host_0, { columns: "columns", maxSelect: "maxSelect", items: "items", mode: "mode", isSelectable: "isSelectable", disableGrid: "disableGrid", actions: "actions", totalCount: "totalCount" }, { onSelectItem: "onSelectItem", onAction: "onAction" }, []);
export { ItemListComponentNgFactory as ItemListComponentNgFactory };
