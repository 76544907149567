<div class="modal-body">
  <div class="row">
    <div class="col">

      <div class="form-group">
        <label for="name-field">Name</label>
        <input class="form-control" id="name-field" [(ngModel)]="editor.collection.name" placeholder="Enter the name of the collection">
      </div>

    </div>
    <div class="col">

      <div class="form-group">
        <label>Visibility</label><br>
        <input type="checkbox" id="isPublic" [(ngModel)]="editor.collection.is_public"> <label [ngClass]="{ 'is-public': editor.collection.is_public, 'is-not-public': !editor.collection.is_public }" for="isPublic">Public</label>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col">

      <div class="form-group">
        <label for="description-field">Description</label>
        <textarea class="form-control" id="description-field" [(ngModel)]="editor.collection.description" placeholder="Description"></textarea>
      </div>

      <div class="form-group">
        <label>Public link</label>
        <div class="input-group">
          <input #publicLink
            class="form-control"
            [value]="editor.collection.is_public ? getPublicLink(collection) : 'No public link'"
            readonly>
          <span class="input-group-btn">
            <button class="btn btn-sm btn-secondary"
              title="Copy to clipboard"
              [ngxClipboard]="publicLink"
              (cbOnSuccess)="copiedToClipboard()"><i class="fa fa-clipboard"></i> Copy</button>
          </span>
        </div>
      </div>

    </div>
  </div>

  <div class="row" *ngIf="isSmartCollection()">
    <div class="col">
      <p>
        <a class="btn btn-small btn-secondary"
          [routerLink]="getFilterLink(collection)"
          (click)="cancel()"><i class="fa fa-filter"></i> view smart collection filters</a>
      </p>
    </div>
  </div>

  <div class="row" *ngIf="items && items.length">
    <div class="col">
      <div class="form-group">
        <label>{{isPhotoCollection() ? 'Photos' : 'Products'}}</label>
      </div>
      <app-item-list
        [items]="items"
        [columns]="columns"
        [actions]="actions"
        (onAction)="actionHandler($event)"></app-item-list>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container-fluid no-padding">
    <div class="form-group pull-left" *ngIf="editor.collection.id">
      <a class="btn btn-sm btn-secondary"
        [href]="'/api/collection/' + editor.collection.id + '/export/'"
        (click)="export($event)"
        download>
        <i class="fa fa-download"></i> Download
      </a>
    </div>
    <app-modal-save-buttons
      (save)="save($event)"
      (cancel)="cancel()"
      [isClosing]="isClosing"
      [isSaving]="isSaving"
    ></app-modal-save-buttons>
  </div>
</div>
