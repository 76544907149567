<div class="row" *ngIf="mode === 'save'">
  <div *ngIf="canUpdate()" class="col">
    <div class="modal-header">
       <h4 class="modal-title">Update preset</h4>
    </div>
    <div class="modal-body">
      <p>Update existing preset <b>{{getSelectedPreset().name}}</b> or create new.</p>
      <button class="btn btn-success" (click)="save(getSelectedPreset().id)">
        <i class="fa fa-edit"></i>
        Update preset
      </button>
    </div>
  </div>
  <div class="col">
    <div class="modal-header">
       <h4 class="modal-title">Create new preset</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="name-field">Name of the new preset</label>
        <input class="form-control" id="name-field" [(ngModel)]="name" placeholder="Enter the name of the preset">
        <small *ngIf="!isNameAvailable() && name" class="form-text text-muted">Preset name already in use please choose another.</small>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-sm btn-success" (click)="save()" *ngIf="mode === 'save'" [disabled]="!isNameAvailable()"><i class="fa fa-check"></i> Create new preset</button>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="mode === 'select'">
  <div class="col">
    <div class="form-group">
      <label>Presets</label>
      <app-item-list
        [maxSelect]="1"
        [isSelectable]="true"
        (onSelectItem)="selectPreset($event)"
        [disableGrid]="true"
        [items]="presets"
        [columns]="presetColumns"
        [actions]="actions"
        (onAction)="presetActionHandler($event)"></app-item-list>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="form-group pull-right">
    <button type="submit" class="btn btn-sm btn-secondary" (click)="cancel()"><i class="fa fa-close"></i> {{mode === 'save' ? 'Cancel' : 'Close'}}</button>
  </div>
</div>
