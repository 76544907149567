<app-pagination-list-view
  #listView
  title="Products"
  [service]="productService"
  [(items)]="products"
  [filters]="getFilterFields()"
  [itemList]="itemList"
  [isFiltersOpen]="isFiltersOpen"
  [totalPhotosForProcessing]="countProcessingPhotos"
  (saveAsCollection)="saveAsCollection($event)"
>
  <ng-container header-buttons>
    <app-batch-actions-dropdown
      [selectedCount]="selectedCount()"
      [actions]="batchActions()"
    ></app-batch-actions-dropdown>

    <button class="btn btn-sm btn-success btn-create-product" (click)="openProduct({})">
      <i class="fa fa-plus"></i>
      <span class="mobile-hide">Create</span>
    </button>
  </ng-container>
</app-pagination-list-view>

<ng-template #confirmDeleteModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title">Confirm delete</h2>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="deletingProduct.id">
    <p>Are you sure you want to delete <b>{{deletingProduct.sku}}</b> product?</p>
    <label *ngIf="deletingProduct.files && deletingProduct.files.length">
      <input type="checkbox" [(ngModel)]="deleteProductPhotos" /> Delete also photos ({{deletingProduct.files.length}})
    </label>
  </div>
  <div class="modal-body" *ngIf="!deletingProduct.id">
    <p>Are you sure you want to delete <b>{{deletingProduct.count}}</b> products?</p>
    <label>
      <input type="checkbox" [(ngModel)]="deleteProductPhotos" /> Delete also all photos linked to these products
    </label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="d()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="c()">Yes, delete</button>
  </div>
</ng-template>
