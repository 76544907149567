import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { pick } from 'lodash';
import Handlebars from 'handlebars';
import { BaseApiService } from '@app/core/base-api.service';
export class EmailService extends BaseApiService {
    constructor() {
        super(...arguments);
        this.modelEndpoint = 'api/emailtemplate';
        this.sendEndpoint = 'api/emailer/send';
        this.templates = null;
        this.messageFields = ['to_name', 'to_email', 'from_name', 'from_email', 'subject', 'content'];
    }
    loadTemplates() {
        if (this.templates !== null) {
            return of(this.templates);
        }
        return this.authService.get(this.modelEndpoint)
            .pipe(map((response) => {
            this.templates = response.body.map((template) => (Object.assign({}, template, { content: Handlebars.compile(template.content || ''), subject: Handlebars.compile(template.subject || '') })));
            return this.templates;
        }));
    }
    send(message) {
        return this.authService
            .post(this.sendEndpoint, this.pickMessageFields(message))
            .pipe(map((response) => response.body));
    }
    sendTemplate(id, message) {
        return this.authService
            .post(`${this.sendEndpoint}/${id}`, Object.assign({}, this.pickMessageFields(message), { params: message.params || {} }))
            .pipe(map((response) => response.body));
    }
    pickMessageFields(message) {
        return pick(message, this.messageFields);
    }
}
