import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { ToasterService } from 'angular2-toaster';
import { map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { BaseApiService } from '@app/core/base-api.service';
import { UtilService } from '@app/core/util.service';

@Injectable()
export class ProductService extends BaseApiService {
  constructor(
    authService: AuthService,
    util: UtilService,
    private toasterService: ToasterService,
  ) {
    super(authService, util);
    this.modelEndpoint = 'api/product';
  }

  delete(id, imageIds = null) {
    return super.delete(id, imageIds && imageIds.length ? imageIds.join(',') : null);
  }

  upsert(prod) {
    // only update certain fields
    const product = <any>pick(prod, ['id', 'sku', 'slug', 'attribute_values', 'item_type_id']);
    product.filesOrder = prod.files ? prod.files.map(({ id }) => id) : [];

    return super.upsert(product);
  }

  getAllUsersByOrganization(){
    return  this.authService.getAllUsersByOrganization();
  }

  countProcessingPhotos(){
    return this.authService.get(`api/product/count-processing-photos`)
      .pipe(map((res:any)=>res.body));
  }
  search(term: string) {
    return this.authService.get(`api/product/all?search=${term}`)
      .pipe(map((res: any) => res.body));
  }

  searchTag(field: string, term: string) {
    return this.authService.get(`api/product/tag/${field}?search=${term}`)
      .pipe(map((res: any) => res.body));
  }

  batchEdit(productData, ids) {
    return this.authService.put('api/product/batch', {
      productData,
      ids,
    });
  }

  batchCreate(productData) {
    return this.authService.post('api/product/batch', {
      productData,
    });
  }

  batchDelete(filters, deleteFiles = false) {
    return super.batchDelete(filters, { deleteFiles });
  }
}

// product resolver for routes
import { Resolve } from '@angular/router';

@Injectable()
export class ProductResolve implements Resolve<any> {

  constructor(private productService: ProductService) {}

  resolve() {
    return this.productService.getPage();
  }
}
