<div *ngIf="isError; else connectedTemplate" class="alert alert-danger" role="alert">
  <h4 class="alert-heading">Remote control</h4>
  <div>
    Can't establish socket connection.
  </div>
  <hr>
  <button class="btn btn-sm btn-primary" (click)="retry()">
    <i class="fa fa-refresh"></i>
    <span class="mobile-hide">Retry</span>
  </button>
</div>

<ng-template #connectedTemplate>
  <app-remote-control-device-list
    *ngIf="!device"
    [devices]="devices"
  ></app-remote-control-device-list>

  <app-remote-control-device
    *ngIf="device"
    [device]="device"
  ></app-remote-control-device>
</ng-template>
