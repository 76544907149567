<div class="remote-video-wrap" [ngStyle]="wrapperStyles" [ngClass]="{ 'aspect-FULL': isFull }">
  <div class="remote-video-wrapper">
    <div class="remote-video-wrapper-overflow">
      <div class="video-overlay" *ngIf="shouldShowLoadingOverlay">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i>
      </div>

      <div class="video-overlay" *ngIf="!isInitialized">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i>
      </div>

      <div #focusPointElement *ngIf="focusPoint && showVideo" class="focus-point" [ngStyle]="focusPointer" (click)="resetFocusPoint()"></div>

      <video
        #videoElement
        *ngIf="showVideo"
        autoplay
        preload="None"
        (click)="videoClicked($event)"
        [srcObject]="videoSrc"
        class="remote-video"></video>
      <ng-content></ng-content>

      <div class="overlay-img"
      *ngIf="overlay"
      [ngStyle]="{'background-image': 'url(' + overlay + ')'}"></div>
    </div>
  </div>
</div>
