import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOG_LOGGER_PROVIDERS } from 'angular2-logger/app/core/providers';
import { ToasterModule, ToasterService } from 'angular2-toaster';

// injectables
import { AppHttpInterceptor } from './app-http-interceptor';

// services
import { AuthService, BasicInfoResolve } from './auth.service';
import { BasicInfoService } from './basic-info.service';
import { UtilService } from './util.service';
import { EmailService } from './email.service';
import { ItemTypeService } from './item-type.service';
import { CameraPresetService } from './camera-preset.service';

// components
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ToasterModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  declarations: [
    DashboardComponent
  ],
  providers: [
    AuthService,
    BasicInfoResolve,
    BasicInfoService,
    UtilService,
    ToasterService,
    EmailService,
    ItemTypeService,
    LOG_LOGGER_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true ,
    }
  ],
  exports: [
    CommonModule,
    FormsModule,
    ToasterModule,
    DashboardComponent,
  ],
})
export class CoreModule {}