/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-save-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-save-buttons.component";
var styles_ModalSaveButtonsComponent = [i0.styles];
var RenderType_ModalSaveButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalSaveButtonsComponent, data: {} });
export { RenderType_ModalSaveButtonsComponent as RenderType_ModalSaveButtonsComponent };
function View_ModalSaveButtonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-sm btn-success"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "fa-check": 0, "fa-spinner fa-spin": 1 }), (_l()(), i1.ɵted(-1, null, [" Save and close "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa"; var currVal_1 = _ck(_v, 3, 0, (!_co.isSaving || !_co.isClosing), (_co.isSaving && _co.isClosing)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ModalSaveButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "form-group pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-sm btn-secondary"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["class", "btn btn-sm btn-success"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "fa-check": 0, "fa-spinner fa-spin": 1 }), (_l()(), i1.ɵted(-1, null, [" Save "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalSaveButtonsComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fa"; var currVal_2 = _ck(_v, 7, 0, (!_co.isSaving || _co.isClosing), (_co.isSaving && !_co.isClosing)); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.enableSaveAndClose; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSaving; _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalSaveButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-save-buttons", [], null, null, null, View_ModalSaveButtonsComponent_0, RenderType_ModalSaveButtonsComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalSaveButtonsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalSaveButtonsComponentNgFactory = i1.ɵccf("app-modal-save-buttons", i3.ModalSaveButtonsComponent, View_ModalSaveButtonsComponent_Host_0, { isSaving: "isSaving", isClosing: "isClosing", enableSaveAndClose: "enableSaveAndClose" }, { save: "save", cancel: "cancel" }, []);
export { ModalSaveButtonsComponentNgFactory as ModalSaveButtonsComponentNgFactory };
