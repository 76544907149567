/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batch-actions-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./batch-actions-dropdown.component";
var styles_BatchActionsDropdownComponent = [i0.styles];
var RenderType_BatchActionsDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchActionsDropdownComponent, data: {} });
export { RenderType_BatchActionsDropdownComponent as RenderType_BatchActionsDropdownComponent };
function View_BatchActionsDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-pill badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedCount; _ck(_v, 1, 0, currVal_0); }); }
function View_BatchActionsDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa"; var currVal_1 = _co.actionIconClass(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BatchActionsDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "dropdown-item"], ["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.actionClick($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchActionsDropdownComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "dropdown-item"; var currVal_2 = _co.btnClass(_v.context.$implicit); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; _ck(_v, 1, 0, currVal_0); var currVal_4 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
export function View_BatchActionsDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 737280, null, 3, i3.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i3.ɵm]], null, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _menuElement: 0 }), i1.ɵqud(335544320, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "button", [["aria-haspopup", "true"], ["class", "btn btn-sm btn-primary dropdown-toggle"], ["id", "batchdropdown"], ["ngbDropdownToggle", ""]], [[8, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-wrench"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Batch actions "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchActionsDropdownComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "batchdropdown"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 13).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 13).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, [[1, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchActionsDropdownComponent_2)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = (_co.selectedCount >= 1); _ck(_v, 11, 0, currVal_3); var currVal_7 = _co.actions; _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.selectedCount < 1); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 13).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 13).placement; _ck(_v, 12, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_BatchActionsDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-batch-actions-dropdown", [], null, null, null, View_BatchActionsDropdownComponent_0, RenderType_BatchActionsDropdownComponent)), i1.ɵdid(1, 114688, null, 0, i4.BatchActionsDropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchActionsDropdownComponentNgFactory = i1.ɵccf("app-batch-actions-dropdown", i4.BatchActionsDropdownComponent, View_BatchActionsDropdownComponent_Host_0, { selectedCount: "selectedCount", actions: "actions" }, {}, []);
export { BatchActionsDropdownComponentNgFactory as BatchActionsDropdownComponentNgFactory };
