<div class="form-group">
  <label *ngIf="showLabel">{{ field.title }}</label>

  <select class="form-control"
    *ngIf="field.input_element === 'select'"
    [(ngModel)]="value"
    (ngModelChange)="propagateChange($event)"
    [disabled]="isReadOnly">
    <option [ngValue]="null">Select</option>
    <option *ngFor="let option of get(field, 'settings.options')" [ngValue]="option.id || option">{{ option.name || option }}</option>
  </select>

  <div class="multi-select" *ngIf="field.input_element === 'multi-select'">
    <label *ngFor="let option of get(field, 'settings.options')">
      <input
        type="checkbox"
        (change)="changeMultiSelectOption($event, option.id || option)" [checked]="isCheckedOption(option)"
        [readonly]="isReadOnly">
      {{ option.name || option }}
    </label>
  </div>

  <div class="col-md-9" *ngIf="field.type === 'boolean'">
    <label class="checkbox-inline">
      <input
          [(ngModel)]="value"
          (ngModelChange)="propagateChange($event)"
          type="checkbox"
          placeholder=""
          [readonly]="isReadOnly">

      {{ field.label }}
    </label>
  </div>

  <div *ngIf="field.input_element === 'text'" class="text-row">
    <input class="form-control"
    #textField
    [(ngModel)]="value"
    (ngModelChange)="propagateChange($event)"
    type="text"
    placeholder="{{ field.placeholder || field.title }}"
    (keydown)="keydown($event)"
    [readonly]="isReadOnly">

    <button *ngIf="copyButton" class="copy-button" (click)="copyText($event)">{{ copyButtonText }}</button>
  </div>


  <div class="tag-group" *ngIf="field.input_element === 'product-tag'">
    <div class="input-row">
      <input type="text" class="form-control" placeholder="Add a tag"
        #productTagInput
        (selectItem)="onProductTagSelect($event, productTagInput)"
        (keyup)="onProductTagInputKeyUp($event, productTagInput)"
        [(ngModel)]="productTagToAdd"
        [ngbTypeahead]="productTagSearch.bind(this, field.slug)"
        [editable]="false">
      <button class="btn btn-secondary btn-sm" (click)="addProductTag($event, productTagInput)"><i class="fa fa-plus"></i></button>
    </div>

    <div class="tag-list" *ngIf="isArray(value)">
      <span class="badge badge-pill badge-default"
        *ngFor="let tag of value">{{ tag }} <i class="fa fa-close" (click)="removeProductTag(tag)"></i></span>
      <span *ngIf="!value || !value.length" class="empty">No tags selected</span>
    </div>
  </div>

  <ng-container *ngIf="field.input_element === 'tree'">
    <div class="tag-list">
      <button class="btn btn-secondary btn-sm" (click)="toggleTreeEditable()">
        <i class="fa"
          [ngClass]="{ 'fa-pencil': !isTreeEditable, 'fa-chevron-up': isTreeEditable }">
        </i>
      </button>
      <span class="badge badge-pill badge-default"
        *ngFor="let tag of value">{{ tag.text }}</span>
    </div>

    <ngx-treeview
      *ngIf="isTreeEditable"
      #treeElement
      [config]="field.settings.config"
      [items]="treeItems"
      (selectedChange)="treeSelectionChanged($event)">
    </ngx-treeview>
  </ng-container>

  <owl-date-time
    *ngIf="field.type === 'date-time'"
    [(ngModel)]="value"
    [dateFormat]="getDateFormat()"
    [type]="getDatePickerType()"
    (ngModelChange)="propagateChange($event)"></owl-date-time>

  <ng-container *ngIf="field.type === 'date-time-range'">
    <div class="row field-error-row" *ngIf="value.end && value.start > value.end">
      <div class="col-md-12">Start must be a time before the end</div>
    </div>
    <div class="row date-time-range">
      <div class="col-md-12 nowrap">
        <div class="col-flex-half">
          <owl-date-time
            [(ngModel)]="value.start"
            [dateFormat]="getDateFormat()"
            [type]="getDatePickerType()"
            (ngModelChange)="propagateChange($event)"></owl-date-time>
        </div>
        <div class="col operator">-</div>
        <div class="col-flex-half">
          <owl-date-time
            [(ngModel)]="value.end"
            [dateFormat]="getDateFormat()"
            [type]="getDatePickerType()"
            (ngModelChange)="propagateChange($event)"></owl-date-time>
        </div>
      </div>
    </div>
  </ng-container>

</div>
