<toaster-container [toasterconfig]="basicInfoService.getToasterConfig()"></toaster-container>

<div class="app flex-row align-items-center">
  <div class="container col-md-8">
    <div class="card-group mb-0">
      <div class="card p-4">
        <div class="card-block">
          <form name="form" (ngSubmit)="login()" #f="ngForm" novalidate>
            <h1>Login</h1>
            <p class="text-muted">Sign In to your account</p>
            <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
            <div class="input-group mb-3" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
              <span class="input-group-addon"><i class="icon-user"></i></span>
              <input type="text" class="form-control" placeholder="Username" name="username" [(ngModel)]="model.username" #username="ngModel" required >
            </div>
            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
            <div class="input-group mb-4" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
              <span class="input-group-addon"><i class="icon-lock"></i></span>
              <input type="password" class="form-control" placeholder="Password" name="password" [(ngModel)]="model.password" #password="ngModel" required>
            </div>
            <div class="row">
              <div class="col-6">
                <button type="submit" [disabled]="!f.form.valid || loading" class="btn btn-primary px-4">
                  <i *ngIf="!loading" class="fa fa-sign-in"></i>
                  <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                  Login
                </button>
              </div>

              <div class="col-6 text-right">
                <!-- <button type="button" class="btn btn-link px-0">Forgot password?</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card card-inverse card-primary py-5 d-md-down-none" style="width:44%">
        <div class="card-block text-center login-img-pane">
          <img src="assets/img/logo.png" alt="TF">
        </div>
      </div>
    </div>
  </div>
</div>
