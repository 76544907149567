import { Component, OnInit } from '@angular/core';
import { startOfMonth, endOfMonth } from 'date-fns';

import { PhotoService } from '@app/photo/photo.service';
import { UtilService } from '@app/core/util.service';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ProductService} from "@app/product/product.service";
import { ToasterService } from 'angular2-toaster';
import { debounce } from 'lodash';

const ONE_MONTH = 32 * 24 * 60 * 60 * 1000; //Add one day to not annoy

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  columns = [
    { name: 'email', label: 'User', type: 'text' },
    { name: 'time', label: 'Day', type: 'text' },
    { name: 'count', label: 'Photos', type: 'text' },
  ];
  items = [];
  dateRange = {
    start: startOfMonth(Date.now()),
    end: endOfMonth(Date.now()),
  };
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  private fetchStatsDebouncer: Function;

  constructor(
    public photoService: PhotoService,
    public util: UtilService,
    public productService: ProductService,
    private toasterService: ToasterService,
  ) {
    this.fetchStatsDebouncer = debounce(() => {
      if (this.dateRange.end.getTime() < this.dateRange.start.getTime())  {
        this.toasterService.pop('error', 'Invalid date range', `Please select end date after start`);
        return
      }

      if ((this.dateRange.end.getTime() - this.dateRange.start.getTime()) > ONE_MONTH) {
        this.toasterService.pop('error', 'Invalid date range', `Please don't select date range longer than a month`);
        return
      }

      this.photoService.stats(this.startTime, this.endTime, this.selectedItems).subscribe(stats => this.items = (<any>stats).body);
    }, 500);
  }

  onItemSelect(item: any) {
    this.fetchStats();
  }

  onSelectAll(items: any) {
    this.selectedItems=[...items];
    this.fetchStats();
  }

  onDeSelect(item:any){
    let idx=this.selectedItems.findIndex(s=>s.id === item.id);
    if (idx !== -1) {
      this.selectedItems.splice(idx,1);
    }
    this.fetchStats();
  }

  onDeSelectAll(items:any){
    this.selectedItems=[...items];
    this.fetchStats();
  }

  get startTime() {
    return this.dateRange.start.toISOString();
  }

  get endTime() {
    return this.dateRange.end.toISOString();
  }

  ngOnInit() {
    this.fetchStats();
    this.productService.getAllUsersByOrganization().subscribe((res)=>{
     this.dropdownList=res.map(u=>{return {'id':u.id,'name':u.email}})
    });

  }

  fetchStats() {
    this.fetchStatsDebouncer();
  }

  getTotalCount() {
    return this.items.reduce((a, b) => a + parseInt(b.count || 0, 10), 0);
  }

  export() {
    this.util.setTemporaryAuthCookie();
  }

  downloadUrl() {
    return `/api/file/stats?from=${this.startTime}&to=${this.endTime}&format=csv`;
  }
}
