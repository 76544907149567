import { pick } from 'lodash';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/core/auth.service';
import { UtilService } from '@app/core/util.service';
import { BaseApiService } from '@app/core/base-api.service';
export class CollectionService extends BaseApiService {
    constructor(authService, util) {
        super(authService, util);
        this.authService = authService;
        this.util = util;
        this.modelEndpoint = 'api/collection';
    }
    getRelevantFields(collection) {
        collection.products = (collection.products || []).map(product => product.id);
        collection.files = (collection.files || []).map(file => file.id);
        let type = collection.collection_type || null;
        if (collection.products.length) {
            type = CollectionService.TYPE_PRODUCT;
        }
        else if (collection.files.length) {
            type = CollectionService.TYPE_PHOTO;
        }
        if (type !== CollectionService.TYPE_PHOTO) {
            collection.files = [];
        }
        if (type !== CollectionService.TYPE_PRODUCT) {
            collection.products = [];
        }
        return Object.assign({}, pick(collection, ['name', 'description', 'products', 'files', 'is_public', 'settings']), { collection_type: type });
    }
    getByKey(key, page = 1) {
        return this.authService.get(`${this.modelEndpoint}/public/${key}?page=${page}`);
    }
    create(collection) {
        return this.authService.post(`${this.modelEndpoint}/create`, this.getRelevantFields(collection));
    }
    update(collection) {
        return this.authService.put(`${this.modelEndpoint}/${collection.id}`, this.getRelevantFields(collection));
    }
    upsert(collection) {
        if (collection.id) {
            return this.update(collection);
        }
        else {
            return this.create(collection);
        }
    }
    search(term, type = '') {
        return this.authService.get(`${this.modelEndpoint}/all?search=${term}&type=${type}`)
            .pipe(map((response) => response.body));
    }
}
CollectionService.TYPE_PHOTO = 'photo';
CollectionService.TYPE_PRODUCT = 'product';
