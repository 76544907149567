/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/pagination-list-view/pagination-list-view.component.ngfactory";
import * as i3 from "../shared/pagination-list-view/pagination-list-view.component";
import * as i4 from "../shared/batch-actions-dropdown/batch-actions-dropdown.component.ngfactory";
import * as i5 from "../shared/batch-actions-dropdown/batch-actions-dropdown.component";
import * as i6 from "../shared/item-list/item-list.component.ngfactory";
import * as i7 from "@angular/common";
import * as i8 from "../shared/item-list/item-list.component";
import * as i9 from "angular2-toaster/src/toaster.service";
import * as i10 from "@angular/router";
import * as i11 from "../core/util.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./photo.component";
import * as i14 from "./photo.service";
import * as i15 from "@ng-bootstrap/ng-bootstrap";
import * as i16 from "../collection/collection.service";
var styles_PhotoComponent = [i0.styles];
var RenderType_PhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoComponent, data: {} });
export { RenderType_PhotoComponent as RenderType_PhotoComponent };
export function View_PhotoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-pagination-list-view", [["title", "Photos"]], null, [[null, "itemsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemsChange" === en)) {
        var pd_0 = ((_co.photos = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PaginationListViewComponent_0, i2.RenderType_PaginationListViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaginationListViewComponent, [], { title: [0, "title"], service: [1, "service"], items: [2, "items"] }, { itemsChange: "itemsChange" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-batch-actions-dropdown", [], null, null, null, i4.View_BatchActionsDropdownComponent_0, i4.RenderType_BatchActionsDropdownComponent)), i1.ɵdid(4, 114688, null, 0, i5.BatchActionsDropdownComponent, [], { selectedCount: [0, "selectedCount"], actions: [1, "actions"] }, null), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "app-item-list", [["view-content", ""]], null, [[null, "onAction"], [null, "onSelectItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAction" === en)) {
        var pd_0 = (_co.photoActionHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelectItem" === en)) {
        var pd_1 = (_co.openPhoto($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ItemListComponent_0, i6.RenderType_ItemListComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(7, 114688, null, 0, i8.ItemListComponent, [i9.ToasterService, i10.Router, i11.UtilService, i12.DomSanitizer, i7.DatePipe], { columns: [0, "columns"], items: [1, "items"], isSelectable: [2, "isSelectable"], actions: [3, "actions"] }, { onSelectItem: "onSelectItem", onAction: "onAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Photos"; var currVal_1 = _co.photoService; var currVal_2 = _co.photos; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.selectedCount(); var currVal_4 = _co.batchActions(); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = _co.photoColumns; var currVal_6 = _co.photos; var currVal_7 = true; var currVal_8 = _co.photoActions; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_PhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo", [], null, null, null, View_PhotoComponent_0, RenderType_PhotoComponent)), i1.ɵdid(1, 114688, null, 0, i13.PhotoComponent, [i14.PhotoService, i9.ToasterService, i10.ActivatedRoute, i15.NgbModal, i11.UtilService, i16.CollectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoComponentNgFactory = i1.ɵccf("app-photo", i13.PhotoComponent, View_PhotoComponent_Host_0, {}, {}, []);
export { PhotoComponentNgFactory as PhotoComponentNgFactory };
