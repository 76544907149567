<div class="unreachable" *ngIf="isReady && !device.isConnectable">
  <app-remote-control-helper title="Device is not reachable"></app-remote-control-helper>
</div>

<div class="remote-content" *ngIf="isReady && device.isConnectable && device.settings">
  <div class="uploaded-photos-strip"
    *ngIf="uploadedPhotos.length && !showPhoto"
    [ngClass]="{ 'open': isPhotoStripOpen, 'closed': !isPhotoStripOpen }">
    <div class="uploaded-photos-strip-container">
      <div class="photo-group" *ngFor="let group of photoStripGroups">
        <div class="legend" [title]="group.sku">{{group.sku}}</div>
        <img [src]="getUploadedPhotoThumb(photo)"
          alt=""
          class="uploaded-photo"
          *ngFor="let photo of group.photos"
          (click)="openPhoto(photo)">
      </div>
    </div>
    <div
      class="caret"
      (click)="isPhotoStripOpen = !isPhotoStripOpen"
      *ngIf="uploadedPhotosForStrip.length > 1"
    ><i class="fa" [ngClass]="{'fa-caret-left': isPhotoStripOpen, 'fa-caret-right': !isPhotoStripOpen}"></i></div>
  </div>

  <div class="video-col" [ngClass]="{'show-photo': showPhoto}">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true" *ngIf="!device.videoSrc"></i>
    <app-remote-video
      #remoteVideoElement
      *ngIf="device.videoSrc && !showPhoto"
      [isCapturing]="isCapturing"
      [videoSrc]="device.videoSrc"
      [overlay]="isOverlayVisible && overlayImage ? overlayImage.url : undefined"
      [aspectRatio]="aspectRatio"
      [setFocusPoint]="setFocusPoint"
      [focusPoint]="device.settings.focusPoint"
      [orientation]="orientation"
    ></app-remote-video>

    <app-photo-cropper
      *ngIf="showPhoto"
      class="remote-photo"
      [photo]="latestPhoto"
      [overlay]="isOverlayVisible && overlayImage ? overlayImage.url : undefined"
      [aspectRatio]="aspectRatio"
      [orientation]="orientation"
      [(rotation)]="rotation"
    ></app-photo-cropper>
  </div>

  <div class="settings-col">
    <div class="settings-top">
      <div class="sku-control-group">
        <app-attribute-field
          #skuElem
          [field]="{ input_element: 'text', placeholder: 'SKU' }"
          [showLabel]="false"
          name="SKU"
          [(ngModel)]="sku"
          [isReadOnly]="isReadOnly"
          [showLabel]="false"
          (keydown)="onSkuFieldKeyPress($event)"
        ></app-attribute-field>

        <ng-container *ngIf="sku">
          <div *ngFor="let field of getAttributeFields()">
            <app-attribute-field
              [field]="field"
              [name]="field.slug"
              [(ngModel)]="attributeValues[field.slug]"
              [isReadOnly]="isReadOnly"
              [showLabel]="false"
            ></app-attribute-field>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="!showPhoto && sliders">
        <app-slider
          *ngFor="let slider of sliders"
          [slider]="slider"
        ></app-slider>
      </ng-container>

      <app-rotation-slider
        *ngIf="showPhoto"
        [(rotation)]="rotation"></app-rotation-slider>

      <app-aspect-ratio-selector
        [hasFreeAspectRatio]="showPhoto"
        [hasFullAspectRatio]="!showPhoto"
        [(aspectRatio)]="aspectRatio"
        [(orientation)]="orientation"></app-aspect-ratio-selector>

      <app-overlay-selector
        [(overlayImage)]="overlayImage"
        [(isOverlayVisible)]="isOverlayVisible"></app-overlay-selector>

      <button *ngIf="!showPhoto" class="btn btn-sm" (click)="onResetSettings()" [disabled]="!hasSettingsChanged()">
        <i class="fa fa-refresh"></i>
        <span class="mobile-hide">Reset settings</span>
      </button>

      <div *ngIf="!showPhoto" class="input-group">
        <button class="btn btn-sm" [ngClass]="{'btn-group-left' : hasSettingsChanged()}" (click)="openPresetManager('select')">
          <i class="fa fa-cogs"></i>
          <span class="mobile-hide">
            <span class="long-btn-text">{{getCurrentPreset().name}}</span>
          </span>
        </button>
        <button *ngIf="hasSettingsChanged()" class="btn btn-sm btn-group-right" (click)="openPresetManager('save')" [disabled]="!hasSettingsChanged()">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </div>

    <div class="form-group settings-bottom" *ngIf="!showPhoto && !isUploading">
      <div class="input-group skip-preview">
        <button class="btn btn-sm btn-group-left" (click)="skipPreview = !skipPreview">
          <i class="fa fa-image"></i>
          Skip preview
        </button>
        <label class="btn-checkbox">
          <input type="checkbox" [(ngModel)]="skipPreview">
        </label>
      </div>

      <button class="btn btn-lg btn-success btn-action"
        (click)="capture()"
        [disabled]="isCapturing || isUploading">
        <i class="fa fa-camera"></i>
        <span class="mobile-hide">Capture</span>
      </button>
    </div>

    <div class="form-group settings-bottom" *ngIf="showPhoto || isUploading">
      <button class="btn btn-action"
        *ngIf="showPhoto"
        (click)="dismissPhoto()"
        [disabled]="isUploading || isCapturing">
        <i class="fa fa-reply"></i>
        <span class="mobile-hide">Retake</span>
      </button>

      <button class="btn btn-lg btn-success btn-upload btn-action"
        *ngIf="showPhoto || isUploading"
        (click)="upload()"
        [disabled]="isUploading || isCapturing">
        <div class="progress" *ngIf="uploadProgress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" [style.width.%]="uploadProgress" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="upload-btn-content">
          <i class="fa" *ngIf="!uploadProgress" [ngClass]="{ 'fa-save': !isUploading, 'fa-spinner fa-pulse fa-fw': isUploading }"></i>
          <span class="mobile-hide" *ngIf="isUploading; then uploading else save"></span>
          <ng-template #save>Save</ng-template>
          <ng-template #uploading>Uploading <span *ngIf="uploadProgress">{{uploadProgress | number: '1.0-0'}}%</span></ng-template>
        </div>
      </button>
    </div>

    <!-- <div class="form-group">
      <label class="switch switch-text switch-success">
          <input type="checkbox" class="switch-input" name="save-to-camera-roll" [(ngModel)]="saveToCameraRoll">
          <span class="switch-label" data-on="Yes" data-off="No"></span>
          <span class="switch-handle"></span>
      </label>
      Save captured photos to camera roll on the device
    </div> -->

  </div>

</div>
