import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resizedImage',
})
export class ResizedImagePipe implements PipeTransform {

  transform(src, size = '600x600', flags = ''): any {
    const flagParam = flags ? `-${flags}` : '';
    const sizeParam = size ? `-${size}` : '';
    // set the resize parameter right after the file key and optional crop parameter and flag parameter at the end of the whole filename
    return (src || '').replace(/(\/[^\/-]*(-\d+x\d+x\d+x\d+)?)([^\/]*)(\.jp(e)?g|png)$/, `$1${sizeParam}$3${flagParam}$4`);
  }

}
