import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteControlService, Devices, Device } from './remote-control.service';

@Component({
  selector: 'app-remote-control-device-list',
  templateUrl: './remote-control-device-list.component.html',
  styleUrls: ['./remote-control-device-list.component.scss'],
})
export class RemoteControlDeviceListComponent {
  @Input() devices: Devices;

  constructor(
    private router: Router,
    private remoteControlService: RemoteControlService,
  ) { }

  get isReady() {
    return this.remoteControlService.isReady;
  }

  openDevice(device: Device) {
    this.router.navigate(['remote-control', device.id]);
  }

  refreshDevice(device: Device) {
    device.emit('reboot');
  }
}
